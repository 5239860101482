import React from "react";
import { DefaultButton, getTheme, Stack } from "@fluentui/react";
import { useState } from "react";
// import { Checkbox } from "@fluentui/react/lib/Checkbox";
import Checkbox from "@mui/material/Checkbox";

import { useEffect } from "react";
import { Button, FormControlLabel } from "@mui/material";

const ViewColumn = (props) => {
  const {
    isColumnOptionsOpen,
    setIsColumnOptionsOpen,
    columnHeader,
    setVisibleColumns,
    visibleColumns,
  } = props;
  const [checkboxStatus, setCheckboxStatus] = useState(() => {
    const status = {};
    columnHeader.forEach((column) => {
      status[column.key] = visibleColumns.includes(column.key);
    });
    return status;
  });

  function handleColumnToggle(columnKey) {
    // Update the checkbox status when a checkbox is toggled
    setCheckboxStatus((prevStatus) => ({
      ...prevStatus,
      [columnKey]: !prevStatus[columnKey],
    }));
  }

  function handleSave() {
    // Update the visible columns based on the new checkbox status
    setVisibleColumns((prevVisibleColumns) =>
      columnHeader
        .filter((column) => checkboxStatus[column.key])
        .map((column) => column.key)
    );
    setIsColumnOptionsOpen(false);
  }

  //   function handleReset() {
  //     setCheckboxStatus({});
  //   }
  function handleReset() {
    setCheckboxStatus(() => {
      const status = {};
      columnHeader.forEach((column) => {
        status[column.key] = true;
      });
      return status;
    });
  }

  return (
    <div isOpen={isColumnOptionsOpen}>
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <h1 style={{ fontWeight: 900, fontSize: "30px" }}>Choose Columns</h1> */}
        <Stack
          horizontal
          horizontalAlign="end"
          onClick={() => setIsColumnOptionsOpen(false)}
        >
          {" "}
          {/* <IconButton iconProps={closeIcon} title="Close" ariaLabel="Close" /> */}
        </Stack>
      </div>
      {columnHeader
        .filter(
          (column) => column.key !== "column5" && column.key !== "column6"
        )
        .map((column) => (
          <div style={{ marginLeft: "20px", marginBottom: "-8px" }}>
            {/* <Checkbox
              key={column.key}
              //   label={<span style={{ fontSize: "1em" }}>{column.name}</span>}
              inputProps={{ "aria-label": column.name }}
              //  checked={visibleColumns.includes(column.key)}
              checked={checkboxStatus[column.key]}
              onChange={() => handleColumnToggle(column.key)}
              style={{ backgroundColor: "#fff" }}
            /> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxStatus[column.key]}
                  onChange={() => handleColumnToggle(column.key)}
                  inputProps={{ "aria-label": column.name }}
                  id={column.key}
                  style={{ color: "#800000" }}
                />
              }
              label={column.name}
              htmlFor={column.key}
              style={{ fontSize: "0.6rem", color: "#323130" }}
            />
          </div>
        ))}
      <div style={{ padding: "20px", display: "flex", marginTop: "5px" }}>
        <Button
          style={{
            marginRight: "10px",
            backgroundColor: "#800000",
            color: "#fff",
          }}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
};

export default ViewColumn;

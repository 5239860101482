class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        visibility:- Use to toggle column visibility.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static tableHeader = [
    {
      id: "fname",
      accessor: "fname",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: "100px",
      label: "user.name",
      className: "",
      functionName: "",
      // overflow:"hidden"
    },
    // {
    // 	id: "lname",
    // 	accessor: "lname",
    // 	dataType: "string",
    // 	sortable: true,
    // 	disablePadding: false,
    // 	visibility: true,
    // 	width: 50,
    // 	label: "user.lname",
    // 	className: "",
    // 	functionName: "",
    // },

    {
      id: "userType",
      accessor: "userType",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "user.userType",
      className: "",
      functionName: "",
    },
    {
      id: "mobileNo",
      accessor: "mobileNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 60,
      label: "user.mobileNo",
      className: "",
      functionName: "",
    },
    {
      id: "email",
      accessor: "email",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: "user.email",
      className: "",
      functionName: "",
    },
    {
      id: "practiceNo",
      accessor: "practiceNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "user.practiceNo",
      className: "",
      functionName: "",
    },
    {
      id: "status",
      accessor: "active",
      dataType: "boolean",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "user.status",
      className: "",
      functionName: "",
    },

    // {
    //   id: "createdAt",
    //   accessor: "createdAt",
    //   dataType: "date",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "user.createdAt",
    //   className: "DateConversion",
    //   functionName: "unixToLocaleDate",
    // },
    // {
    //   id: "updatedAt",
    //   accessor: "updatedAt",
    //   dataType: "date",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "user.updatedAt",
    //   className: "DateConversion",
    //   functionName: "unixToLocaleDate",
    // },
  ];
}
export default TableHeader;

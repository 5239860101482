import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import { FormHelperText, Grid, Hidden, InputLabel,  TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { AppContext } from "../AppState";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import FormValidation from "../../../../utils/FormValidation";

const defaultHcpInfo = {
  name: "",
  specialization: "",
  mobileNo: "",
  practiceNumber: "",
  email: "",
  address: "",
};
const useStyles = makeStyles((theme)=>({
saveDraftButton:{
  // backgroundColor: "#E8E8E8",
  // borderRadius: "2px",
  // width: "121px",
  // height: "32px",
  // color: "black",/
  // [theme.breakpoints.down("sm")]: {
  //   width: "30px",
  // },
  // [theme.breakpoints.up("xs")]: {
  //   width: "30px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   width: "35%",
  // },

}

}))

// const defaultPatientInfo = {
//   name: "",
//   email: "",
//   mobileNo: "",
//   mobileNo2: "",
//   medicalAidName: "",
//   membershipNumber: "",
//   idProofNumber: "",
//   address: "",
// };

const PatientInfo = (props) => {
  const classes = useStyles();
  const [hcpInfo, setHcpInfo] = useState(defaultHcpInfo);
  const { patientInfo, setPatientInfo ,defaultPatientInfo} = props;
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  //   const [patientInfo, setPatientInfo] = useState(defaultPatientInfo);

  const validatePatientInfo = () => {
    let fieldValidation = [
      "name",
      // "email",
      "mobileNo",
      // "mobileNo2",
      "medicalAidName",
      "membershipNumber",
      "idProofNumber",
      // "address",
    ];
    FormValidation.validation(fieldValidation, patientInfo).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };
  return (
    <div style={{ marginBottom: 100 }}>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2 style={{ color: "#323130", fontSize: "28px",marginTop:'4px' }}>
                Patient Information
              </h2>
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="name"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                    }}
                  >
                    Title and Name<span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    // label="Title and Name"
                    id="name"
                    value={patientInfo.name}
                    onChange={(e) => {
                      setPatientInfo({
                        ...patientInfo,
                        name: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="email"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="email"
                    value={patientInfo.email}
                    onChange={(e) => {
                      setPatientInfo({
                        ...patientInfo,
                        email: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="mobileNo"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Telephone 1<span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="mobileNo"
                    value={patientInfo.mobileNo}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPatientInfo({
                        ...patientInfo,
                        mobileNo: e.target.value,
                      });
                      if (value.length > 10) {
                        setError(true);
                      } else {
                        setError(false);
                      }
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                    error={error}
                  />
                  {error && (
                      <FormHelperText id="mobileNo-error">
                        Mobile Number should not exceed 10 digits.
                      </FormHelperText>
                    )}
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="mobileNo2"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Telephone 2
                  </InputLabel>
                  <TextField
                    id="mobileNo2"
                    fullWidth
                    // label="Specialization"
                    value={patientInfo.mobileNo2}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPatientInfo({
                        ...patientInfo,
                        mobileNo2: e.target.value,
                      });
                      if (value.length > 10) {
                        setError2(true);
                      } else {
                        setError2(false);
                      }
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                    error={error2}
                  />
                  {error2 && (
                      <FormHelperText id="mobileNo-error">
                        Mobile Number should not exceed 10 digits.
                      </FormHelperText>
                    )}
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="medicalAidName"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Medical Aid Name<span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="medicalAidName"
                    // label="Practice Number"
                    value={patientInfo.medicalAidName}
                    onChange={(e) => {
                      setPatientInfo({
                        ...patientInfo,
                        medicalAidName: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="membershipNumber"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Membership Number<span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="membershipNumber"
                    // label="Practice Number"
                    value={patientInfo.membershipNumber}
                    onChange={(e) => {
                      setPatientInfo({
                        ...patientInfo,
                        membershipNumber: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="idProofNumber"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    ID or Passport Number
                    <span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="idProofNumber"
                    // label="Practice Number"
                    value={patientInfo.idProofNumber}
                    onChange={(e) => {
                      setPatientInfo({
                        ...patientInfo,
                        idProofNumber: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="address"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Address
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="address"
                    multiline
                    value={patientInfo.address}
                    onChange={(e) => {
                      setPatientInfo({
                        ...patientInfo,
                        address: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "74px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {/* <Grid container sx={{ mt: 3, justifyContent: "space-between" }}>
                  <Grid>
                    <Button>Reset </Button>
                    <Button>Save as Draft </Button>
                  </Grid>
                  <Grid>
                    <Button onClick={props.handleNext} sx={{ mr: 2 }}>
                      Next
                    </Button>
                  </Grid>
                </Grid> */}
            </Grid>
            <Grid container sx={{ mt: 3, justifyContent: "space-between" }}>
              <Grid style={{ marginTop: "10px" }}>
              <Hidden only={["xs"]}>
                <Button
                  style={{
                    backgroundColor: "#E8E8E8",
                    borderRadius: "2px",
                    width: "91px",
                    height: "32px",
                    color: "#323130",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    fontFamily: "Segoe UI",
                  }}
                    onClick={(e)=>{
                      setPatientInfo(defaultPatientInfo)
                    }}
                >
                  Reset{" "}
                </Button>
                <Button
                className={classes.saveDraftButton}
                  style={{
                    backgroundColor: "#E8E8E8",
                    borderRadius: "2px",
                    width: "121px",
                    height: "32px",
                    color: "#323130",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    margin: "7px",
                    fontFamily: "Segoe UI",
                  }}
                  onClick={props.saveSurveyAsDraft}
                >
                  Save as Draft
                </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                <Button
                  style={{
                    backgroundColor: "#E8E8E8",
                    borderRadius: "2px",
                    width: "40px",
                    height: "32px",
                    color: "#323130",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    fontFamily: "Segoe UI",
                  }}
                    onClick={(e)=>{
                      setPatientInfo(defaultPatientInfo)
                    }}
                >
                  Reset{" "}
                </Button>
                <Button
                className={classes.saveDraftButton}
                  style={{
                    backgroundColor: "#E8E8E8",
                    borderRadius: "2px",
                    width: "40px",
                    height: "32px",
                    color: "#323130",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    margin: "7px",
                    fontFamily: "Segoe UI",
                  }}
                  onClick={props.saveSurveyAsDraft}
                >
                  Draft
                </Button>



                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
              <Hidden only={["xs"]}>
                <Button
                  onClick={props.handlePrev}
                  // sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    width: "96px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Previous
                </Button>
                <Button
                  onClick={validatePatientInfo}
                  // sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    width: "96px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    margin: "7px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Next
                </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                <Button
                  onClick={props.handlePrev}
                  // sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    width: "32px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    margin: "7px",
                  }}
                >
                  <ArrowBackIosNewIcon/>
                </Button>
                    <Button
                      onClick={validatePatientInfo}
                      // sx={{ mr: 2 }}
                      style={{
                        backgroundColor: "#800000",
                        borderRadius: "2px",
                        width: "32px",
                        height: "32px",
                        color: "#fff",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "0",
                        margin: "7px",
                        // marginRight: "10px",
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                    
                  </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientInfo;

// const monthName = {
// 	'01': {
// 		name: 'January',
// 		short: 'Jan',
// 		number: 1,
// 		days: 31,
// 	},
// 	'02': {
// 		name: 'February',
// 		short: 'Feb',
// 		number: 2,
// 		days: 28,
// 	},
// 	'03': {
// 		name: 'March',
// 		short: 'Mar',
// 		number: 3,
// 		days: 31,
// 	},
// 	'04': {
// 		name: 'April',
// 		short: 'Apr',
// 		number: 4,
// 		days: 30,
// 	},
// 	'05': {
// 		name: 'May',
// 		short: 'May',
// 		number: 5,
// 		days: 31,
// 	},
// 	'06': {
// 		name: 'June',
// 		short: 'Jun',
// 		number: 6,
// 		days: 30,
// 	},
// 	'07': {
// 		name: 'July',
// 		short: 'Jul',
// 		number: 7,
// 		days: 31,
// 	},
// 	'08': {
// 		name: 'August',
// 		short: 'Aug',
// 		number: 8,
// 		days: 31,
// 	},
// 	'09': {
// 		name: 'September',
// 		short: 'Sep',
// 		number: 9,
// 		days: 30,
// 	},
// 	'10': {
// 		name: 'October',
// 		short: 'Oct',
// 		number: 10,
// 		days: 31,
// 	},
// 	'11': {
// 		name: 'November',
// 		short: 'Nov',
// 		number: 11,
// 		days: 30,
// 	},
// 	'12': {
// 		name: 'December',
// 		short: 'Dec',
// 		number: 12,
// 		days: 31,
// 	},
// };
const monthNameArray = [
  {
    name: "January",
    short: "Jan",
    number: 1,
    days: 31,
  },
  {
    name: "February",
    short: "Feb",
    number: 2,
    days: 28,
  },
  {
    name: "March",
    short: "Mar",
    number: 3,
    days: 31,
  },
  {
    name: "April",
    short: "Apr",
    number: 4,
    days: 30,
  },
  {
    name: "May",
    short: "May",
    number: 5,
    days: 31,
  },
  {
    name: "June",
    short: "Jun",
    number: 6,
    days: 30,
  },
  {
    name: "July",
    short: "Jul",
    number: 7,
    days: 31,
  },
  {
    name: "August",
    short: "Aug",
    number: 8,
    days: 31,
  },
  {
    name: "September",
    short: "Sep",
    number: 9,
    days: 30,
  },
  {
    name: "October",
    short: "Oct",
    number: 10,
    days: 31,
  },
  {
    name: "November",
    short: "Nov",
    number: 11,
    days: 30,
  },
  {
    name: "December",
    short: "Dec",
    number: 12,
    days: 31,
  },
];
const dayName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const DateConversion = {
  unixToLocaleDate: function (unixTimestamp) {
    if (typeof unixTimestamp === "string") return "Date is not  found";

    let dateValue =
      new Date(unixTimestamp * 1000)
        .toLocaleDateString("default", {
          day: "numeric",
          month: "short",
        })
        .split(" ")[1]
        .toString()
        .padStart(2, "0") +
      "/" +
      new Date(unixTimestamp * 1000)
        .toLocaleDateString("default", {
          month: "numeric",
        })
        .split(" ")[0]
        .toString()
        .padStart(2, "0") +
      "/" +
      new Date(unixTimestamp * 1000)
        .toLocaleDateString("default", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .split(" ")[2];
    return dateValue;
  },
  LocalDateToUnix: function (date, type) {
    let result = 0;
    if (type === "normal") {
      result = Math.floor(new Date() / 1000);
    } else if (type === "start") {
      result = Math.floor(
        new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(
          0
        ) / 1000
      );
    } else if (type === "end") {
      result = Math.floor(
        new Date(
          new Date(new Date(date).setHours(23)).setMinutes(59)
        ).setSeconds(59) / 1000
      );
    } else if (type === "dateSelect") {
      result = Math.floor(new Date(date) / 1000);
    } else if (type === "withtime") {
      result = Math.floor(new Date(date));
    }
    return result;
  },
  unixToLocaleDateTime: function (unixTimestamp) {
    let date = new Date(unixTimestamp * 1000);
    let tempDate = date.getDate();
    let tempMonth = date.getMonth() + 1;
    let tempYear = date.getFullYear();
    let tempHour = date.getHours();
    let tempMinutes = date.getMinutes();
    let tempSeconds = date.getSeconds();

    return (
      tempDate.toString().padStart(2, "0") +
      "/" +
      tempMonth.toString().padStart(2, "0") +
      "/" +
      tempYear +
      " " +
      tempHour.toString().padStart(2, "0") +
      ":" +
      tempMinutes.toString().padStart(2, "0") +
      ":" +
      tempSeconds.toString().padStart(2, "0")
    );
  },
  timeLapse: function (unixTimestamp) {
    let date = unixTimestamp * 1000;
    let tempDate = new Date();

    var minute = 60 * 1000,
      hour = minute * 60,
      day = hour * 24, //if day required enable
      //   month = day * 30, //if months required enable
      ms = Math.abs(date - tempDate);

    // var months = parseInt(ms / month, 10); //if months required enable

    // ms -= months * month; //if months required enable

    var days = parseInt(ms / day, 10); //if day required enable

    ms -= days * day; //if day required enable

    var hours = parseInt(ms / hour, 10);

    ms -= hours * hour;
    var minutes = parseInt(ms / minute, 10);

    return [
      //   months + ' months', //if months required enable
      days + " Days", //if day required enable
      hours + " hrs",
      minutes + " min",
    ].join(", ");
  },
  unixToDateFieldDate: function (unixTimestamp) {
    let date = new Date(unixTimestamp * 1000);
    return date;
  },
  getMonthesList: function () {
    return monthNameArray;
  },
  findDayObjectFromString: function (day, month, year) {
    let dayNumber =
      new Date(Date.parse(day + "/" + month + "/" + year)).getDay() + 1;
    let body = {
      dayNumber,
      dayName: dayName[dayNumber - 1],
    };
    return body;
  },
  excelDateToJSDate: function (serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    // var hours = Math.floor(total_seconds / (60 * 60));
    // var minutes = Math.floor(total_seconds / 60) % 60;

    return (
      date_info.getDate().toString().padStart(2, "0") +
      "/" +
      (date_info.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date_info.getFullYear().toString()
    );
  },
  payhubDateObjectToDateString: function (date) {
    return (
      date.day.toString().padStart(2, "0") +
      "/" +
      date.month.toString().padStart(2, "0") +
      "/" +
      date.year
    );
  },
  unixToLocalDate12HourTime: function (unixTimestamp) {
    console.log("this is the date");

    let date = new Date(unixTimestamp * 1000);
    let tempDate = date.getDate();
    let tempMonth = date.getMonth() + 1;
    let tempYear = date.getFullYear();
    let hours = date
      .toLocaleTimeString()
      .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
    return (
      tempDate.toString().padStart(2, "0") +
      "-" +
      tempMonth.toString().padStart(2, "0") +
      "-" +
      tempYear +
      " " +
      hours
    );
  },
  todayOrReturnDate: function (unixTimestamp) {
    let date = new Date(unixTimestamp * 1000);
    let tempDate = date.getDate();
    let tempMonth = monthNameArray[date.getMonth()].name;
    let tempYear = date.getFullYear();

    if (date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
      return "Today";
    } else {
      return (
        tempMonth +
        " " +
        tempDate +
        " " +
        ((tempDate >= 4 && tempDate <= 20) || (tempDate >= 24 && tempDate <= 30)
          ? "th"
          : ["st", "nd", "rd"][(tempDate % 10) - 1]) +
        " " +
        tempYear
      );
    }
  },
  returnYearMonthDate: function (unixTimestamp) {
    let dateValue = new Date(unixTimestamp * 1000);

    let year = dateValue.getFullYear();
    let month = dateValue.getMonth() + 1;
    let day = dateValue.getDate();

    return year.toString() + month.toString() + day.toString();
  },
};
export default DateConversion;

import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";

import { AppContext } from "../AppState";
import { useEffect } from "react";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FormValidation from "../../../../utils/FormValidation";



const PatientDiagnos = (props) => {
  const { patientDiagnosis, setPatientDiagnosis,patientDiagnosisDefaultValue } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setPatientDiagnosis({
      ...patientDiagnosis,
      diagnosisDate: new Date(date * 1000),
    });
  };
  const validateInfo = () => {
    let fieldValidation = [
      "diagnosis",
      // "email",
      // "diagnosisDate",
      "patientCurrentCondition",
    ];
    FormValidation.validation(fieldValidation, patientDiagnosis).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                height: "80%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2 style={{ color: "#323130", fontSize: "28px",marginTop:'4px' }}>
                Patient Diagnosis
              </h2>
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 , paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="diagnosis"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                    }}
                  >
                    Diagnosis
                    <span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="diagnosis"
                    value={patientDiagnosis.diagnosis}
                    onChange={(e) => {
                      setPatientDiagnosis({
                        ...patientDiagnosis,
                        diagnosis: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>

                <Grid xl={12} lg={12} md={12} sm={12} xs={12}style={{ padding: 5 , paddingTop: 15, paddingBottom: 15 }}>
                  <InputLabel
                    htmlFor="diagnosisDate"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                      marginTop: 5,
                    }}
                  >
                    Date of diagnosis
                    <span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      id="diagnosisDate"
                      inputFormat="dd/MM/yyyy"
                      // label={"Date-of-diagnosis"}
                      value={
                        patientDiagnosis.diagnosisDate !== null
                          ? new Date(patientDiagnosis.diagnosisDate * 1000)
                          : null
                      }
                      maxDate={new Date()}
                      onChange={(date) =>
                        setPatientDiagnosis({
                          ...patientDiagnosis,
                          diagnosisDate: Math.floor(
                            new Date(date).getTime() / 1000.0
                          ),
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ marginBottom: "10px" }}
                        />
                      )}
                     


                    />
                  </LocalizationProvider>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 ,paddingTop: 15, paddingBottom: 15  }}
                >
                  <InputLabel
                    htmlFor="patientCurrentCondition"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                      whiteSpace: "normal", // Prevent line breaks
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis",
                      // minWidth: "600px"
                    }}
                  >
                    {/* I am writing to request a formulary exception for the above
                       mentioned patient to receive treatment with ZYQUIS (apixaban) for the indication */}
                    Current condition of the patient{" "}
                    <span style={{ color: "#D13438" }}>*</span>
                    <span style={{ visibility: "hidden" }}>
                      exception for the above mentioned patient to receive
                      treatment with ZYQUIS (apixaban){" "}
                    </span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="patientCurrentCondition"
                    multiline
                    value={patientDiagnosis.patientCurrentCondition}
                    onChange={(e) => {
                      setPatientDiagnosis({
                        ...patientDiagnosis,
                        patientCurrentCondition: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "114px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setPatientDiagnosis(patientDiagnosisDefaultValue);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setPatientDiagnosis(patientDiagnosisDefaultValue);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={validateInfo}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={validateInfo}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientDiagnos;

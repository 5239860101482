import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";

import { AppContext } from "../AppState";
import { useEffect } from "react";
// import { DatePicker, defaultDatePickerStrings } from "@fluentui/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import SideModal from "../Modals/SideModal";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import FormValidation from "../../../../utils/FormValidation";
import ImageIcon from '@mui/icons-material/Image';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const ChildPughScore = (props) => {
  const { childPughScore, setChildPughScore, handleNext,childPughScorePage } = props;
  //   const [pdfFiles, setPdfFiles] = useState([]);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

    const validateChildPugh = () => {
      let fieldValidation = [
        "bilirubinScore",
        "albuminScore",
        "inrScore",
        "ascitesScore",
        "encephalopathyScore",
        "patientHasCirrhosis"
        // "outlinedBasic",
        // "outlineBasic",
        // "address",
      ];
      FormValidation.validation(fieldValidation, childPughScore).then(
        (ValidationResult) => {
          if (ValidationResult === true) {
            props.handleNext();
          }
        }
      );
    };

  const handleButtonClick = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      bilirubin: newValue,
    });
  };
  const handleAlbuminClick = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      albumin: newValue,
    });
  };
  const handleInrClick = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      inr: newValue,
    });
  };

  const handleAscitesHistory = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      ascites: newValue,
    });
  };
  const handleEncephalopathyHistory = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      encephalopathy: newValue,
    });
  };
  const handlePatientHasCirrhosis = (e) => {
    setChildPughScore({
      ...childPughScore,
      patientHasCirrhosis: e.target.value,
    });
  };
  const handleResultIn = (e) => {
    setChildPughScore({
      ...childPughScore,
      resultIn: e.target.value,
    });
  };
  const handleZYQUISmgBD = (e) => {
    setChildPughScore({
      ...childPughScore,
      ZYQUISmgBD: e.target.value,
    });
  };

  function calculateRiskScore(patientData) {
    let score = 0;
    const bilirubin = patientData.bilirubinScore;
    const albumin = patientData.albuminScore;
    const inr = patientData.inrScore;
    const ascites = patientData.ascitesScore;
    const encephalopathy = patientData.encephalopathyScore;

    if (albumin == 1) {
      score += 1;
    } else if (albumin == 2) {
      score += 2;
    } else if (albumin == 3) {
      score += 3;
    }

    if (bilirubin == 1) {
      score += 1;
    } else if (bilirubin == 2) {
      score += 2;
    } else if (bilirubin == 3) {
      score += 3;
    }

    if (inr == 1) {
      score += 1;
    } else if (inr == 2) {
      score += 2;
    } else if (inr == 3) {
      score += 3;
    }

    if (ascites == 1) {
      score += 1;
    } else if (ascites == 2) {
      score += 2;
    } else if (ascites == 3) {
      score += 3;
    }

    if (encephalopathy) {
      if (encephalopathy == 1) {
        score += 1;
      } else if (encephalopathy == 2) {
        score += 2;
      } else if (encephalopathy >= 3) {
        score += 3;
      }
    }

    return score;
  }
  const scores = calculateRiskScore(childPughScore);
  useEffect(() => {
    setChildPughScore({
      ...childPughScore,
      totalRisk: scores,
    });
  }, [scores]);

  const handleCheckboxChange = (event) => {
    const riskType = event.target.value;

    if (childPughScore.riskType === riskType) {
      // if the clicked checkbox is already checked, uncheck it
      setChildPughScore({
        ...childPughScore,
        riskType: null,
      });
    } else {
      // otherwise, check the clicked checkbox and uncheck the others
      setChildPughScore({
        ...childPughScore,
        riskType: riskType,
      });
    }
  };

  const handleLogoUpload = (event, key) => {
    // console.log("Hello: ", event);
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      [...event.target.files].map(async (data) => {
        let formData = new FormData();
        formData.append(`attachment`, data);

        formData.append("bucketName", "survey");

        console.log("Hello: ", formData);
        APIRequest.multipartForDataRequest(
          "PUT",
          ConfigAPIURL.uploadFile,
          formData
        ).then((response) => {
          if (response !== undefined && response !== null) {
            console.log("Hi");
            setChildPughScore((prevObject) => {
              if (Array.isArray(prevObject[key])) {
                return {
                  ...prevObject,
                  [key]: prevObject[key].concat(response.data.attachmentUrl[0]),
                };
              } else {
                return {
                  ...prevObject,
                  [key]: response.data.attachmentUrl[0],
                };
              }
            });
            setTimeout(function () {}, 1000);
          } else {
          }
        });
      });
    } else {
    }
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                Child-Pugh Score for Cirrhosis Mortality
              </h2>
              <p>Estimates cirrhosis severity</p>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  boxShadow:
                    " 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)",
                  // padding: "15px",
                  paddingBottom: "30px",
                  width: "96%",
                  borderRadius: "8px",
                  paddingLeft:"0",
                  paddingRight:"0",
                  // margin: "10px",
                }}
              >
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    backgroundColor: "#FAFAFA",
                    // marginTop: "-13px",
                    // marginLeft: "-13px",
                  }}
                >
                  <Grid xs={8} xl={3} lg={3} md={4} style={{ paddingLeft: "8px" }}>
                    {/* <p>Test</p> */}
                  </Grid>
                  <Grid
                    xl={3.8}
                    lg={3.8}
                    md={4}
                    style={{ paddingLeft: "8px", textAlign: "center",fontWeight: "600", }}
                  >
                    <Hidden only={["xs","sm"]}>

                    <p>1</p>
                    </Hidden>
                  </Grid>
                  <Grid
                    xl={3}
                    lg={3}
                    md={3}
                    style={{ paddingLeft: "8px", textAlign: "center",fontWeight: "600", }}
                  >
                    <Hidden only={["xs","sm"]}>

                    <p>2</p>
                    </Hidden>
                  </Grid>
                  <Grid
                    xl={3}
                    lg={3}
                    md={3}
                    style={{ paddingLeft: "4px", textAlign: "center",fontWeight: "600", }}
                  >
                    <Hidden only={["xs","sm"]}>

                    <p>3</p>
                    </Hidden>
                  </Grid>
                  <Grid xs={2} xl={1.2} lg={1.2} md={1} style={{ paddingLeft: "8px",fontWeight: "600", }}>
                    <p>Score</p>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={3} lg={3} md={3} style={{paddingLeft:'8px'}}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px'
                        }}
                      >
                        Bilirubin (Total)<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                bilirubinScore: 1,
                                bilirubin: "<2 mg/dL (<34.2 μmol/L)",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.bilirubinScore === 1
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.bilirubinScore === 1
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                              textTransform: "none",
                            }}
                          >
                            {"<2 mg/dL (<34.2 μmol/L)"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                bilirubinScore: 2,
                                bilirubin: "2-3 mg/dL (\u003C34.2-51.3 μmol/L))",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.bilirubinScore === 2
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.bilirubinScore === 2
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                              textTransform: "none",
                            }}
                          >
                            {"2-3 mg/dL (\u003C34.2-51.3 μmol/L)"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                bilirubinScore: 3,
                                bilirubin: ">3 mg/dL (\u003C51.3 μmol/L",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.bilirubinScore === 3
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.bilirubinScore === 3
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                              textTransform: "none",
                            }}
                          >
                            {">3 mg/dL (<51.3 μmol/L)"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="bilirubinScore"
                        value={childPughScore.bilirubinScore}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ------ */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12}xl={3} lg={3} md={3}style={{paddingLeft:'8px'}}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px'
                        }}
                      >
                        Albumin<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                albuminScore: 1,
                                albumin: ">3.5 g/dL (>35 g/L)",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.albuminScore === 1
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.albuminScore === 1
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                              textTransform: "none",
                            }}
                          >
                            {">3.5 g/dL (>35 g/L)"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                albuminScore: 2,
                                albumin: "2.8-3.5 g/dL (28-35 g/L)",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.albuminScore === 2
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.albuminScore === 2
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                              textTransform: "none",
                            }}
                          >
                            {"2.8-3.5 g/dL (28-35 g/L)"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                albuminScore: 3,
                                albumin: "<2.8 g/dL (<28 g/L)",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.albuminScore === 3
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.albuminScore === 3
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                              textTransform: "none",
                            }}
                          >
                            {"<2.8 g/dL (<28 g/L)"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="albuminScore"
                        value={childPughScore.albuminScore}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12}xl={3} lg={3} md={3}style={{paddingLeft:'8px'}}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px'
                        }}
                      >
                        INR<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                inrScore: 1,
                                inr: "<1.7",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.inrScore === 1
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.inrScore === 1
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"<1.7"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                inrScore: 2,
                                inr: "1.7-2.2",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.inrScore === 2
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.inrScore === 2
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1.7-2.2"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                inrScore: 3,
                                inr: ">2.2",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.inrScore === 3
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.inrScore === 3
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {">2.2"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="inrScore"
                        value={childPughScore.inrScore}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12}xl={3} lg={3} md={3}style={{paddingLeft:'8px'}}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px'
                        }}
                      >
                        Ascites<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                ascitesScore: 1,
                                ascites: "Absent",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.ascitesScore === 1
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.ascitesScore === 1
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"Absent"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                ascitesScore: 2,
                                ascites: "Slight",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.ascitesScore === 2
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.ascitesScore === 2
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"Slight"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                ascitesScore: 3,
                                ascites: "Moderate",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.ascitesScore === 3
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.ascitesScore === 3
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"Moderate"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="ascitesScore"
                        value={childPughScore.ascitesScore}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12}xl={3} lg={3} md={3}style={{paddingLeft:'8px'}}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px'
                        }}
                      >
                        Encephalopathy<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                encephalopathyScore: 1,
                                encephalopathy: "No Encephalopathy",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.encephalopathyScore === 1
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.encephalopathyScore === 1
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"No Encephalopathy"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                encephalopathyScore: 2,
                                encephalopathy: "Grade 1-2",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.encephalopathyScore === 2
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.encephalopathyScore === 2
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"Grade 1-2"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}xl={4} lg={4} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setChildPughScore({
                                ...childPughScore,
                                encephalopathyScore: 3,
                                encephalopathy: "Grade 3-4",
                              });
                            }}
                            style={{
                              //   backgroundColor: "#FAFAFA",
                              backgroundColor:
                                childPughScore.encephalopathyScore === 3
                                  ? "#fff"
                                  : "#fff",
                              color: "#000",
                              border:childPughScore.encephalopathyScore === 3
                              ? "2px solid #800000"
                              : "1px solid #8A8886",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontSize: "12px",
                              padding: "3px",
                              height: "40px",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"Grade 3-4"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="encephalopathyScore"
                        value={childPughScore.encephalopathyScore}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ---- */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={2}xl={9} lg={9} md={9} style={{ marginRight: "8px" }}></Grid>
                    <Grid item xs={6}xl={2} lg={2} md={2}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        Total Risk Score
                      </InputLabel>
                    </Grid>
                    <Grid item xs={0.5} style={{ display: "flex" }}>
                      <TextField
                        id="score"
                        value={calculateRiskScore(childPughScore)}              
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ------ */}
              </Grid>

              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FFF3F3",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ marginLeft: "10px" }}>Classification</p>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5, display: "flex" }}
                >
                  <div
                    style={{
                      maxWidth: "125px",
                      height: "32px",
                      backgroundColor: "#F2F2F2",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                      padding: "6px",
                    }}
                  >
                    Mild : 5-6
                  </div>
                  <div
                    style={{
                      maxWidth: "125px",
                      height: "32px",
                      backgroundColor: "#F2F2F2",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                      padding: "6px",
                    }}
                  >
                    Moderate : 7-9
                  </div>
                  <div
                    style={{
                      maxWidth: "154px",
                      height: "32px",
                      backgroundColor: "#F2F2F2",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                      padding: "6px",
                    }}
                  >
                    Severe : 10 -15
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FAFAFA",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ margin: "12px", lineHeight: 2.5 }}>
                  From the above assessment it is determined that the patient
                  has
                  <input
                    id="patientHasCirrhosis"
                    variant="outlined"
                    className="segoe-input"
                    style={{
                      width: "50%",
                      height: "28px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #8A8886",
                      borderRadius: "2px",
                      fontFamily:'Segoe UI'
                    }}
                    placeholder="Mild, Moderate or Severe"
                    value={childPughScore.patientHasCirrhosis}
                    onChange={handlePatientHasCirrhosis}
                  /><span style={{ color: "#D13438" }}>*</span>
                  Cirrhosis severity. Liver disease could result in an increased
                  risk of bleeding for my patient. From studies listed in the
                  references below it is established that ZYQUIS (apixaban) has
                  a low risk of bleeding and can safely be used up to Category B
                  Cirrhosis (Refer to Package insert referenced).
                </p>
              </Grid>

              {/* <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FAFAFA",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ margin: "12px", lineHeight: 2.5 }}>
                  It is with all the above mentioned reasons, and my
                  professional medical opinion that should my patient not
                  receive ZYQUIS (apixaban), it would result in<span style={{ color: "#D13438" }}>*</span>
                  <input
                    id="outlinebasic"
                    variant="outlined"
                    style={{
                      width: "50%",
                      height: "28px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #8A8886",
                      borderRadius: "2px",
                    }}
                    value={childPughScore.resultIn}
                    onChange={handleResultIn}
                  />
                  Please approve ZYQUIS
                  <input
                    id="outlined-basic"
                    variant="outlined"
                    style={{
                      width: "50px",
                      height: "28px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #8A8886",
                      borderRadius: "2px",
                    }}
                    value={childPughScore.ZYQUISmgBD}
                    onChange={handleZYQUISmgBD}
                  />
                  mg BD to be covered as chronic or Prescribed Minimum Benefit
                  (PMB) on the patient’s treatment plan.
                </p>
              </Grid> */}

              {/* <p>Upload</p> */}
              {/* <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <Grid
                  xl={3.5}
                  lg={3.5}
                  md={3.5}
                  sm={12}
                  xs={12}
                  style={{
                    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    paddingBottom: "30px",
                    borderRadius: "8px",
                    margin: "10px",
                    minWidth: 270,
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      alignItems: "right",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      onClick={() => {
                        document.getElementById("pdf-inputs").click();
                      }}
                      style={{
                        color: "#fff",
                        backgroundColor: childPughScore.consentientLetterPdf.length > 0 ? "#c1c1c1" : "#800000",
                        height: "32px",
                        width: "12px",
                        marginBottom: "8px",
                      }}
                      disabled={childPughScore.consentientLetterPdf.length > 0 ? true : false}
                    >
                      <AddIcon />
                    </Button>
                    <input
                      id="pdf-inputs"
                      type="file"
                      accept=".pdf, image/*"
                      key={Date.now()}
                      onChange={(e) => {
                        console.log("Okay");
                        handleLogoUpload(e, "consentientLetterPdf");
                      }}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  <p
                    style={{
                      padding: "10px",
                      fontWeight: "600",
                      color: "#323130",
                      paddingTop: "0",
                      marginTop: "-32px",
                      paddingLeft:'2px'
                    }}
                  >
                    Consent Letter
                  </p>
                  {childPughScore.consentientLetterPdf.length > 0 ? (
                    childPughScore.consentientLetterPdf.map(
                      (pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "190px",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                               {pdfFile.toLowerCase().endsWith(".pdf") ? (
    <PictureAsPdfIcon
      style={{
        color: "red",
        marginRight: "5px",
        alignItems: "center",
      }}
    />
  ) : (
    <ImageIcon
      style={{
        color: "blue",
        marginRight: "5px",
        alignItems: "center",
      }}
      alt="Icon"
    />
    
  )}
                              
                              <span
                                className="pdf-file-name"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "120px",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: "#000000",
                                }}
                              >
                                {pdfFile.split('_')[1]}
                              </span>
                            </span>
                          </Grid>
                          <span style={{ display: "flex", marginLeft: "4px" }}>
                            <VisibilityIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                window.open(pdfFile, "_blank");
                              }}
                            />
                            <DeleteIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                const newArr = [
                                  ...childPughScore.consentientLetterPdf,
                                ];
                                newArr.splice(index, 1);
                                setChildPughScore({
                                  ...childPughScore,
                                  consentientLetterPdf: newArr,
                                });
                              }}
                            />
                          </span>
                        </Grid>
                      )
                    )
                  ) : (
                    <>
                      <p style={{ textAlign: "center", letterSpacing: "2px" }}>
                        Upload Consent Letter
                      </p>
                    </>
                  )}
                </Grid>

                <Grid
                  xl={3.5}
                  lg={3.5}
                  md={3.5}
                  sm={12}
                  xs={12}
                  style={{
                    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    paddingBottom: "30px",
                    borderRadius: "8px",
                    margin: "10px",
                    minWidth: 270,
                  }}
                >
                  <Grid>
                    <Grid
                      xs={12}
                      style={{
                        alignItems: "right",
                        justifyContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Button
                        onClick={() => {
                          document.getElementById("pdf-input").click();
                        }}
                        style={{
                          color: "#fff",
                          backgroundColor: "#800000",
                          height: "32px",
                          width: "12px",
                          marginBottom: "8px",
                        }}
                      >
                        <AddIcon />
                      </Button>
                      <input
                        id="pdf-input"
                        type="file"
                        accept=".pdf, image/*"
                        onChange={(e) => {
                          handleLogoUpload(e, "labReports");
                        }}
                        style={{ display: "none" }}
                      />
                    </Grid>
                    <p
                      style={{
                        padding: "10px",
                        fontWeight: "600",
                        color: "#323130",
                        paddingTop: "0",
                        marginTop: "-32px",
                        paddingLeft:'2px'
                      }}
                    >
                      Lab Report
                    </p>
                    {childPughScore.labReports.length > 0 ? (
                      childPughScore.labReports.map((pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "190px",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                               {pdfFile.toLowerCase().endsWith(".pdf") ? (
    <PictureAsPdfIcon
      style={{
        color: "red",
        marginRight: "5px",
        alignItems: "center",
      }}
    />
  ) : (
    <ImageIcon
      style={{
        color: "blue",
        marginRight: "5px",
        alignItems: "center",
      }}
      alt="Icon"
    />
    
  )}
                              
                              <span
                                className="pdf-file-name"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "120px",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: "#000000",
                                }}
                              >
                                {pdfFile.split('_')[1]}
                              </span>
                            </span>
                          </Grid>
                          <span style={{ display: "flex", marginLeft: "4px" }}>
                            <VisibilityIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                window.open(pdfFile, "_blank");
                              }}
                            />
                            <DeleteIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                const newArr = [...childPughScore.labReports];
                                newArr.splice(index, 1);
                                setChildPughScore({
                                  ...childPughScore,
                                  labReports: newArr,
                                });
                              }}
                            />
                          </span>
                        </Grid>
                      ))
                    ) : (
                      <>
                        <p
                          style={{ textAlign: "center", letterSpacing: "2px" }}
                        >
                          Upload lab Report
                        </p>
                      </>
                    )}

                  </Grid>
                </Grid>

                <Grid
                  xl={3.5}
                  lg={3.5}
                  md={3.5}
                  sm={12}
                  xs={12}
                  style={{
                    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    paddingBottom: "30px",
                    borderRadius: "8px",
                    margin: "10px",
                    minWidth: 270,
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      alignItems: "right",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      onClick={() => {
                        document.getElementById("pdf-input2").click();
                      }}
                      style={{
                        color: "#fff",
                        backgroundColor: childPughScore.scripts.length > 0 ? "#c1c1c1":"#800000",
                        height: "32px",
                        width: "12px",
                        marginBottom: "8px",
                      }}
                      disabled={childPughScore.scripts.length > 0 ? true : false}
                    >
                      <AddIcon />
                    </Button>
                    <input
                      id="pdf-input2"
                      type="file"
                      accept=".pdf, image/*"
                      onChange={(e) => {
                        handleLogoUpload(e, "scripts");
                      }}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  <p
                    style={{
                      padding: "10px",
                      fontWeight: "600",
                      color: "#323130",
                      paddingTop: "0",
                      marginTop: "-32px",
                      paddingLeft:'2px'
                    }}
                  >
                    Script
                  </p>
                  {childPughScore.scripts.length > 0 ? (
                    childPughScore.scripts.map((pdfFile, index) => (
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Grid
                          key={index}
                          style={{
                            boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                            width: "190px",
                            height: "54px",
                            borderRadius: "8px",
                            backgroundColor: "#FFFFFF",
                            marginLeft: "10px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                             {pdfFile.toLowerCase().endsWith(".pdf") ? (
    <PictureAsPdfIcon
      style={{
        color: "red",
        marginRight: "5px",
        alignItems: "center",
      }}
    />
  ) : (
    <ImageIcon
      style={{
        color: "blue",
        marginRight: "5px",
        alignItems: "center",
      }}
      alt="Icon"
    />
    
  )}
                            
                            <span
                              className="pdf-file-name"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "120px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#000000",
                              }}
                            >
                              {pdfFile.split('_')[1]}
                            </span>
                          </span>
                        </Grid>
                        <span style={{ display: "flex", marginLeft: "4px" }}>
                          <VisibilityIcon
                            style={{
                              color: "#000",
                              marginRight: "5px",
                              backgroundColor: "#F2F2F2",
                              borderRadius: "6px",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                            }}
                            onClick={() => {
                              window.open(pdfFile, "_blank");
                            }}
                          />
                          <DeleteIcon
                            style={{
                              color: "#000",
                              marginRight: "5px",
                              backgroundColor: "#F2F2F2",
                              borderRadius: "6px",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                            }}
                            onClick={() => {
                              const newArr = [...childPughScore.scripts];
                              newArr.splice(index, 1);
                              setChildPughScore({
                                ...childPughScore,
                                scripts: newArr,
                              });
                            }}
                          />
                        </span>
                      </Grid>
                    ))
                  ) : (
                    <>
                      <p style={{ textAlign: "center", letterSpacing: "2px" }}>
                        Upload Script
                      </p>
                    </>
                  )}
                </Grid>

                <p style={{ width: "100%" }}>Kind Regards</p>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    value={childPughScore.hcpName}
                    onChange={(e) =>
                      setChildPughScore({
                        ...childPughScore,
                        hcpName: e.target.value,
                      })
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      border: "none",
                      maxWidth: "370px",
                      width: "300px",
                    }}
                  />
                </Grid>
                <p>(Name of HCP)</p>
              </Grid> */}
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setChildPughScore(childPughScorePage);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setChildPughScore(childPughScorePage);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={props.handleNext && validateChildPugh}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={props.handleNext && validateChildPugh}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
              {/* <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Button
                    onClick={validateChildPugh}
                  
                  sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    margin: "7px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Preview & Submit
                </Button>
                <SideModal
                  isOpen={isOpen}
                  dismissPanel={dismissPanel}
                  header={"Preview"}
                />
              </Grid> */}
            </Grid>
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChildPughScore;

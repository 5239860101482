import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Snackbar,
  Link,
  Paper,
  Box,
  Grid,
  Divider,
  Typography,
  Hidden,
  Alert,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  Stack,
} from "@mui/material";
import OtpInput from "react18-input-otp";
import { makeStyles } from "@mui/styles";
import logo from "@mui/icons-material/AddBusiness";
import APIRequest from "../../../utils/APIRequest";
import Toaster from "../../../utils/Toaster";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import LocalStorage from "../../../config/LocalStorage";
import SlideInDialog from "../../../templates/dialog/SlideInDialog";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import loginBanner from "../../../assets/images/1.png";
import loginBannerMain from "../../../assets/images/3.png";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ margin: "0 auto !important" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        {LocalStorage.ProjectName}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
    width: "100vh",
    padding: "40px",
    backgroundColor: "#800000",
    backgroundImage: `url(${loginBannerMain})`,
    // border: "2px solid red",
    cursor: "default",
    display: "flex",
    justifyContent: "center",
    backgroundSize: "cover",
  },
  imageGrid: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  titleDiv: {
    height: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  vectorDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "400px",
    width: "500px",
  },
  loginGrid: {
    width: "100%",
    height: "100%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    // width: "50%",
    width: "500px",
    minHeight: "500px",
    overflow: "auto",
    margin: 4,
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    rowGap: "10px",
    boxShadow: " 0px 8px 4px #FB8B96",
    padding: "40px",
    textAlign: "center",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  avatar: {
    margin: 1,
    backgroundColor: "#8BC6EC",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  input: {
    // margin: "10px",
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
  forgotPassword: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  submit: {
    backgroundColor: "#800000 !important",
    borderRadius: "4px !important",
    padding: "6px, 90px, 6px, 90px !important",
    marginTop: "20px !important",
    width: "247px !important",
    fontWeight: "700 !important",
    color: "white",
  },
  textAlign: {
    textAlign: "end",
  },
  register: {
    marginTop: "6px !important",
  },
}));

function Reset(props) {
  const { resetPage, loginPage, setResetPage } = props;
  const classes = useStyles();

  const [OTP, setOTPfield] = useState("");
  const [otp, setOTP] = useState("");

  const [email, setEmail] = useState("");
  const [passwordField, setNewPasswordField] = useState(false);
  const [password, setPassword] = useState("");

  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const GETOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOTP,
      JSON.stringify({ email: email })
    ).then((response) => {
      console.log(response);
      if (response.data.responseCode === 109) {
        setOTPfield(true);
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP has been generated and send to your Email Id",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Email id doesnt exist",
        });
      }
    });
  };

  const verifyOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.OTPverify,
      JSON.stringify({ otp: otp })
    ).then((response) => {
      if (response.data.responseCode === 109) {
        setNewPasswordField(true);
        setOTPfield(false);
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP Verified",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Otp is not matching ",
        });
      }
    });
  };

  const confirmPassword = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.confirmPassword,
      JSON.stringify({ userPassword: password, email: email })
    ).then((response) => {
      console.log(response);
      if (response.data.responseCode === 109) {
        loginPage(true);
        setResetPage(false);
        setNewPasswordField(false);
        setOTPfield(false);
        setEmail("");
        setPassword("");
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "Password successfully reset please login",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    });
  };

  const handleRegister = () => {
    loginPage(false);
    setResetPage(false);
    setOTPfield(false);
    setNewPasswordField(false);
    setEmail("");
    setPassword("");
    setOTP("");
  };

  return (
    <React.Fragment>
      {resetPage ? (
        <div className={classes.paper}>
          <Typography
            style={{
              fontSize: "28px",
              fontWeight: 600,
              color: "#444",
              textAlign: "center",
            }}
          >
            Reset Password
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: 500, color: "#777" }}
          >
            {OTP
              ? "Enter the OTP you received"
              : passwordField
              ? "Enter your new password"
              : "Please provide registered Email for password reset"}
          </Typography>

          <div className={classes.form}>
            <TextField
              className={classes.input}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              id="email"
              autoComplete="off"
              label="Email Id"
              name="email"
              style={{ display: passwordField === true ? "none" : "" }}
              disabled={OTP}
            />
            {OTP ? (
              <div style={{ display: passwordField === true ? "none" : "" }}>
                <div
                  style={{
                    marginBottom: "12px",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "16px",
                      display: passwordField === true ? "none" : "",
                    }}
                  >
                    Enter OTP
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "25px",
                    width: "100%",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <OtpInput
                    id="demo_input"
                    value={otp}
                    onChange={setOTP}
                    numInputs={6}
                    separator={<span style={{ color: "#666" }}> </span>}
                    containerStyle={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 10,
                      width: "95%",
                      maxWidth: "500px",
                      margin: "0 auto",
                    }}
                    inputStyle={{
                      flex: "1",
                      width: "30%",
                      height: "50px",
                      textAlign: "center",
                      border: "2px solid #E1E1E1",
                      backgroundColor: "#F0F5F9",
                      fontSize: "16px",
                      fontWeight: "600",
                      borderRadius: "10px",
                      outline: "none",
                      transition: "border-color 0.3s, background-color 0.3s",
                    }}
                    focusStyle={{
                      borderColor: "#3498db", // Change border color on focus
                      backgroundColor: "#ECF5FC", // Change background color on focus
                    }}
                    isInputNum={true}
                  />
                </div>

                <div
                  style={{
                    display: passwordField === true ? "none" : "",
                    textAlign: "center",
                    marginTop: "20px",
                    color: "black",
                  }}
                >
                  Enter 6 digit OTP received on your email
                </div>
              </div>
            ) : null}

            {passwordField ? (
              <>
                <TextField
                  id="outlined-adornment-password"
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  required
                  label="Enter New Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => confirmPassword()}
                  disabled={password === "" && password.length < 5}
                  style={{ color: "white" }}
                >
                  Confirm Password
                </Button>
              </>
            ) : null}

            {OTP ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => verifyOTP()}
                style={{
                  display: passwordField === true ? "none" : "",
                  margin: "100px",
                }}
              >
                Verify OTP
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => GETOTP()}
                style={{ display: passwordField === true ? "none" : "" }}
              >
                Reset Password
              </Button>
            )}
            <Typography className={classes.register} variant="subtitle1">
              Don't have an account yet?
              <span
                style={{ color: "blue", cursor: "pointer", margin: "2px" }}
                onClick={() => handleRegister()}
              >
                Register
              </span>
            </Typography>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Reset;

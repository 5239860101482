
import React, { useState, useEffect } from "react";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Snackbar,
  Link,
  Paper,
  Box,
  Grid,
  Divider,
  Typography,
  Hidden,
  Alert,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "@mui/icons-material/AddBusiness";
import APIRequest from "../../../utils/APIRequest";
import Toaster from "../../../utils/Toaster";
import ConfigAPIURL from "../../../config/ConfigAPIURL";
import LocalStorage from "../../../config/LocalStorage";
import SlideInDialog from "../../../templates/dialog/SlideInDialog";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import loginBanner from "../../../assets/images/1.png";
import loginBannerMain from "../../../assets/images/3.png";
import ResetPassword from "./ResetPassword";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ margin: "0 auto !important" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        {LocalStorage.ProjectName}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    padding: "40px",
    backgroundColor: "#800000",
    backgroundImage: `url(${loginBannerMain})`,
    // border: "2px solid red",
    cursor: "default",
    display: "flex",
    justifyContent: "center",
    backgroundSize: "cover",
  },
  imageGrid: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  titleDiv: {
    height: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  vectorDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "400px",
    width: "500px",
  },
  loginGrid: {
    width: "100%",
    height: "100%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    // width: "50%",
    width: "500px",
    minHeight: "500px",
    overflow: "auto",
    margin: 4,
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    rowGap: "10px",
    boxShadow: " 0px 8px 4px #FB8B96",
    padding: "40px",
    textAlign: "center",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  avatar: {
    margin: 1,
    backgroundColor: "#8BC6EC",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  input: {
    // margin: "10px",
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
  forgotPassword: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  submit: {
    backgroundColor: "#800000 !important",
    borderRadius: "4px !important",
    padding: "6px, 90px, 6px, 90px !important",
    marginTop: "20px !important",
    width: "247px !important",
    fontWeight: "700 !important",
  },
  textAlign: {
    textAlign: "end",
  },
  register: {
    marginTop: "6px !important",
  },
}));

const userDetails = {
  fname: "",
  lname: "",
  email: "",
  mobileNo: "",
  password: "",
  specialization: "",
};

function Login(props) {
  const classes = useStyles();
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");

  const [disableOtpBtn, setDisableOtpBtn] = useState(false);
  const [slideInDialog, setSlideInDialog] = useState(false);

  const [isLoginPage, setIsLoginPage] = useState(true);

  const [userForm, setUserForm] = useState(userDetails);
  const [resetPage, setResetPage] = useState(false);

  const [otp, setOTP] = useState("");
  const [twoFactAut, setTowFactAuth] = useState(false);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };
  useEffect(() => {
    isLogin();
  }, []);

  const registerUser = async (userForm) => {
    console.log(userForm);
    const keys = Object.keys(userForm);
    const isValid = keys.some((value) => !userForm[value]);
    if (isValid) return Toaster.error("Please fill the text fields");
    let url = ConfigAPIURL.addUser;
    let method = "POST";
    const data = { ...userForm };
    data["username"] = userForm.mobileNo;
    data["practiceNo"] = userForm.password;
    const response = await APIRequest.request(
      method,
      url,
      JSON.stringify(data)
    );
    if (response?.data?.responseCode === 109) {
      setUserForm(userDetails);
      Toaster.sucess("Account created successfully");
      setIsLoginPage(true);
    }
  };
  const handlePasswordChange = () => {
    if (mobileNo === "") {
      setSnakbarValues({
        status: true,
        severity: "error",
        message: "Please enter your mobile number",
      });
      return;
    }
    setSlideInDialog(true);
  };

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
  };

  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "GET",
        ConfigAPIURL.forgotPassword + "?username=" + mobileNo,
        ""
      ).then((response) => {
        setSlideInDialog(false);
        if (response.code === 100 && response.data.responseCode === 101) {
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "New password is generated and sent over the email",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 116
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Exceeded password attempt",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 115
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User login email is not verified",
          });
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User email id not found / wrong email id /deactivated",
          });
        }
      });
    } else {
      setSlideInDialog(false);
    }
  };

  const submitMobileLogin = () => {
    if (mobileNo === "") {
      return Toaster.error("Enter your Mobile Number", "topRight");
    }
    if (password === "") {
      return Toaster.error("Enter your Password", "topRight");
    }
    APIRequest.request(
      "POST",
      ConfigAPIURL.hcpAccountLogin,
      JSON.stringify({
        email: mobileNo,
        password: password,
      })
    ).then((response) => {
      //redirect based on user type
      if (
        response.data.responseCode === 108 ||
        response.data.responseCode === 103
      ) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Wrong Credentials",
        });
      } else if (response.data.responseCode === 116) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Exceeded Password attempt",
        });
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 105
      ) {
        isLogin();
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 122
      ) {
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP sent to your mobile number",
        });
        // setTowFactAuth(true);
      } else if (response.data.responseCode === 104) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Password is not matching, please check your password",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server Error, Please try after sometime",
        });
      }
    });
  };

  const isLogin = () => {
    APIRequest.request("GET", ConfigAPIURL.hcpSessionValidation, "").then(
      (response) => {
        //redirect based on user type
        console.log("userdetails,", response);
        if (response !== undefined && response !== null) {
          if (response.data !== undefined && response.data !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (
                response.data.responseCode === 108 ||
                response.data.responseCode === 103
              ) {
                // setSnakbarValues({
                // 	status: true,
                // 	severity: 'error',
                // 	message: 'Wrong OTP',
                // });
              } else if (response.data.responseCode === 109) {
                let userDetails = {};
                if (
                  response.data.user !== null
                  // response.data.user.permission !== undefined
                ) {
                  userDetails = {
                    email: response.data.user.email,
                    mobileNo: response.data.user.mobileNo,
                    name: response.data.user.name,
                    permission: response.data.user.permission,
                    profileImage: response.data.user.profileImage,
                    userName: response.data.user.userName,
                    userType: response.data.user.userType,
                    isSuperAdmin: response.data.user.isSuperAdmin,
                  };
                  console.log("userdetails,", userDetails);
                  LocalStorage.userDetails = userDetails;
                } else {
                  setSnakbarValues({
                    status: true,
                    severity: "error",
                    message: "User does not have permission to login",
                  });

                  // logoutFunction();
                }

                if (
                  userDetails.userType !== undefined &&
                  userDetails.userType === "hcp"
                ) {
                  if (
                    // userDetails.permission.permission.filter(
                    //   (e) => e.label === "User"
                    // )[0].enable
                    userDetails.userType === "hcp"
                  ) {
                    props.history.replace("/hcp/create");
                  } else if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "CreateOrder"
                    )[0].enable
                  ) {
                    props.history.replace("/admin/create-order");
                  }

                  // } else if (userDetails.userType === 'user') {
                  //   props.history.replace('/user');
                } else {
                  // props.history.replace("/login");
                }
              } else {
                setSnakbarValues({
                  status: true,
                  severity: "error",
                  message: "Server Error, Please try after sometime",
                });
              }
            } else {
              setSnakbarValues({
                status: true,
                severity: "error",
                message: "Server Error, Please try after sometime",
              });
            }
          } else {
            setSnakbarValues({
              status: true,
              severity: "error",
              message: "Server Error, Please try after sometime",
            });
          }
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Server Error, Please try after sometime",
          });
        }
      }
    );
  };
  const _enableResendOTPButton = () => {
    setTimeout(function () {
      setDisableOtpBtn(false);
    }, 5000);
  };

  const logoutFunction = (props) => {
    APIRequest.request("GET", ConfigAPIURL.hcpLogout, "").then((response) => {
      if (response.code === 100) {
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      }
    });
    sessionStorage.clear();
    localStorage.clear();
    // LocalStorage.permission = null;
  };
  const handleMobileNoChange = (event) => {
    const value = event.target.value;
    setMobileNo(value);

    if (value.length > 10) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const handleMobileNoChange2 = (event) => {
    const value = event.target.value;
    setMobileNo(value);
    setUserForm({
      ...userForm,
      mobileNo: value,
    });
    if (value.length > 10) {
      setError2(true);
    } else {
      setError2(false);
    }
  };

  const resendOTP = () => {
    setDisableOtpBtn(true);
    APIRequest.request("POST", ConfigAPIURL.resendOtp, "").then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null &&
          response.data.responseCode === 109
        ) {
          _enableResendOTPButton();

          setSnakbarValues({
            status: true,
            severity: "info",
            message: "Sucessfully sent OTP",
          });
        } else {
          setDisableOtpBtn(false);
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "Please try after some time",
          });
        }
      } else {
        setDisableOtpBtn(false);
        setSnakbarValues({
          status: true,
          severity: "info",
          message: "Please try after some time",
        });
      }
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleResetPage = () => {
    setResetPage(true);
    setIsLoginPage(false);
  };

  const handleRegister = () => {
    setResetPage(false);
    setIsLoginPage(false);
  };

  console.log(resetPage, "resetPage");

  return (
    <>
      {/* <Stack style={{ width: "100vw" }}>
        <img src={loginBanner} alt="banner" />
      </Stack> */}
      <Grid container className={classes.root}>
        {/* <CssBaseline /> */}
        <Grid item xl={8} lg={8} sm={8} xs={12} className={classes.loginGrid}>
          {isLoginPage ? (
            <div className={classes.paper}>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: 600,
                  color: "#444",
                  textAlign: "center",
                }}
              >
                {/* Sign in to {LocalStorage.ProjectName} */}
                Login Now
              </Typography>
              <Typography
                style={{ fontSize: "16px", fontWeight: 500, color: "#777" }}
              >
                Please provide registered mobile number with password to login
              </Typography>
              {!twoFactAut ? (
                <div className={classes.form}>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={mobileNo}
                    onKeyPress={(event) => {
                      return event.charCode >= 48 && event.charCode <= 57
                        ? event
                        : event.preventDefault();
                    }}
                    // onChange={(event) => setMobileNo(event.target.value)}
                    onChange={handleMobileNoChange}
                    id="mobileNo"
                    autoComplete="off"
                    label="Mobile Number"
                    name="mobileNo"
                    error={error}
                  />
                  {error && (
                    <FormHelperText id="mobileNo-error">
                      Mobile Number should not exceed 10 digits.
                    </FormHelperText>
                  )}
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      className={classes.input}
                      variant="outlined"
                      margin="normal"
                      required
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      fullWidth
                      name="password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      autoComplete="off"
                      label="Password"
                      // id="password"
                    />
                  </FormControl>
                  <Typography className={classes.register} variant="subtitle1">
                    <span
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        display:"flex",
                        flexDirection:"row-reverse"
                      }}
                      onClick={() => handleResetPage()}
                    >
                      Forgot password?
                    </span>
                  </Typography>

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => submitMobileLogin()}
                  >
                    Log in
                  </Button>
                  <Typography className={classes.register} variant="subtitle1">
                    Don't have an account yet?
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => handleRegister()}
                    >
                      {" "}
                      Register
                    </span>
                  </Typography>
                </div>
              ) : (
                <div className={classes.form}>
                 
                </div>
              )}

             
            </div>
          ) : (
            <div
              className={classes.paper}
              style={{ display: resetPage === true ? "none" : "" }}
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: 600,
                  color: "#444",
                  textAlign: "center",
                }}
              >
                {/* Sign in to {LocalStorage.ProjectName} */}
                Register Now
              </Typography>
              <Typography
                style={{ fontSize: "16px", fontWeight: 500, color: "#777" }}
              >
                Please enter the specified fields to create an account
              </Typography>
              {!twoFactAut ? (
                <div className={classes.form}>
                  <Grid container>
                    <Grid item xs={6}>
                      <div style={{ paddingRight: ".5rem" }}>
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          margin="normal"
                          required
                          id="mobileNo"
                          autoComplete="off"
                          label="First Name"
                          name="fname"
                          fullWidth="true"
                          value={userForm.fname}
                          onChange={(e) =>
                            setUserForm({
                              ...userForm,
                              fname: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ paddingLeft: ".5rem" }}>
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          margin="normal"
                          required
                          id="lname"
                          autoComplete="off"
                          label="Last Name"
                          name="lname"
                          fullWidth="true"
                          value={userForm.lname}
                          onChange={(e) =>
                            setUserForm({
                              ...userForm,
                              lname: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onKeyPress={(event) => {
                      return event.charCode >= 48 && event.charCode <= 57
                        ? event
                        : event.preventDefault();
                    }}
                    // onChange={(event) => setMobileNo(event.target.value)}
                    value={userForm.mobileNo}
                    onChange={handleMobileNoChange2}
                    // onChange={(e) =>
                    //   setUserForm({
                    //     ...userForm,
                    //     mobileNo: e.target.value,
                    //   })
                    // }
                    id="mobileNo"
                    autoComplete="off"
                    label="Mobile Number"
                    name="mobileNo"
                    error={error2}
                  />
                  {error2 && (
                    <FormHelperText id="mobileNo-error">
                      Mobile Number should not exceed 10 digits.
                    </FormHelperText>
                  )}
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    id="specialization"
                    autoComplete="off"
                    label="Specialization"
                    name="specialization"
                    fullWidth="true"
                    value={userForm.specialization}
                    onChange={(e) =>
                      setUserForm({
                        ...userForm,
                        specialization: e.target.value,
                      })
                    }
                  />
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    id="email"
                    autoComplete="off"
                    label="Email"
                    name="email"
                    fullWidth="true"
                    type="email"
                    value={userForm.email}
                    onChange={(e) =>
                      setUserForm({
                        ...userForm,
                        email: e.target.value,
                      })
                    }
                  />
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    value={userForm.password}
                    onChange={(e) =>
                      setUserForm({
                        ...userForm,
                        password: e.target.value,
                      })
                    }
                    fullWidth
                    type="number"
                    name="password"
                    autoComplete="off"
                    label="Practice Number"
                    // type="password"
                    id="password"
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => registerUser(userForm)}
                  >
                    Register
                  </Button>
                  <Typography className={classes.register} variant="subtitle1">
                    Already have an account ?
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => setIsLoginPage(true)}
                    >
                      {" "}
                      Login Now
                    </span>
                  </Typography>
                </div>
              ) : (
                <div className={classes.form}></div>
              )}
            </div>
          )}
          <ResetPassword
            resetPage={resetPage}
            handleRegister={handleRegister}
            loginPage={setIsLoginPage}
            setResetPage={setResetPage}
          />
        </Grid>
        <Snackbar
          open={snakbarValues.status}
          onClose={notificationClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={3000}
        >
          <Alert severity={snakbarValues.severity}>
            {snakbarValues.message}
          </Alert>
        </Snackbar>
        <SlideInDialog
          title={"Need to reset your password?"}
          contentComponent={
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                align="justify"
              >
                <Typography variant="body1">
                  I would like to reset my password
                </Typography>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                align="justify"
                style={{ margin: "10px 0px" }}
              >
                <Divider />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                align="justify"
              >
                <Typography variant="body1">
                  By clicking “Agree” below you will get an email with a link to
                  reset your password. After clicking the link to reset your
                  password in that email you will get another email with system
                  generated password.'
                </Typography>
              </Grid>
            </Grid>
          }
          fullWidth={true}
          maxWidth={"sm"}
          dialogActionsStatus={true}
          slideInDialog={slideInDialog}
          setSlideInDialog={handleSlideInDialog}
          handleDeleteRequest={handleDeleteRequest}
        />
      </Grid>
    </>
  );
}

export default Login;

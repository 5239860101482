import { makeStyles, useTheme } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import ResponsiveDrawer from "../Drawers/ResponsiveDrawer";
import TopBar from "../Drawers/TopBar";
import { AppProvider } from "../AppState";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, Hidden, Link, PaginationItem, Step, StepLabel, Stepper } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from "@mui/icons-material/Home";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import DraftsIcon from "@mui/icons-material/Drafts";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ManageHistorySharpIcon from "@mui/icons-material/ManageHistorySharp";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../AppState";

import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useState } from "react";
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

// Create a new variant

const useStyles = makeStyles((theme) => ({
  childs: {
    marginLeft: "400px",
    marginTop: "80px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      marginTop: "90px",
      marginTop: "100px",
    },
  },
}));
const steps = [
  "HCP Info",
  "Patient Info",
  "Authorization Request",
  "Patient diagnosis",
  "Patient’s Lab Results",
  "Patient’s Examination Results",
  "Score for Atrial Fibrillation Stroke Risk",
  "Other Medications",
  "Score for Major Bleeding Risk",
  "Other Medications for Bleeding Risk",
  "Patients Lifestyle",
  "Score for Cirrhosis Mortality",
  "Classification"
];
// const MyStepper = () => {

//   // Define the state to keep track of the active step
//   // const [activeStep, setActiveStep] = useState(0);

//   // Define the handlers for changing the active step
//   const handleStepChange = (step) => {
//     setActiveStep(step);
//   };

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   // Add auto-play functionality
//   const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

//   return (
//     <Stack direction="column" spacing={2}>
//       <AutoPlaySwipeableViews
//         index={activeStep}
//         onChangeIndex={handleStepChange}
//         enableMouseEvents
//         slideStyle={{ minHeight: '80vh' }}
//       >
//         {steps.map((step, index) => (
//           <div key={step.label}>
//             {index === activeStep && (
//               <div>
//                 {/* <h2>{step.label}</h2> */}
//                 <Button variant="contained"
//                 // onClick={() => history.push(step.path)}
//                 >Go to step {index + 1}</Button>
//               </div>
//             )}
//           </div>
//         ))}
//       </AutoPlaySwipeableViews>
//       <Pagination count={steps.length} page={activeStep} onChange={(event, step) => setActiveStep(step)} />
//       <Stack direction="row" justifyContent="space-between">
//         <Button onClick={handleBack} disabled={activeStep === 0} startIcon={<KeyboardArrowLeft />} variant="outlined">
//           Back
//         </Button>
//         <Button onClick={handleNext} disabled={activeStep === steps.length - 1} endIcon={<KeyboardArrowRight />} variant="contained">
//           Next
//         </Button>
//       </Stack>
//     </Stack>
//   );
// };

const Layout = ({ children, callback }) => {
  const theme = useTheme();
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const [value, setValue] = React.useState("form");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (location.pathname.startsWith("/hcp/create")) {
      setValue("form");
    } else if (location.pathname.startsWith("/hcp/mydrafts")) {
      setValue("myDrafts");
    } else if (location.pathname.startsWith("/hcp/history")) {
      setValue("history");
    } else if (location.pathname.startsWith("/hcp/setting")) {
      setValue("setting");
    } else {
      setValue("");
    }
  }, [location.pathname]);

  const [activeStep, setActiveStep, completed] = useContext(AppContext);

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  return (
    // <AppProvider>
    <div>
      <div style={{ position: "relative", zIndex: 1,height:'55px' }}>
        <TopBar style={{ zIndex: 2 }} callback={callback} />
        {
        // location.pathname === "/hcp/create"
        (location.pathname.startsWith("/hcp/create")) && (
          
          <Hidden only={["xl", "lg", "md"]}>
            
            {/* <MobileStepper
              variant="dots"
              steps={12}
              position="fixed"
              activeStep={activeStep ?activeStep : 0 }
              sx={{
                top: 0,
                left: 0,
                right: 0,
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "white",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                flexGrow: 1,
                mt: 8,
                ml: 2.5,
                mr: 4,
                position: "fixed",
                top: 0,
              }}
              nextButton={
                <Button size="small" onClick={handleNext} disabled>
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled>
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            /> */}

            {/* ------------- */}
            <>
              <Stack
                direction="column"
                style={{ marginBottom: "-55px",}}
                width="100%"
              >
                <SwipeableViews
                  index={activeStep + 1}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                  slideStyle={{
                    //  minHeight: '80px'
                    marginTop: "100px",
                  }}
                >
                  {steps.map((step, index) => (
                    <div key={step.label}>
                      {index === activeStep && (
                        <div>
                          {/* <Button variant="contained" 
                // onClick={() => history.push(step.path)}
                >Go to step {index + 1}</Button> */}
                        </div>
                      )}
                    </div>
                  ))}
                </SwipeableViews>
                <Box display="flex" justifyContent="center" width="100%" >
                  <Pagination
                    count={steps.length}
                    page={activeStep + 1}
                    onChange={(event, step) => setActiveStep(step - 1)}
                    hideNextButton={true}
                    hidePrevButton={true}
                    siblingCount={2}
                    style={{backgroundColor:"#800000",color:"#fff",borderRadius:'16px'}}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        style={{
                          color: item.type === 'page' && activeStep + 1 === item.page ? '#000' : 'inherit',
                          backgroundColor: item.type === 'page' && activeStep + 1 === item.page ? '#FFE9EB' : 'inherit',
                          borderRadius: item.type === 'page' && activeStep + 1 === item.page ? '20px' : 'inherit',
                          margin: '0 3px'
                        }}
                      />
                    )}
                  />
                </Box>
                
              </Stack>
            </>
          </Hidden>
        )}
      </div>

      <div
        style={{
          position: "relative",
          zIndex: 0,
        }}
      >
        <ResponsiveDrawer callback={callback} />
      </div>
      <div className={classes.childs}>{children}</div>

      <Hidden only={["xl", "lg", "md"]}>
        <BottomNavigation
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            paddingBottom: 1,
            width: "100%",
            zIndex: 1000, // set a higher z-index if necessary to ensure it's on top of other elements
          }}
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction
            label="Form"
            value="form"
            onClick={() => {
              history.push("/hcp/create");
            }}

            icon={
              <HomeIcon
                style={{ color: 
                  value === "form" ? "#800000" : "#868686"
                 }}
              />
            }
            sx={{
              color:
               value === "form" ? "#800000" : "#868686",
              "& .MuiBottomNavigationAction-label": {
                color:
                 value === "form" ? "#800000" : "#868686",
              },
            }}
          />

          <BottomNavigationAction
            label="My Drafts"
            value="myDrafts"
            icon={
              <DraftsIcon
                style={{ color: 
                   value === "myDrafts" ? "#800000" : "#868686"
                   }}
              />
            }

            onClick={() => {
              history.push("/hcp/mydrafts");
            }}
            sx={{
              color:  
              value === "myDrafts" ? "#800000" : "#868686",
              "& .MuiBottomNavigationAction-label": {
                color: 
                 value === "myDrafts" ? "#800000" : "#868686",
              },
            }}
          />

          <BottomNavigationAction
            label="History"
            value="history"
            icon={
              <ManageHistorySharpIcon
                style={{ color: value === "history" ? "#800000" : "#868686" }}
              />
            }
            onClick={() => {
              history.push("/hcp/history");
            }}
            sx={{
              color: value === "history" ? "#800000" : "#868686",
              "& .MuiBottomNavigationAction-label": {
                color: value === "history" ? "#800000" : "#868686",
              },
            }}
          />
          <BottomNavigationAction
            label="Setting"
            value="setting"
            icon={
              <SettingsSharpIcon
                style={{ color: value === "setting" ? "#800000" : "#868686" }}
              />
            }
            onClick={() => {
              history.push("/hcp/setting");
            }}
            sx={{
              color: value === "setting" ? "#800000" : "#868686",
              "& .MuiBottomNavigationAction-label": {
                color: value === "setting" ? "#800000" : "#868686",
              },
            }}
          />
        </BottomNavigation>
      </Hidden>
    </div>
    // </AppProvider>
  );
};

export default Layout;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Hidden,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { connect } from "react-redux";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import MultiLinkLogo from "../../../../assets/images/multilinkLogo.jpg";
import Zyquis from "../../../../assets/images/Zyquis.png";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

const logoutFunction = (props) => {
  APIRequest.request("GET", ConfigAPIURL.hcpLogout, "").then((response) => {
    if (response.code === 100) {
      window.location.href = "#/hcp/login";
    }
  });
  sessionStorage.clear();
  localStorage.clear();
  // LocalStorage.permission = null;
};

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  //   appBar: {
  //     [theme.breakpoints.up("md")]: {
  //       width: `calc(100% - ${drawerWidth}px)`,
  //       marginLeft: drawerWidth,
  //     },
  //     backgroundImage: theme.custom.topHeader.toolbarColor,
  //   },
  appBarDrawerClose: {
    backgroundColor: "#800000",
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  floatRight: {
    textAlign: "right",
  },
  logoutIcon: {
    color: "#fff",
  },
  logo: {
    borderRadius: "50%",
    height: "45px",
    width: "45px",
    marginTop: "8px",
  },
}));

function TopBar(props) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const classes = useStyles();
  const handleNotLogout = () => {
    setShowDeleteDialog(false);
  };
  const handleDialog = () => {
    setShowDeleteDialog((prevState) => !prevState);
  };
  return (
    <div>
      <AppBar
        position="fixed"
        className={
          props.mobileOpen ? classes.appBarDrawerClose : classes.appBar
        }
        style={{ background: "#800000" }}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="#000">
            <img src={Zyquis} alt="Logo" className={classes.logo} style={{marginRight:'8px'}}/>
            <img src={MultiLinkLogo} alt="Logo" className={classes.logo} />
          </Typography>
          

          {/* <Hidden only={["sm", "xs"]}> */}

          <Tooltip ml={2} title={"Logout"}>
            <IconButton
              // onClick={() => logoutFunction()}
              onClick={handleDialog}
              style={{ backgroundColor: "white", borderRadius: "50%" }}
            >
              <ExitToAppIcon
                className={classes.logoutIcon}
                style={{ color: "#000" }}
              />
            </IconButton>
          </Tooltip>
          {/* </Hidden> */}
        </Toolbar>
      </AppBar>
      {/* ---- */}
      <div>
        <Dialog
          hidden={!showDeleteDialog}
          onDismiss={handleNotLogout}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Logout",
            subText: "Do you want to Logout?",
          }}
          modalProps={{
            isBlocking: true,
          }}
        >
          
          <DialogFooter>
            <PrimaryButton
              onClick={() => logoutFunction()}
              style={{ backgroundColor: "#800000", border: "none" }}
              text="Yes"
            />
            <DefaultButton onClick={handleNotLogout} text="No" />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default TopBar;

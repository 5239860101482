/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TableQuery from "./TableQuery";
import TableHeader from "./TableHeader";
import APIRequest from "../../../../utils/APIRequest";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import DataTable from "../../../../templates/tables/DataTable";
import APIRequestDataTableQuery from "../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import LocalStorage from "../../../../config/LocalStorage";
import TableButtons from "../../../../templates/buttons/TableButtons";

import { connect } from "react-redux";
import FormDialog from "../../../../templates/dialog/FormDialog";
import AEVForm from "./AEVForm";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SideModal from "./SideModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const queryBody = {
  active: true,
  page: 0,
  pageSize: 10,
  keyword: "",
  sortOrder: "false",
  sortField: "",
  shopName: "all",
  orderStatus: "all",
  startDate: null,
  endDate: null,
  // status: "all",
  status: "",
  // status: true,
  keyword: "",
};
const userFormDetails = {
  userType: "admin",
  reportId: "",
  hcpName: "",
  patientInfo: {
    name: "",
    mobileNo: "",
  },
  hcpInfo: {
    mobileNo: "",
    patientNumber: "",
  },
  status: "",
  active: true,
  isSuperAdmin: false,
  fname: "",
  lname: "",
  mobileNo: "",
  email: "",
  shopId: "",
  permission: null,
  password: "",
  scoreForCirrhosisMortality: {
    consentientLetterPdf: [],
    labReports: [],
    scripts: [],
  },
  surveyData: {},
};

function Reports(props) {
  const classes = useStyles();

  const [slideInDialog, setSlideInDialog] = useState(false);
  const [resetPasswordAttemptDialog, setResetPasswordAttemptDialog] =
    useState(false);
  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/
  const [query, setQuery] = useState(queryBody);
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });

  const [report, setReport] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    tableQuery(query);
  };
  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  const [roles, setRoles] = useState([]);
  const [userForm, setUserForm] = useState(userFormDetails);
  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([]);
  // useful of on page load
  useEffect(() => {
    tableQuery(query); // calling table api on page load by default value
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);

  console.log("Row details is this", userForm);
  useEffect(() => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails.dataTableDBClick === "dataTableDBClick") {
        {
          LocalStorage.adminButtonPermission.find(function (element) {
            return element.button === "visibility" || element.button === "view"
              ? true
              : false;
          })
            ? tableView()
            : SnackbarUtils.error(
                props.t("toaster.permissionNotAvailable"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
        }
      }
    }
  }, [rowDetails]);
  //keyboard shortcut
  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "add":
        tableAdd();
        break;
      case "edit":
        tableEdit();
        break;
      case "view":
        tableView();
        break;
      case "delete":
        tableDelete();
        break;
      case "esc":
        handleFormDialog();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);
  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery(queryBody);
    tableQuery(queryBody);
  };
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    console.log("this is query,", query);
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === "query" ? true : false;
      })
    ) {
      APIRequestDataTableQuery.request(
        "POST",
        ConfigAPIURL.reportsQuery,
        JSON.stringify(query)
      ).then((tableData) => {
        console.log("this is datataa", tableData);
        setTableData(tableData);
      });

      APIRequest.request("GET", ConfigAPIURL.getReport, "").then((response) => {
        console.log("Data: ", response);
        setReport(response.data.report);
      });
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };
  const notification = (message, type) => {
    SnackbarUtils[type](message, "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  // table record selected callback
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
  };

  // table record selected callback
  const tableDBLCallBack = (dbClickrowDetails) => {
    setRowDetails({
      ...dbClickrowDetails,
      dataTableDBClick: "dataTableDBClick",
    });
  };

  //table button call back function
  const tableAdd = () => {
    setOpenForm({
      status: true,
      title: props.t("order.addOrder"),
      divType: "new",
      functionName: "",
    });
  };
  const tableEdit = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setOpenForm({
        status: true,
        title: props.t("report.editReport"),
        divType: "edit",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableView = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setOpenForm({
        status: false,
        title: props.t("user.viewUser"),
        divType: "view",
        functionName: "",
      });
      setIsOpen(true);
      getViewable();
      return true;
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return false;
    }
  };
  // useEffect(()=>{
  //   if (
  //     rowDetails !== undefined &&
  //     rowDetails !== null
  //   ) {
  //     getViewable();
  //   }
  // },[])
  const getViewable = () => {
    if (rowDetails !== undefined && rowDetails !== null) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getReportsDetails,
        JSON.stringify({ surveyId: rowDetails._id })
      ).then((response) => {
        console.log("editable responsesas", response.data.survey);

        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response.data.survey;
            userResult.dob = new Date(userResult.dob * 1000);

            let permissions = {};
            if (props.roles !== undefined && props.roles !== null) {
              props.roles.map((role) => {
                if (role._id === userResult.permission) {
                  permissions = role;
                }
              });
            }

            setUserForm({
              ...userForm,
              userType: userResult.userType,
              reportId: userResult.reportId,
              hcpName: userResult.hcpName,
              patientInfo: userResult.surveyData.patientInfo,
              hcpInfo: userResult.surveyData.hcpInfo,
              status: userResult.status,
              scoreForCirrhosisMortality:
                userResult.surveyData.scoreForCirrhosisMortality,
              active: userResult.active,
              isSuperAdmin: userResult.isSuperAdmin,
              fname: userResult.fname,
              lname: userResult.lname,
              mobileNo: userResult.mobileNo,
              email: userResult.email,
              shopId: userResult.shopId,
              permission: userResult.permission ? userResult.permission : null,
              password: userResult.password,
              surveyData: userResult.surveyData,
              requestDate: userResult?.createdAt,
            });

            // setSelectedRowId(userResult._id);
          }
        }
      });
    }
  };
  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (query.active === true) {
        setSlideInDialog(true);
      } else {
        SnackbarUtils.error(
          props.t("snackbar.recordAlreadyDeleted"),
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(props.t("snackbar.selectRow"), 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    }
  };
  const tableUpload = () => {
    // console.log('tableUpload');
  };
  const tableApprove = () => {
    // console.log('tableApprove');
  };
  const tableReject = () => {
    // console.log('tableReject');
  };
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');
  };
  const handleFormDialog = () => {
    setRowDetails(null);
    setOpenForm({ status: false, title: "", divType: "", functionName: "" });
    resetQuery();
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

  const handleDeleteRequest = (response) => {
    console.log(rowDetails._id, "this is rowDetails._id");
    if (response === "agree") {
      APIRequest.request(
        "DELETE",
        ConfigAPIURL.deleteReports,
        JSON.stringify({ reportId: rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            tableQuery(query);
            setRowDetails(null);
            setOpenForm({
              status: false,
              title: "",
              divType: "",
              functionName: "",
            });
            SnackbarUtils.sucess(
              props.t("toaster.deleted_success"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
      setRowDetails(null);
      setOpenForm({ status: false, title: "", divType: "", functionName: "" });
    }
  };

  const tableResetPassword = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails.userType !== "admin") {
        return SnackbarUtils.error(
          props.t("snackbar.resetAdmin"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
      if (rowDetails.passwordAttempt === 0) {
        return SnackbarUtils.error(
          props.t("snackbar.alreadyZeroAttempts"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }

      setResetPasswordAttemptDialog(true);
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        style={{ margin: "10px", marginLeft: "35px" }}
      >
        <Grid
          item
          xs={3.5}
          direction="row"
          sx={{ backgroundColor: "white" }}
          style={{
            display: "flex",
            height: "100px",
            justifyContent: "space-evenly",
            paddingLeft: "0",
            paddingTop: "2px",
            margin: "10px",
            borderRadius: "4px",
            alignItems: "center",
          }}
          // xl={3}
        >
          <Grid item xs={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#EEE8FF",
                borderRadius: "50%",
                height: "70px",
                width: "70px",
              }}
            >
              <PersonOutlineIcon
                style={{ fontSize: "40px", color: "#9F0FF1" }}
              />
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <h2 style={{ margin: "0" }}>{report && report.totalHcp}</h2>
            <p style={{ margin: "0", fontWeight: "600" }}>Total HCP</p>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3.5}
          direction="row"
          sx={{ backgroundColor: "white" }}
          style={{
            display: "flex",
            height: "100px",
            justifyContent: "space-evenly",
            paddingLeft: "0",
            paddingTop: "2px",
            margin: "10px",
            borderRadius: "4px",
            alignItems: "center",
          }}
        >
          <Grid item xs={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFECD8",
                borderRadius: "50%",
                height: "70px",
                width: "70px",
              }}
            >
              <PersonIcon style={{ fontSize: "40px", color: "#FF9A62" }} />
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <h2 style={{ margin: "0" }}>{report && report.totalAdmin}</h2>
            <p style={{ margin: "0", fontWeight: "600" }}>Total Admins</p>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3.5}
          direction="row"
          sx={{ backgroundColor: "white" }}
          style={{
            display: "flex",
            height: "100px",
            justifyContent: "space-evenly",
            paddingLeft: "0",
            paddingTop: "2px",
            margin: "10px",
            borderRadius: "4px",
            alignItems: "center",
          }}
        >
          <Grid item xs={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFEAF3",
                borderRadius: "50%",
                height: "70px",
                width: "70px",
              }}
            >
              <ImportContactsIcon
                style={{ fontSize: "40px", color: "#FF60A8" }}
              />
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <h2 style={{ margin: "0" }}>{report && report.totalReport}</h2>
            <p style={{ margin: "0", fontWeight: "600" }}>Total Reports</p>
          </Grid>
        </Grid>
      </Grid>
      {/* -- */}
      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            tableHeader={TableHeader.tableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack}
            searchLabel={props.t("report.searchLabelreport")}
            tableDBLCallBack={tableDBLCallBack}
            queryComponent={<TableQuery query={query} setQuery={handleQuery} />} //filter menu option
            buttonComponent={
              <TableButtons
                // tableAdd={tableAdd}
                tableEdit={tableEdit}
                tableView={tableView}
                tableDelete={tableDelete}
                tableUpload={tableUpload}
                tableApprove={tableApprove}
                tableReject={tableReject}
                tableCheckStatus={tableCheckStatus}
                tableResetPassword={tableResetPassword}
                buttonList={buttonList}
                // tableDownloadInvoice={tableDownloadInvoice}
              />
            }
          />
        </Grid>
      </Grid>
      <FormDialog
        sideDrawerData={props.sideDrawerData}
        label={
          openForm.divType !== "new"
            ? rowDetails !== undefined && rowDetails !== null
              ? rowDetails.userType === "user"
                ? rowDetails.fname + " - " + rowDetails.patientId
                : rowDetails.fname
              : ""
            : ""
        }
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          <AEVForm
            marginTop={60}
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={rowDetails}
            tableQuery={tableQuery}
            isOpen={isOpen}
          />
        }
      />

      <SlideInDialog
        title={props.t("report.deleteReport")}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />

      <SideModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        header={"Preview"}
        date={new Date(userForm?.requestDate * 1000).toLocaleDateString(
          "en-IN"
        )}
        hcpInfo={userForm.hcpInfo}
        patientInfo={userForm.patientInfo}
        patientDiagnosis={userForm.surveyData.patientDiagnosis}
        authorization={userForm.surveyData.authorizationRequest}
        patientLabResult={userForm.surveyData.patientLabResult}
        examResult={userForm.surveyData.patientExaminationDiagnosis}
        atrialFibrillationData={
          userForm.surveyData.scoreForAtrialFibrillationStrokeRisk
        }
        hasBledScore={userForm.surveyData.scoreForMajorBleedingRisk}
        childPughScore={userForm.surveyData.scoreForCirrhosisMortality}
        medicationRows={userForm.surveyData.otherMedications}
        medicationBleedingRows={
          userForm.surveyData.otherMedicationsBleedingRisk
        }
        patientLifeStyle={userForm.surveyData.patientLifestyle}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,

    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Reports)
);

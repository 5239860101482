import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import SecurityIcon from "@mui/icons-material/Security";
import FilterListIcon from "@mui/icons-material/FilterList";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import StorageIcon from "@mui/icons-material/Storage";
import CategoryIcon from "@mui/icons-material/Category";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
class SideBarMenu {
  static menuForSuperAdmin = [
    {
      display: "sideNavMenu.user",
      link: "/admin/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "User",
      icon: <PeopleIcon />,
      eventKey: "users",
      subMenu: [],
    },

    // {
    //   display: "sideNavMenu.order",
    //   link: "/admin/order",
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: "order",
    //   className: "",
    //   permissionLevel: "Order",
    //   icon: <ShoppingCartIcon />,
    //   eventKey: "order",
    //   subMenu: [],
    // },
    {
      display: "sideNavMenu.reports",
      link: "/admin/reports",
      linkPage: false,
      onclick: null,
      urlEndWith: "reports",
      className: "",
      permissionLevel: "",
      icon: <DashboardIcon />,
      eventKey: "reports",
      subMenu: [],
    },

    {
      display: "sideNavMenu.security",
      link: "/admin/security",
      linkPage: false,
      onclick: null,
      urlEndWith: "security",
      className: "",
      permissionLevel: "",
      icon: <SecurityIcon />,
      eventKey: "security",
      subMenu: [
        {
          display: "sideNavMenu.role_function",
          link: "/admin/security/role",
          linkPage: false,
          onclick: null,
          urlEndWith: "role",
          className: "",
          permissionLevel: "Role",
          icon: <FilterListIcon />,
          eventKey: "role",
        },
      ],
    },
    {
      display: "sideNavMenu.setting",
      link: "/admin/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      icon: <SettingsIcon />,
      eventKey: "setting",
      subMenu: [],
    },
  ];
  static menuForAdmin = [
    // CreateOrder
    // {
    //   display: "sideNavMenu.pos",
    //   link: "/admin/order/pos",
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: "pos",
    //   className: "",
    //   permissionLevel: "POS",
    //   icon: <ShoppingCartIcon />,
    //   eventKey: "pos",
    //   subMenu: [],
    // },
    // {
    //   display: "sideNavMenu.dashboard",
    //   link: "/admin/dashboard",
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: "dashboard",
    //   className: "",
    //   permissionLevel: "Dashboard",
    //   icon: <DashboardIcon />,
    //   eventKey: "dashboard",
    //   subMenu: [],
    // },
    {
      display: "sideNavMenu.user",
      link: "/admin/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "",
      icon: <PeopleIcon style={{ color: "#fff" }} />,
      eventKey: "users",
      subMenu: [],
    },

    {
      display: "sideNavMenu.reports",
      link: "/admin/reports",
      linkPage: false,
      onclick: null,
      urlEndWith: "reports",
      className: "",
      permissionLevel: "",
      icon: <DashboardIcon style={{ color: "#fff" }} />,
      eventKey: "reports",
      subMenu: [],
    },

    {
      display: "sideNavMenu.security",
      link: "/admin/security",
      linkPage: false,
      onclick: null,
      urlEndWith: "security",
      className: "",
      permissionLevel: "",
      icon: <SecurityIcon style={{ color: "#fff" }} />,
      eventKey: "security",
      subMenu: [
        {
          display: "sideNavMenu.role_function",
          link: "/admin/security/role",
          linkPage: false,
          onclick: null,
          urlEndWith: "role",
          className: "",
          permissionLevel: "",
          icon: <FilterListIcon style={{ color: "#fff" }} />,
          eventKey: "role",
        },
      ],
    },
    {
      display: "sideNavMenu.setting",
      link: "/admin/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      icon: <SettingsIcon style={{ color: "#fff" }} />,
      eventKey: "setting",
      subMenu: [],
    },
  ];
}
export default SideBarMenu;

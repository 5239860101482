import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ThemeSetting from "./theme";
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
}));

function Setting() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<ThemeSetting />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
			</Grid>
		</div>
	);
}
export default withTranslation("translations")(Setting);

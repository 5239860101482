import React, { createContext, useState } from "react";

export const AppContext = createContext();

const defaultHcpInfo = {
  name: "",
  specialization: "",
  mobileNo: "",
  practiceNumber: "",
  email: "",
  address: "",
};

const defaultPatientInfo = {
  name: "",
  email: "",
  mobileNo: "",
  mobileNo2: "",
  medicalAidName: "",
  membershipNumber: "",
  idProof: "",
  address: "",
};

const defaultAuthorization = {
  diagnosis: "",
  additionalDiagnosis: "",
  icd10Code: "",
  additionalIcd10Code: "",
  name: "",
  writtenInfos: "",
  dosage: "",
  reqType: "",
};
const atrialFibrillationPage = {
  age: "",
  sex: "",
  chfHistory: "",
  hypertensionHistory: "",
  thromboembolismHistory: "",
  vascularDiseaseHistory: "",
  diabeteshistory: "",
  totalRisk: "",
  riskType: "",
};
const hasbledScorePage = {
  hypertension: "",
  renalDisease: "",
  liverDisease: "",
  strokeHistory: "",
  priorMajorBleeding: "",
  LabileINR: "",
  age: "",
  medicationUsage: "",
  alcoholUse: "",
  totalRisk: "",
  riskType: "",
};
const patientDiagnos = {
  diagnosis: "",
  diagnosisDate: "",
  writtenInfos: "",
};

const patientLabResult = {
  inr: "",
  inrDate: "",
  ptTime: "",
  ptTimeDate: "",
  dDimer: "",
  dDimerDate: "",
  ccrl: "",
  ccrlDate: "",
  other: "",
  otherDate: "",
  diagnosis: "",
  diagnosisDate: "",
  writtenInfos: "",
};

const patientExamResult = {
  bloodPressure: "",
  bloodPressureCheckbox: "",
  heartRate: "",
  heartRateCheckbox: "",
  weight: "",
  weightCheckbox: "",
  age: "",
  ageCheckbox: "",
  hba1c: "",
  hba1cCheckbox: "",
  other: "",
  otherCheckbox: "",
  diagnosisDate: "",
  writtenInfos: "",
};
const OtherMedication = [{}, {}, {}];
const medicationBleedingRisk = [{}, {}, {}, {}];

export const AppProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({}); // Initialize an empty object as the global state
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  // const [hcpInfo, setHcpInfo] = useState(defaultHcpInfo);
  // const [patientInfo, setPatientInfo] = useState(defaultPatientInfo);
  // const [authorization, setAuthorization] = useState(defaultAuthorization);
  // const [labResult, setLabResult] = useState(patientLabResult);
  // const [examResult, setExamResult] = useState(patientExamResult);
  // const [diagnosPatient, setDiagnosPatient] = useState(patientDiagnos);
  // const [atrialFibrillationata, setAtrialFibrillationData] = useState(
  //   atrialFibrillationPage
  // );
  // const [hasBledScore, setHasBledScore] = useState(hasbledScorePage);
  // const [medicationRows, setMedicationRows] = useState(OtherMedication);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  // const [medicationBleedingRows, setMedicationBleedingRows] = useState(
  //   medicationBleedingRisk
  // );

  return (
    <AppContext.Provider
      value={[
        activeStep,
        setActiveStep,
        completed,
        setCompleted,
        // hcpInfo,
        // setHcpInfo,
        // patientInfo,
        // setPatientInfo,
        // authorization,
        // setAuthorization,
      ]}
    >
      {children}
    </AppContext.Provider>
  );
};

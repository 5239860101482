import React, { useState, useContext, useEffect, useRef } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  FormHelperText,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PatientInfo from "./PatientInfo";
import { AppContext } from "../AppState";
import Authorizationreq from "./Authorizationreq";
import PatientDiagnos from "./PatientDiagnos";
import LabResult from "./LabResult";
import PatientExamResult from "./PatientExamResult";
import AtrialFibrillation from "./AtrialFibrillation";
import OtherMedications from "./OtherMedications";
import HasBledScore from "./HasBledScore";
import MedicationsBleedingRisk from "./MedicationsBleedingRisk";
import PatientLifestyle from "./PatientLifestyle";
import ChildPughScore from "./ChildPughScore";
import FormValidation from "../../../../utils/FormValidation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useBoolean } from "@fluentui/react-hooks";
import SideModal from "../Modals/SideModal";
import { useHistory } from "react-router-dom";
import { Prompt, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import Toaster from "../../../../utils/Toaster";

import Classification from "./Classification";

const useStyles = makeStyles((theme) => ({
  resetButton: {
    // backgroundColor: "#E8E8E8",
    // borderRadius: "2px",
    // width: "91px",
    // height: "32px",
    // color: "black",
    // textAlign: "center",
    // textTransform: "capitalize",
    // Padding: "6px 28px 6px 28px",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const defaultHcpInfo = {
  name: "",
  specialization: "",
  mobileNo: "",
  practiceNo: "",
  email: LocalStorage.userDetails.email,
  address: "",
};

const defaultPatientInfo = {
  name: "",
  email: "",
  mobileNo: "",
  mobileNo2: "",
  medicalAidName: "",
  membershipNumber: "",
  idProofNumber: "",
  address: "",
};

const defaultAuthorization = {
  diagnosis: "",
  additionalDiagnosis: "",
  icd10Code: "",
  additionalIcd10Code: "",
  name: "",
  writtenInfos: "",
  dosage: "",
  reqType: "",
};
const patientDiagnosisDefaultValue = {
  diagnosis: "",
  diagnosisDate: null,
  patientCurrentCondition: "",
};
const patientLabResultDefaultValue = {
  inr: "",
  inrDate: null,
  ptTime: "",
  ptTimeDate: null,
  dDimer: "",
  dDimerDate: null,
  ccrl: "",
  ccrlDate: null,
  other: "",
  otherDate: null,
  comment: "",
};
const patientExamResult = {
  bloodPressure: "",
  bloodPressureCheckbox: "",
  heartRate: "",
  heartRateCheckbox: "",
  weight: "",
  weightCheckbox: "",
  age: "",
  ageCheckbox: "",
  hba1c: "",
  hba1cCheckbox: "",
  other: "",
  otherCheckbox: "",
  comment: "",
};
const atrialFibrillationPage = {
  age: "",
  sex: "",
  chfHistory: "",
  hypertensionHistory: "",
  thromboembolismHistory: "",
  vascularDiseaseHistory: "",
  diabeteshistory: "",
  totalRisk: "",
  riskType: "",
  ageCriteria: "",
  sexCriteria: "",
  chfHistoryCriteria: "",
  hypertensionHistoryCriteria: "",
  thromboembolismHistoryCriteria: "",
  vascularDiseaseHistoryCriteria: "",
  diabeteshistoryCriteria: "",
};
const hasbledScorePage = {
  hypertension: "",
  renalDisease: "",
  liverDisease: "",
  strokeHistory: "",
  priorMajorBleeding: "",
  LabileINR: "",
  age: "",
  medicationUsage: "",
  alcoholUse: "",
  totalRisk: "",
  riskType: "",
};
const childPughScorePage = {
  bilirubin: "",
  bilirubinScore: "",
  albumin: "",
  albuminScore: "",
  inr: "",
  inrScore: "",
  ascites: "",
  ascitesScore: "",
  encephalopathy: "",
  encephalopathyScore: "",
  totalRisk: "",
  patientHasCirrhosis: "",
  resultIn: "",
  ZYQUISmgBD: "",
  consentientLetterPdf: [],
  labReports: [],
  scripts: [],
  additionalMotivation: [],
  hcpName: "",
};
const OtherMedication = [
  {
    medication: "",
    dose: "",
    condition: "",
  },
  {
    medication: "",
    dose: "",
    condition: "",
  },
  {
    medication: "",
    dose: "",
    condition: "",
  },
];
const medicationBleedingRisk = [
  {
    medication: "",
    dose: "",
    condition: "",
  },
  {
    medication: "",
    dose: "",
    condition: "",
  },
  {
    medication: "",
    dose: "",
    condition: "",
  },
  {
    medication: "",
    dose: "",
    condition: "",
  },
];

const patientLifestyleDefaultValue = {
  notAbleToGetRegularINR: false,
  liveAlone: false,
  historyOfFalling: false,
  doesNotAccessToRegularMeals: false,
  patientOccupationSideEffect: false,
  others: "",
};

const steps = [
  "HCP Info",
  "Patient Info",
  "Authorization Request",
  "Patient diagnosis",
  "Patient’s Lab Results",
  "Patient’s Examination Results",
  "Score for Atrial Fibrillation Stroke Risk",
  "Other Medications",
  "Score for Major Bleeding Risk",
  "Other Medications for Bleeding Risk",
  "Patients Lifestyle",
  "Score for Cirrhosis Mortality",
  "conclusion",
];

const Survey = (props) => {
  const classes = useStyles();

  const [hcpInfo, setHcpInfo] = useState(defaultHcpInfo);
  const [patientInfo, setPatientInfo] = useState(defaultPatientInfo);
  const [authorization, setAuthorization] = useState(defaultAuthorization);
  const [patientLabResult, setPatientLabResult] = useState(
    patientLabResultDefaultValue
  );
  const [examResult, setExamResult] = useState(patientExamResult);
  const [patientDiagnosis, setPatientDiagnosis] = useState(
    patientDiagnosisDefaultValue
  );
  const [atrialFibrillationData, setAtrialFibrillationData] = useState(
    atrialFibrillationPage
  );
  const [hasBledScore, setHasBledScore] = useState(hasbledScorePage);
  const [childPughScore, setChildPughScore] = useState(childPughScorePage);
  const [medicationRows, setMedicationRows] = useState([...OtherMedication]);

  const [medicationBleedingRows, setMedicationBleedingRows] = useState(
    medicationBleedingRisk
  );
  const [pdfFiles, setPdfFiles] = useState([]);
  const [consentientLetterPdf, setConsentientLetterPdf] = useState([]);
  const [scriptPdf, setScriptPdf] = useState([]);
  const [patientLifeStyle, setPatientLifeStyle] = useState(
    patientLifestyleDefaultValue
  );
  const [stage, setStage] = useState({});
  const [error, setError] = useState(false);

  // console.log("this is stage", stage);

  const [activeStep, setActiveStep, completed, setCompleted] =
    useContext(AppContext);

  const [surveyId, setSurveyId] = useState(null);

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [isBlocking, setIsBlocking] = useState(false);
  const shouldUpdateRef = useRef(true);

  // const handleInputChange = (event) => {
  //   setIsBlocking(event.target.value.length > 0);
  // };

  useEffect(() => {
    if (props.match.params.id) {
      setSurveyId(props.match.params.id);

      APIRequest.request(
        "POST",
        ConfigAPIURL.surveyDetails,
        JSON.stringify({
          surveyId: props.match.params.id,
        })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.data !== undefined && response.data !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (
                response.data.responseCode === 108 ||
                response.data.responseCode === 103
              ) {
                props.history.replace("/hcp/login");
              } else if (response.data.responseCode === 109) {
                let result = response.data.survey;
                if (result !== null) {
                  setActiveStep(0);
                  setHcpInfo(result.surveyData.hcpInfo);
                  setPatientInfo(result.surveyData.patientInfo);
                  setAuthorization(result.surveyData.authorizationRequest);
                  setPatientDiagnosis(result.surveyData.patientDiagnosis);
                  setPatientLabResult(result.surveyData.patientLabResult);
                  // setPatientDiagnosis(result.surveyData.patientDiagnosis);
                  setAtrialFibrillationData(
                    result.surveyData.scoreForAtrialFibrillationStrokeRisk
                  );
                  setExamResult(result.surveyData.patientExaminationDiagnosis);
                  setMedicationRows(result.surveyData.otherMedications);
                  setHasBledScore(result.surveyData.scoreForMajorBleedingRisk);
                  setMedicationBleedingRows(
                    result.surveyData.otherMedicationsBleedingRisk
                  );
                  setPatientLifeStyle(result.surveyData.patientLifestyle);
                  setChildPughScore(
                    result.surveyData.scoreForCirrhosisMortality
                  );
                  setStage(response.data.survey.stage);
                  setCompleted(response.data.survey.stage);
                  shouldUpdateRef.current = !shouldUpdateRef.current;
                }
              }
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!props.match.params.id)
      APIRequest.request("GET", ConfigAPIURL.hcpSessionValidation, "").then(
        (response) => {
          if (response !== undefined && response !== null) {
            if (response.data !== undefined && response.data !== null) {
              if (
                response.data.responseCode !== undefined &&
                response.data.responseCode !== null
              ) {
                if (
                  response.data.responseCode === 108 ||
                  response.data.responseCode === 103
                ) {
                  props.history.replace("/hcp/login");
                } else if (response.data.responseCode === 109) {
                  if (response.data.user !== null) {
                    setHcpInfo({
                      ...hcpInfo,
                      name:
                        response.data.user.fname +
                        " " +
                        response.data.user.lname,
                      email: response.data.user.email,
                      mobileNo: response.data.user.mobileNo,
                      specialization: response.data.user?.specialization,
                      practiceNo: response.data.user?.practiceNo,
                      address: response.data.user?.address,
                    });

                    setChildPughScore({
                      ...childPughScore,
                      hcpName:
                        response.data.user.fname +
                        " " +
                        response.data.user.lname,
                    });
                    setIsBlocking(false);
                  }
                }
              }
            }
          }
        }
      );
  }, []);

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setStage(newCompleted);
    handleNext();
  };
  const handleCompleteLastPage = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setStage(newCompleted);
    openPanel();
  };

  const handleActiveStep = (newActiveStep) => {
    setActiveStep(newActiveStep);
  };

  const totalSteps = () => {
    return 13;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateHCPInfo = () => {
    let fieldValidation = [
      "name",
      "specialization",
      "practiceNo",
      "email",
      "mobileNo",
      // "address",
    ];
    FormValidation.validation(fieldValidation, hcpInfo).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          handleComplete();
        }
      }
    );
  };

  const notDraftSave = () => {
    Toaster.error(
      <div
        style={{
          textAlign: "center",
        }}
        className={"pl-2"}
      >
        Can't Save No Data to Draft
      </div>,
      "topRight"
    );
  };

  const saveSurvey = () => {
    const missingKeys = [];
    let falseKey = null;

    for (let i = 0; i < 13; i++) {
      if (!stage.hasOwnProperty(i)) {
        missingKeys.push(i);
      } else if (stage[i] !== true) {
        falseKey = i;
      }
    }

    if (missingKeys.length > 0) {
      dismissPanel();
      setActiveStep(missingKeys[0]);
      Toaster.error(
        <div
          style={{
            textAlign: "center",
          }}
          className={"pl-2"}
        >
          {`Complete this page, ${falseKey}`}
        </div>,
        "topRight"
      );
    }

    // if (falseKey !== null) {
    //   dismissPanel();
    //   Toaster.error(
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //       className={"pl-2"}
    //     >
    //       {`Following pages is not complete  ${falseKey} is not true. Missing keys: ${missingKeys.join(
    //         ", "
    //       )}`}
    //     </div>,
    //     "topRight"
    //   );
    // }
    if (missingKeys.length === 0 && falseKey === null) {
      let data = {
        isDraft: false,
        hcpInfo: hcpInfo,
        patientInfo: patientInfo,
        authorizationRequest: authorization,
        patientDiagnosis: patientDiagnosis,
        patientLabResult: patientLabResult,
        patientExaminationDiagnosis: examResult,
        scoreForAtrialFibrillationStrokeRisk: atrialFibrillationData,
        otherMedications: medicationRows,
        scoreForMajorBleedingRisk: hasBledScore,
        otherMedicationsBleedingRisk: medicationBleedingRows,
        patientLifestyle: patientLifeStyle,
        scoreForCirrhosisMortality: childPughScore,
        stage: stage,
      };
      let method = "POST";
      let url = ConfigAPIURL.createSurvey;

      if (surveyId) {
        method = "PUT";
        url = ConfigAPIURL.updateSurvey;
        data["surveyId"] = surveyId;
      }

      APIRequest.request(method, url, JSON.stringify(data)).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.data !== undefined && response.data !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (
                response.data.responseCode === 108 ||
                response.data.responseCode === 103
              ) {
                props.history.replace("/hcp/login");
              } else if (response.data.responseCode === 109) {
                if (response.data.surveyId !== null) {
                  Toaster.sucess(
                    <div
                      style={{
                        textAlign: "center",
                      }}
                      className={"pl-2"}
                    >
                      {`Survey submitted successfully.`}
                    </div>,
                    "topRight"
                  );
                }
                setIsBlocking(false);
                dismissPanel();
                setHcpInfo(defaultHcpInfo);
                setPatientInfo(defaultPatientInfo);
                setAuthorization(defaultAuthorization);
                setPatientLabResult(defaultPatientInfo);
                setExamResult(patientExamResult);
                setPatientDiagnosis(patientDiagnosisDefaultValue);
                setAtrialFibrillationData(atrialFibrillationPage);
                setHasBledScore(hasbledScorePage);
                setChildPughScore(childPughScorePage);
                setMedicationRows([...OtherMedication]);
                setMedicationBleedingRows(medicationBleedingRisk);
                setPatientLifeStyle(patientLifestyleDefaultValue);
                setStage({});
                setActiveStep(null);
                setCompleted({});
                setSurveyId(null);
                setIsBlocking(false);
                props.history.replace("/hcp/history");
              }
            }
          }
        }
      });
    }
  };

  const saveSurveyAsDraft = () => {
    let data = {
      isDraft: true,
      hcpInfo: hcpInfo,
      patientInfo: patientInfo,
      authorizationRequest: authorization,
      patientDiagnosis: patientDiagnosis,
      patientLabResult: patientLabResult,
      patientExaminationDiagnosis: examResult,
      scoreForAtrialFibrillationStrokeRisk: atrialFibrillationData,
      otherMedications: medicationRows,
      scoreForMajorBleedingRisk: hasBledScore,
      otherMedicationsBleedingRisk: medicationBleedingRows,
      patientLifestyle: patientLifeStyle,
      scoreForCirrhosisMortality: childPughScore,
      stage: stage,
    };
    let method = "POST";
    let url = ConfigAPIURL.createSurvey;

    if (surveyId) {
      method = "PUT";
      url = ConfigAPIURL.updateSurvey;
      data["surveyId"] = surveyId;
    }

    APIRequest.request(method, url, JSON.stringify(data)).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.data !== undefined && response.data !== null) {
          if (
            response.data.responseCode !== undefined &&
            response.data.responseCode !== null
          ) {
            if (
              response.data.responseCode === 108 ||
              response.data.responseCode === 103
            ) {
              props.history.replace("/hcp/login");
            } else if (response.data.responseCode === 109) {
              if (response.data.surveyId !== null) {
                Toaster.sucess(
                  <div
                    style={{
                      textAlign: "center",
                    }}
                    className={"pl-2"}
                  >
                    {`Draft saved successfully.`}
                  </div>,
                  "topRight"
                );

                setSurveyId(response.data.surveyId);
                handleClick();
                setIsBlocking(false);
                props.history.replace("/hcp/mydrafts");
              }
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    setIsBlocking(true);
  }, [
    hcpInfo,
    patientInfo,
    authorization,
    patientLabResult,
    examResult,
    patientDiagnosis,
    atrialFibrillationData,
    hasBledScore,
    childPughScore,
    medicationRows,
    medicationBleedingRows,
    patientLifeStyle,
    stage,
  ]);

  const handleConfirm = (location) => {
    setCompleted({});
    setActiveStep(null);
    setStage({});
    // saveSurveyAsDraft();
  };
  const history = createBrowserHistory();

  const customPrompt = (location) => {
    const message = `Are you sure you want to go to ${location.pathname}? Your changes won't be save. If don't want your entered data be lost please cancel save as draft then go that route.`;
    if (window.confirm(message)) {
      setCompleted({});
      setActiveStep(null);
      setStage({});
      // handleConfirm(location);
      setIsBlocking(false);
      return true;
    } else {
      history.push("/#/hcp/create");
      // handleConfirm();
      // setIsBlocking(true);
      return false;
    }
  };
  return (
    <div>
      <Grid container>
        <Grid
        // xl={12}
        // lg={12}
        // md={12}
        // sm={12}
        // style={{
        //   width: "100%",
        //   justifyContent: "center",
        //   margin: "auto",
        // }}
        >
          <Hidden only={["xs", "md", "sm"]}>
            <Prompt when={isBlocking} message={customPrompt} />
          </Hidden>

          {activeStep === 0 ? (
            // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // >
            <>
              <Grid
                style={{
                  boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                  padding: "15px",
                  paddingBottom: "30px",
                  paddingTop: "2px",
                  width: "96%",
                  borderRadius: "8px",
                  // margin: "10px",
                }}
              >
                <h2 style={{ color: "#323130", fontSize: "28px" }}>
                  Information of the Healthcare Professional
                </h2>
                <p
                  style={{
                    marginBottom: "8px",
                    marginTop: "8px",
                    paddingTop: "0",
                  }}
                >
                  To get started, fill out some basic information about the
                  Healthcare Professional.
                </p>
                <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: 5, paddingTop: 15, paddingBottom: 15 }}
                  >
                    <InputLabel
                      htmlFor="name"
                      style={{
                        color: "#1A1A1A",
                        marginBottom: "4px",
                        // fontWeight: "550",
                      }}
                    >
                      Title and Name<span style={{ color: "#D13438" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      // label="Title and Name"
                      id="name"
                      required
                      value={hcpInfo.name}
                      onChange={(e) => {
                        setHcpInfo({
                          ...hcpInfo,
                          name: e.target.value,
                        });
                      }}
                      InputProps={{
                        style: {
                          height: "44px", // adjust this value as needed
                          border: "1px solid #d9d9d9",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: 5, paddingTop: 15, paddingBottom: 15 }}
                  >
                    <InputLabel
                      htmlFor="specialization"
                      style={{
                        color: "#1A1A1A",
                        marginBottom: "4px",
                        // fontWeight: "550",
                      }}
                    >
                      Specialization<span style={{ color: "#D13438" }}>*</span>
                    </InputLabel>
                    <TextField
                      id="specialization"
                      fullWidth
                      required
                      // label="Specialization"
                      value={hcpInfo.specialization}
                      onChange={(e) => {
                        setHcpInfo({
                          ...hcpInfo,
                          specialization: e.target.value,
                        });
                      }}
                      InputProps={{
                        style: {
                          height: "44px", // adjust this value as needed
                          border: "1px solid #d9d9d9",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: 5, paddingTop: 15, paddingBottom: 15 }}
                  >
                    <InputLabel
                      htmlFor="practiceNo"
                      style={{
                        color: "#1A1A1A",
                        marginBottom: "4px",
                        // fontWeight: "550",
                      }}
                    >
                      Practice Number<span style={{ color: "#D13438" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="practiceNo"
                      required
                      // label="Practice Number"
                      value={hcpInfo.practiceNo}
                      onChange={(e) => {
                        setHcpInfo({
                          ...hcpInfo,
                          practiceNo: e.target.value,
                        });
                      }}
                      InputProps={{
                        style: {
                          height: "44px", // adjust this value as needed
                          border: "1px solid #d9d9d9",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: 5, paddingTop: 15, paddingBottom: 15 }}
                  >
                    <InputLabel
                      htmlFor="email"
                      style={{
                        color: "#1A1A1A",
                        marginBottom: "4px",
                        // fontWeight: "550",
                      }}
                    >
                      Email<span style={{ color: "#D13438" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="email"
                      required
                      value={hcpInfo.email}
                      onChange={(e) => {
                        setHcpInfo({
                          ...hcpInfo,
                          email: e.target.value,
                        });
                      }}
                      InputProps={{
                        style: {
                          height: "44px", // adjust this value as needed
                          border: "1px solid #d9d9d9",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: 5, paddingTop: 15, paddingBottom: 15 }}
                  >
                    <InputLabel
                      htmlFor="mobileNo"
                      style={{
                        color: "#1A1A1A",
                        marginBottom: "4px",
                        // fontWeight: "550",
                      }}
                    >
                      Mobile Number<span style={{ color: "#D13438" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="mobileNo"
                      required
                      value={hcpInfo.mobileNo}
                      onChange={(e) => {
                        const value = e.target.value;

                        setHcpInfo({
                          ...hcpInfo,
                          mobileNo: value,
                        });
                        // setMobileNo(value);
                        if (value.length > 10) {
                          setError(true);
                        } else {
                          setError(false);
                        }
                      }}
                      InputProps={{
                        style: {
                          height: "44px", // adjust this value as needed
                          border: "1px solid #d9d9d9",
                        },
                      }}
                      error={error}
                    />
                    {error && (
                      <FormHelperText id="mobileNo-error">
                        Mobile Number should not exceed 10 digits.
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: 5, paddingTop: 15, paddingBottom: 15 }}
                  >
                    <InputLabel
                      htmlFor="address"
                      style={{
                        color: "#1A1A1A",
                        marginBottom: "4px",
                        // fontWeight: "550",
                      }}
                    >
                      Address
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="address"
                      required
                      multiline
                      value={hcpInfo.address}
                      onChange={(e) => {
                        setHcpInfo({
                          ...hcpInfo,
                          address: e.target.value,
                        });
                      }}
                      InputProps={{
                        style: {
                          height: "74px", // adjust this value as needed
                          border: "1px solid #d9d9d9",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container sx={{ mt: 3, justifyContent: "space-between" }}>
                  <Grid>
                    <Button>Reset </Button>
                    <Button>Save as Draft </Button>
                  </Grid>
                  <Grid>
                    <Button onClick={props.handleNext} sx={{ mr: 2 }}>
                      Next
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid
                container
                sx={{
                  mt: 3,
                  justifyContent: "space-between",
                  marginBottom: "190px",
                }}
              >
                <Grid style={{ marginTop: "10px" }}>
                  <Hidden only={["xs"]}>
                    <Button
                      className={classes.resetButton}
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "91px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={(e) => {
                        setHcpInfo(defaultHcpInfo);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "121px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        margin: "7px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={
                        stage[1] || stage[2] ? saveSurveyAsDraft : notDraftSave
                      }
                    >
                      Save as Draft
                    </Button>
                  </Hidden>

                  <Hidden only={["lg", "sm", "xl", "md"]}>
                    <Button
                      className={classes.resetButton}
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "40px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={(e) => {
                        setHcpInfo(defaultHcpInfo);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "40px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        margin: "7px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={stage ? saveSurveyAsDraft : notDraftSave}
                    >
                      Draft{" "}
                    </Button>
                  </Hidden>
                </Grid>
                <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                  <Hidden only={["xs"]}>
                    <Button
                      onClick={validateHCPInfo}
                      sx={{ mr: 2 }}
                      style={{
                        backgroundColor: "#8F1622",
                        borderRadius: "2px",
                        width: "96px",
                        height: "32px",
                        color: "#fff",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        margin: "7px",
                        marginRight: "30px",
                        fontFamily: "Segoe UI",
                      }}
                    >
                      Next
                    </Button>
                  </Hidden>
                  <Hidden only={["lg", "sm", "xl", "md"]}>
                    <Button
                      onClick={validateHCPInfo}
                      // sx={{ mr: 2 }}
                      style={{
                        backgroundColor: "#800000",
                        borderRadius: "2px",
                        width: "32px",
                        height: "32px",
                        color: "#fff",
                        textAlign: "center",
                        textTransform: "capitalize",
                        // Padding: "6px 28px 6px 28px",
                        margin: "7px",
                        // marginRight: "10px",
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                  </Hidden>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </>
          ) : activeStep === 1 ? (
            // <Box>
            <PatientInfo
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              patientInfo={patientInfo}
              setPatientInfo={setPatientInfo}
              defaultPatientInfo={defaultPatientInfo}
            />
          ) : // </Box>
          activeStep === 2 ? (
            <Authorizationreq
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              authorization={authorization}
              setAuthorization={setAuthorization}
              defaultAuthorization={defaultAuthorization}
            />
          ) : activeStep === 3 ? (
            <PatientDiagnos
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              patientDiagnosis={patientDiagnosis}
              setPatientDiagnosis={setPatientDiagnosis}
              patientDiagnosisDefaultValue={patientDiagnosisDefaultValue}
            />
          ) : activeStep === 4 ? (
            <LabResult
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              patientLabResult={patientLabResult}
              setPatientLabResult={setPatientLabResult}
              patientLabResultDefaultValue={patientLabResultDefaultValue}
            />
          ) : activeStep === 5 ? (
            <PatientExamResult
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              examResult={examResult}
              setExamResult={setExamResult}
              patientExamResult={patientExamResult}
            />
          ) : activeStep === 6 ? (
            <AtrialFibrillation
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              atrialFibrillationata={atrialFibrillationData}
              setAtrialFibrillationData={setAtrialFibrillationData}
              atrialFibrillationPage={atrialFibrillationPage}
            />
          ) : activeStep === 7 ? (
            <OtherMedications
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              medicationRows={medicationRows}
              setMedicationRows={setMedicationRows}
              OtherMedication={OtherMedication}
            />
          ) : activeStep === 8 ? (
            <HasBledScore
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              hasBledScore={hasBledScore}
              setHasBledScore={setHasBledScore}
              hasbledScorePage={hasbledScorePage}
            />
          ) : activeStep === 9 ? (
            <MedicationsBleedingRisk
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              medicationBleedingRows={medicationBleedingRows}
              setMedicationBleedingRows={setMedicationBleedingRows}
              medicationBleedingRisk={medicationBleedingRisk}
            />
          ) : activeStep === 10 ? (
            <PatientLifestyle
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              patientLifeStyle={patientLifeStyle}
              setPatientLifeStyle={setPatientLifeStyle}
              patientLifestyleDefaultValue={patientLifestyleDefaultValue}
            />
          ) : activeStep === 11 ? (
            <ChildPughScore
              openPanel={openPanel}
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleComplete}
              handlePrev={handlePrev}
              childPughScore={childPughScore}
              setChildPughScore={setChildPughScore}
              hcpInfo={hcpInfo}
              childPughScorePage={childPughScorePage}
            />
          ) : activeStep === 12 ? (
            <Classification
              openPanel={openPanel}
              saveSurveyAsDraft={saveSurveyAsDraft}
              handleNext={handleCompleteLastPage}
              handlePrev={handlePrev}
              childPughScore={childPughScore}
              setChildPughScore={setChildPughScore}
              hcpInfo={hcpInfo}
              childPughScorePage={childPughScorePage}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <SideModal
        isOpen={isOpen}
        dismissPanel={dismissPanel}
        hcpInfo={hcpInfo}
        patientInfo={patientInfo}
        authorization={authorization}
        patientLabResult={patientLabResult}
        examResult={examResult}
        patientDiagnosis={patientDiagnosis}
        atrialFibrillationData={atrialFibrillationData}
        hasBledScore={hasBledScore}
        childPughScore={childPughScore}
        medicationRows={medicationRows}
        medicationBleedingRows={medicationBleedingRows}
        pdfFiles={pdfFiles}
        consentientLetterPdf={consentientLetterPdf}
        scriptPdf={scriptPdf}
        patientLifeStyle={patientLifeStyle}
        header={"Preview"}
        handleActiveStep={handleActiveStep}
        saveSurvey={saveSurvey}
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Survey saved as draft successfully
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default Survey;

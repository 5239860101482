import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Autocomplete,
  Box,
  Stack,
  FormControl,
  TextareaAutosize,
  Typography,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import LocalStorage from "../../../../config/LocalStorage";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import BillingModel from "../order/BillingModel";
import DeleteIcon from "@mui/icons-material/Delete";
import isEmpty from "../../../../utils/isEmpty";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  formField: {
    "& .MuiInputLabel-shrink": {
      color: "black !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));
const userFormDetails = {
  userType: "admin",
  active: true,
  isSuperAdmin: false,
  fname: "",
  lname: "",
  userName: "",
  mobileNo: "",
  practiceNo: "",
  specialization: "",
  email: "",

  permission: null,

  password: "",
  // pan: {
  //   value: "",
  //   image: "",
  // },
  // aadhaar: {
  //   value: "",
  //   image: "",
  // },
  deliveryAddress: [],
};

function AEVForm(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(userFormDetails);
  console.log("this is permission", userForm?.permission);
  const [shopFilterOptions, setShopFilterOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editAddress, setEditAddress] = useState({
    landmark: "",
    city: "",
    state: "",
    address: "",
    pincode: "",
    country: "",
  });
  const [editForm, setEditForm] = useState({ status: false, index: 0 });
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  // const getCountryList = () => {
  //   APIRequest.request("GET", ConfigAPIURL.getCountryList, "").then(
  //     (response) => {
  //       if (response !== undefined && response !== null) {
  //         if (response.code === 100 && response.data.responseCode === 109) {
  //           setCountryList(response.data.countries.map((e) => e.name));
  //         }
  //       }
  //     }
  //   );
  // };
  // const getStateList = () => {
  //   if (isEmpty(editAddress?.country)) {
  //     return;
  //   }
  //   APIRequest.request(
  //     "POST",
  //     ConfigAPIURL.getStateList,
  //     JSON.stringify({ country: editAddress?.country })
  //   ).then((response) => {
  //     if (response !== undefined && response !== null) {
  //       if (response.code === 100 && response.data.responseCode === 109) {
  //         setStateList(response.data?.states.map((e) => e.name));
  //       }
  //     }
  //   });
  // };

  // const getCityList = () => {
  //   APIRequest.request(
  //     "POST",
  //     ConfigAPIURL.getCityList,
  //     JSON.stringify({ state: editAddress?.state, searchKey: "" })
  //   ).then((response) => {
  //     if (response !== undefined && response !== null) {
  //       if (response.code === 100 && response.data.responseCode === 109) {
  //         setCityList(response.data.result.map((e) => e.name));
  //       }
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getCountryList();
  //   getShopFilters();
  // }, []);

  // useEffect(() => {
  //   getStateList();
  // }, [editAddress?.country]);

  // useEffect(() => {
  //   getCityList();
  // }, [editAddress?.state]);

  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "hcp" });
    }
  }, [props.page]);

  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "hcp" });
    } else {
      setUserForm(userFormDetails);
    }
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getUserDetails,
        JSON.stringify({ userId: props.rowDetails._id })
      ).then((response) => {
        console.log("thata response of get editable", response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response.data.user;
            userResult.dob = new Date(userResult.dob * 1000);

            let permissions = {};
            if (props.roles !== undefined && props.roles !== null) {
              props.roles.map((role) => {
                if (role._id === userResult.permission) {
                  permissions = role;
                }
              });
            }

            setUserForm({
              ...userForm,
              userType: userResult.userType,
              active: userResult.active,
              isSuperAdmin: userResult.isSuperAdmin,
              fname: userResult.fname,
              lname: userResult.lname,
              userName: userResult.userName,
              mobileNo: userResult.mobileNo,
              practiceNo: userResult.practiceNo,
              specialization: userResult.specialization,
              email: userResult.email,
              shopId: userResult.shopId,
              permission: userResult?.permissions,
              password: userResult.password,
              pan: userResult.pan,
              deliveryAddress: userResult?.deliveryAddress,
              gst: userResult?.gst,
              aadhaar: userResult?.aadhaar,
            });

            setSelectedRowId(userResult._id);
          }
        }
      });
    }
  };
  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.addUser;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.updateUser;
      userForm["userId"] = selectedRowId;
      method = "PUT";
    }

    let fieldValidation =
      userForm.userType === "hcp" || "admin"
        ? LocalStorage.userDetails.isSuperAdmin
          ? [
              "fname",
              "email",
              "mobileNo",
              "userType",
              "lname",
              "password",
              "userName",
              // "specialization",
              "practiceNo",
            ]
          : [
              "fname",
              "email",
              "mobileNo",
              "userType",
              "lname",
              "userName",
              // "specialization",
              "practiceNo",
              "password",
            ]
        : LocalStorage.userDetails.isSuperAdmin
        ? props.formDialog.divType === "new"
          ? [
              "fname",
              "email",
              "mobileNo",
              "userType",
              "password",
              "permission",
              "lname",
              "userName",
              "practiceNo",
            ]
          : [
              "fname",
              "email",
              "mobileNo",
              "userType",
              "permission",
              "lname",
              "password",
              "userName",
              "practiceNo",
            ]
        : props.formDialog.divType === "new"
        ? [
            "fname",
            "email",
            "mobileNo",
            "userType",
            "password",
            "permission",
            "lname",
            "userName",
            "practiceNo",
          ]
        : [
            "fname",
            "email",
            "mobileNo",
            "userType",
            "permission",
            "lname",
            "password",
            "userName",
            "practiceNo",
          ];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          APIRequest.request(method, url, JSON.stringify(userForm)).then(
            (response) => {
              console.log("this is send server res", userForm);
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    method === "POST"
                      ? props.t("snackbar.savedSuccess")
                      : props.t("snackbar.updatedSuccess"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  setUserForm(userFormDetails);

                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const resetForms = () => {
    setEditAddress({
      ...editAddress,
      state: "",
      city: "",
      landmark: "",
      address: "",
      pincode: "",
      country: "",
    });
  };

  const handleDeleteAddress = async (ind) => {
    const billingData = [...userForm?.deliveryAddress];
    billingData.splice(ind, 1);
    setUserForm({
      ...userForm,
      deliveryAddress: billingData,
    });
  };

  const handleBillingEdit = (event, ind) => {
    //open the modal and set address from the duplicate of billings adress
    setEditForm({
      ...editForm,
      status: true,
      index: ind,
    });

    setModalOpen(true);
    const formData = [...userForm.deliveryAddress];
    setEditAddress(formData[ind]);
  };
  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center" }}
              >
                <h2>{props.t("user.userInformation")}</h2>
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="userType"
                  label={props.t("user.userType")}
                  className={classes.userTypeMenuItem && classes.formField}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.userType}
                  value={userForm.userType}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      userType: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"hcp"}>
                    {props.t("user.hcp")}
                  </MenuItem>
                  <MenuItem key={1} value={"admin"}>
                    {props.t("user.admin")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="active"
                  label={props.t("user.active")}
                  className={classes.userTypeMenuItem && classes.formField}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("user.yes")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("user.no")}
                  </MenuItem>
                </TextField>
              </Grid>
              {userForm.userType === "admin" ? (
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Autocomplete
                    id="permission"
                    disabled={
                      props.formDialog.divType === "new" ||
                      props.formDialog.divType === "edit"
                        ? false
                        : true
                    }
                    options={props.roles}
                    value={userForm.permission}
                    getOptionLabel={(option) => option.name}
                    // onInputChange={getInventorList}
                    onChange={(event, value) =>
                      setUserForm({
                        ...userForm,
                        permission: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={
                          classes.userTypeMenuItem && classes.formField
                        }
                        required
                        label={props.t("user.permission")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}

              {/* {userForm.userType === "admin" ? (
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="isSuperAdmin"
                    className={classes.userTypeMenuItem}
                    label={props.t("user.isSuperAdmin")}
                    select
                    required
                    fullWidth
                    defaultValue={userForm.isSuperAdmin}
                    value={userForm.isSuperAdmin}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        isSuperAdmin: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={true}>
                      {props.t("user.yes")}
                    </MenuItem>
                    <MenuItem key={1} value={false}>
                      {props.t("user.no")}
                    </MenuItem>
                  </TextField>
                </Grid>
              ) : (
                <></>
              )} */}

              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="fname"
                  label={props.t("user.fname")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.fname}
                  fullWidth
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      fname: event.target.value,
                    })
                  }
                  className={classes.formField}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="lname"
                  label={props.t("user.lname")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.lname}
                  fullWidth
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      lname: event.target.value,
                    })
                  }
                  className={classes.formField}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="userName"
                  label={props.t("user.username")}
                  autoComplete="something-unsupported"
                  required
                  fullWidth
                  value={userForm.userName}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      userName: event.target.value,
                    })
                  }
                  className={classes.formField}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="mobileNo"
                  label={props.t("user.mobileNo")}
                  autoComplete="something-unsupported"
                  type="number"
                  required
                  fullWidth
                  disabled={props.formDialog.divType === "edit"}
                  value={userForm.mobileNo}
                  inputProps={{ maxLength: 10 }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      mobileNo: event.target.value,
                    })
                  }
                  className={classes.formField}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="specialization"
                  label={props.t("user.specialization")}
                  autoComplete="something-unsupported"
                  required
                  fullWidth
                  // disabled={props.formDialog.divType === "edit"}
                  value={userForm.specialization}
                  inputProps={{ maxLength: 10 }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      specialization: event.target.value,
                    })
                  }
                  className={classes.formField}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="practiceNo"
                  label={props.t("user.practiceNo")}
                  autoComplete="something-unsupported"
                  required
                  fullWidth
                  type="number"
                  // disabled={props.formDialog.divType === "edit"}
                  value={userForm.practiceNo}
                  inputProps={{ maxLength: 10 }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      practiceNo: event.target.value,
                    })
                  }
                  className={classes.formField}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="email"
                  label={props.t("user.email")}
                  autoComplete="something-unsupported"
                  required
                  fullWidth
                  value={userForm.email}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      email: event.target.value,
                    })
                  }
                  className={classes.formField}
                />
              </Grid>

              {/* gst or pan card */}

              {/* <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="Pancard"
                  label={props.t("user.pancard")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm?.pan?.value}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      pan: {
                        ...userForm.pan,
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="aadhar"
                  label={props.t("user.adhar")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm?.aadhaar?.value}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      aadhaar: {
                        ...userForm.pan,
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>

              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="GST No"
                  label={props.t("user.GstNo")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm?.gst}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      gst: event.target.value,
                    })
                  }
                />
              </Grid> */}

              {/* {userForm.userType === "Admin" && ( */}
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="password"
                  label={props.t("user.password")}
                  type="password"
                  autoComplete="something-unsupported"
                  fullWidth
                  required
                  value={userForm.password}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      password: event.target.value,
                    })
                  }
                  // disabled={
                  //   userForm.userType === "admin" &&
                  //   props.formDialog.divType === "new"
                  //     ? false
                  //     : true
                  // }
                  className={classes.formField}
                />
              </Grid>
              {/* )} */}

              {/* {LocalStorage?.userDetails?.isSuperAdmin ? (
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="shopId"
                    label={props.t("user.shopName")}
                    select
                    required
                    fullWidth
                    defaultValue={userForm.shopId}
                    value={userForm.shopId}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        shopId: event.target.value,
                      })
                    }
                  >
                    {shopFilterOptions.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : null} */}
              {/* 
              {userForm.userType === "admin" ? (
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="permission"
                    label={props.t("user.permission")}
                    select
                    required
                    fullWidth
                    defaultValue={userForm.permission}
                    value={userForm.permission}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        permission: event.target.value,
                      })
                    }
                  >
                    {props.roles.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : null} */}

              {/* <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center" }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="3"
                >
                  <h2>{props.t("user.deliveryAddress")}</h2>
                  <AddCircleOutlineIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      resetForms();
                      setModalOpen(true);
                    }}
                  />
                </Box>
              </Grid> */}
              {/* <BillingModel open={modalOpen} setOpen={setModalOpen}> */}
              {/* <Stack> */}
              {/* <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="3"
                  >
                    <h2>{"Address"}</h2>
                  </Box>
                </Grid> */}
              {/* <Stack gap={2}>
                  <FormControl>
                    
                    <Autocomplete
                      id="country"
                      options={countryList}
                      getOptionLabel={(option) => option}
                      value={editAddress.country}
                      onChange={(event, value) =>
                        setEditAddress({
                          ...editAddress,
                          country: value,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={props.t("order.country")}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack gap={2} mt={2}>
                  <TextField
                    size="small"
                    label="Landmark"
                    value={editAddress?.landmark}
                    onChange={(e) => {
                      setEditAddress({
                        ...editAddress,
                        landmark: e.target.value,
                      });
                    }}
                  />
                  
                </Stack>
                <Stack mt={2}>
                  
                  <Autocomplete
                    id="state"
                    required
                    options={stateList}
                    getOptionLabel={(option) => option}
                    value={editAddress.state}
                    onChange={(event, value) =>
                      setEditAddress({
                        ...editAddress,
                        state: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={props.t("order.state")}
                      />
                    )}
                  />
                </Stack>
                <Stack mt={2}>
                
                  <Autocomplete
                    id="city"
                    required
                    options={cityList}
                    getOptionLabel={(option) => option}
                    value={editAddress.city}
                    onChange={(event, value) =>
                      setEditAddress({
                        ...editAddress,
                        city: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={props.t("order.city")}
                      />
                    )}
                  />
                </Stack>

                <Stack mt={2}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "center" }}
                  ></Grid>

                  <Stack mt={2}>
                    <TextareaAutosize
                      // label="address"
                      placeholder="Address"
                      multiline
                      rows={2}
                      maxRows={4}
                      style={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        height: "80px",
                        outline: "none",
                      }}
                      minCols={4}
                      value={editAddress?.address}
                      onChange={(e) => {
                        setEditAddress({
                          ...editAddress,
                          address: e.target.value,
                        });
                      }}
                    />
                  </Stack> */}
              {/* </Stack> */}
              {/* </Stack> */}
              {/* <Stack alignItems="center">
                <Button
                  onClick={() => updateBillingAddress()}
                  style={{ marginTop: "1rem", borderRadius: "25px" }}
                  variant="contained"
                >
                  {editForm?.status ? "Update" : "Submit"}
                </Button>
              </Stack> */}
              {/* </BillingModel> */}
              {/* rendering all the addresses */}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  padding: " 0 0 0 2rem ",
                  marginBottom: "1rem",
                }}
              >
                {userForm?.deliveryAddress &&
                  userForm?.deliveryAddress.length > 0 &&
                  userForm?.deliveryAddress.map((val, ind) => {
                    return (
                      <Stack
                        onDoubleClick={(e) => handleBillingEdit(e, ind)}
                        key={ind}
                        direction="row"
                        justifyContent="space-between"
                        style={{
                          boxShadow: "0px 1px 2px #c8c8c8",
                          marginTop: "1rem",
                          alignItems: "center",
                          padding: "0 1rem",
                        }}
                      >
                        {" "}
                        <Stack>
                          <Stack style={{ padding: ".5rem 0", gap: 4 }}>
                            <Stack style={{ flexDirection: "row", gap: 8 }}>
                              <Typography
                                style={{ textTransform: "capitalize" }}
                                variant="subtitle1"
                              >
                                {val?.country}
                              </Typography>
                              |
                              <Typography
                                style={{ textTransform: "capitalize" }}
                                variant="subtitle1"
                              >
                                {val?.state}
                              </Typography>
                              |
                              {/* <Typography variant="subtitle1">
                                Gst No
                              </Typography> */}
                              <Typography variant="subtitle1">
                                {val?.city}
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography variant="subtitle1">
                                {val?.landmark}
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography variant="subtitle1">
                                {val?.address}
                              </Typography>
                            </Stack>
                            {/* <Stack direction="row" gap={1}>
                              <Typography variant="subtitle1">
                                {val?.bankName}
                              </Typography>
                              |
                              <Typography variant="subtitle1">
                                {val?.accountNo}
                              </Typography>
                            </Stack> */}
                          </Stack>
                        </Stack>
                        <Tooltip title="Delete">
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteAddress(ind)}
                          />
                        </Tooltip>
                      </Stack>
                    );
                  })}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {props.formDialog.divType === "view" ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <Button color="primary" onClick={() => sendToServer()}>
              {props.t("buttons.submit")}
            </Button>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);

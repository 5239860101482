import React, { useState, useEffect } from "react";
import OtpInput from "react18-input-otp";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Snackbar,
  Link,
  Paper,
  Box,
  Grid,
  Divider,
  Typography,
  Hidden,
  Alert,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import APIRequest from "../../utils/APIRequest";
import Toaster from "../../utils/Toaster";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import SlideInDialog from "../../templates/dialog/SlideInDialog";
import LocalStorage from "../../config/LocalStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    padding: "20px",
    // backgroundColor: "#fff",
    background:
      " linear-gradient(to bottom left, #F9B4B4 0%, white 50%, #F9B4B4 80%)",
    cursor: "default",
    display: "flex",
    justifyContent: "center",
  },
  imageGrid: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  titleDiv: {
    height: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  vectorDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "400px",
    width: "500px",
  },
  loginGrid: {
    width: "100%",
    height: "100%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    // width: "50%",
    width: "500px",
    height: "500px",
    margin: 4,
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "10px",
    boxShadow: " 0px 8px 4px #FB8B96",
    padding: "40px",
    textAlign: "center",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  avatar: {
    margin: 1,
    backgroundColor: "#8BC6EC",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  input: {
    margin: "10px",
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
  },
  forgotPassword: {
    display: "flex",
    justifyContent: "space-between",
    // margin: "10px 0",
  },
  submit: {
    backgroundColor: "#800000 !important",
    borderRadius: "4px !important",
    padding: "6px, 90px, 6px, 90px !important",
    marginTop: "20px !important",
    width: "247px !important",
    fontWeight: "700 !important",
  },
  textAlign: {
    textAlign: "end",
  },
}));

function Login(props) {
  const classes = useStyles();
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");

  const [disableOtpBtn, setDisableOtpBtn] = useState(false);
  const [slideInDialog, setSlideInDialog] = useState(false);

  const [otp, setOTP] = useState("");
  const [twoFactAut, setTowFactAuth] = useState(false);
  const [Login, setLogin] = useState(true);
  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const [error, setError] = useState(false);

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };
  useEffect(() => {
    isLogin();
  }, []);

  const [OTP, setOTPfield] = useState("");
  const [otpass, setOTPass] = useState("");

  const [email, setEmail] = useState("");
  const [passwordField, setNewPasswordField] = useState(false);
  const [passwordnew, setPasswordnew] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const GETOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getOTP,
      JSON.stringify({ email: email })
    ).then((response) => {
      console.log(response);
      if (response.data.responseCode === 109) {
        setOTPfield(true);
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP has been generated and send to your Email Id",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Email id doesnt exist",
        });
      }
    });
  };

  const verifyOTPReset = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.OTPverify,
      JSON.stringify({ otp: otpass })
    ).then((response) => {
      if (response.data.responseCode === 109) {
        setNewPasswordField(true);
        setOTPfield(false);
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP Verified",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Otp is not matching ",
        });
      }
    });
  };

  const confirmPassword = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.confirmPassword,
      JSON.stringify({ userPassword: passwordnew, email: email })
    ).then((response) => {
      console.log(response);
      if (response.data.responseCode === 109) {
        setLogin(true);
        // setResetPage(false);
        setNewPasswordField(false);
        setOTPfield(false);

        setEmail("");
        setPassword("");
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "Password successfully reset please login",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    });
  };

  const handlePasswordChange = () => {
    setLogin(false);
  };

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
  };

  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "GET",
        ConfigAPIURL.forgotPassword + "?username=" + mobileNo,
        ""
      ).then((response) => {
        setSlideInDialog(false);
        if (response.code === 100 && response.data.responseCode === 101) {
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "New password is generated and sent over the email",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 116
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Exceeded password attempt",
          });
        } else if (
          response.code === 100 &&
          response.data.responseCode === 115
        ) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User login email is not verified",
          });
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "User email id not found / wrong email id /deactivated",
          });
        }
      });
    } else {
      setSlideInDialog(false);
    }
  };

  const submitMobileLogin = () => {
    if (mobileNo === "") {
      return Toaster.error("Enter your Mobile Number", "topRight");
    }
    if (password === "") {
      return Toaster.error("Enter your Password", "topRight");
    }
    APIRequest.request(
      "POST",
      ConfigAPIURL.accountLogin,
      JSON.stringify({
        email: mobileNo,
        password: password,
      })
    ).then((response) => {
      //redirect based on user type
      if (
        response.data.responseCode === 108 ||
        response.data.responseCode === 103
      ) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Wrong Credentials",
        });
      } else if (response.data.responseCode === 116) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Exceeded Password attempt",
        });
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 105
      ) {
        isLogin();
      } else if (
        response.data.responseCode === 109 ||
        response.data.responseCode === 122
      ) {
        setSnakbarValues({
          status: true,
          severity: "success",
          message: "OTP sent to your mobile number",
        });
        // setTowFactAuth(true);
      } else if (response.data.responseCode === 104) {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Password is not matching, please check your password",
        });
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server Error, Please try after sometime",
        });
      }
    });
  };

  const verifyOTP = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.verifyOtp,
      JSON.stringify({
        otpVal: otp,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null &&
          response.data.responseCode === 109
        ) {
          isLogin();
        } else if (response.data.responseCode === 118) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Invalid OTP",
          });
        } else if (response.data.responseCode === 112) {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "You don't have any permission. Please contact admin",
          });
        }
      } else {
        setSnakbarValues({
          status: true,
          severity: "error",
          message: "Server Error, Please try after sometime",
        });
      }
    });
  };
  const isLogin = () => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        //redirect based on user type
        if (response !== undefined && response !== null) {
          if (response.data !== undefined && response.data !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (
                response.data.responseCode === 108 ||
                response.data.responseCode === 103
              ) {
                // setSnakbarValues({
                // 	status: true,
                // 	severity: 'error',
                // 	message: 'Wrong OTP',
                // });
              } else if (response.data.responseCode === 109) {
                let userDetails = {};
                if (
                  response.data.user !== null &&
                  response.data.user.permission !== undefined
                ) {
                  userDetails = {
                    email: response.data.user.email,
                    mobileNo: response.data.user.mobileNo,
                    name: response.data.user.name,
                    permission: response.data.user.permission,
                    profileImage: response.data.user.profileImage,
                    userName: response.data.user.userName,
                    userType: response.data.user.userType,
                    isSuperAdmin: response.data.user.isSuperAdmin,
                  };
                  LocalStorage.userDetails = userDetails;
                } else {
                  setSnakbarValues({
                    status: true,
                    severity: "error",
                    message: "User does not have permission to login",
                  });

                  logoutFunction();
                }

                if (
                  userDetails.userType !== undefined &&
                  userDetails.userType === "admin"
                ) {
                  if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "User"
                    )[0].enable
                  ) {
                    props.history.replace("/admin");
                  } else if (
                    userDetails.permission.permission.filter(
                      (e) => e.label === "CreateOrder"
                    )[0].enable
                  ) {
                    props.history.replace("/admin/create-order");
                  }

                  // } else if (userDetails.userType === 'user') {
                  //   props.history.replace('/user');
                } else {
                  props.history.replace("/login");
                }
              } else {
                setSnakbarValues({
                  status: true,
                  severity: "error",
                  message: "Server Error, Please try after sometime",
                });
              }
            } else {
              setSnakbarValues({
                status: true,
                severity: "error",
                message: "Server Error, Please try after sometime",
              });
            }
          } else {
            setSnakbarValues({
              status: true,
              severity: "error",
              message: "Server Error, Please try after sometime",
            });
          }
        } else {
          setSnakbarValues({
            status: true,
            severity: "error",
            message: "Server Error, Please try after sometime",
          });
        }
      }
    );
  };
  const _enableResendOTPButton = () => {
    setTimeout(function () {
      setDisableOtpBtn(false);
    }, 5000);
  };

  const logoutFunction = (props) => {
    APIRequest.request("GET", ConfigAPIURL.adminLogout, "").then((response) => {
      if (response.code === 100) {
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      }
    });
    sessionStorage.clear();
    localStorage.clear();
    // LocalStorage.permission = null;
  };

  const resendOTP = () => {
    setDisableOtpBtn(true);
    APIRequest.request("POST", ConfigAPIURL.resendOtp, "").then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null &&
          response.data.responseCode === 109
        ) {
          _enableResendOTPButton();

          setSnakbarValues({
            status: true,
            severity: "info",
            message: "Sucessfully sent OTP",
          });
        } else {
          setDisableOtpBtn(false);
          setSnakbarValues({
            status: true,
            severity: "info",
            message: "Please try after some time",
          });
        }
      } else {
        setDisableOtpBtn(false);
        setSnakbarValues({
          status: true,
          severity: "info",
          message: "Please try after some time",
        });
      }
    });
  };
  const handleMobileNoChange = (event) => {
    const value = event.target.value;
    setMobileNo(value);
    if (value.length > 10) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      {Login ? (
        <Grid item xl={8} lg={8} sm={8} xs={8} className={classes.loginGrid}>
          <div className={classes.paper}>
            <Typography
              style={{ fontSize: "28px", fontWeight: 600, color: "#444" }}
            >
              {/* Sign in to {LocalStorage.ProjectName} */}
              Admin Login
            </Typography>
            <Typography
              style={{ fontSize: "16px", fontWeight: 500, color: "#777" }}
            >
              Please provide registered mobile number with password to login
            </Typography>
            {!twoFactAut ? (
              <div className={classes.form}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={mobileNo}
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  // onChange={(event) => setMobileNo(event.target.value)}
                  onChange={handleMobileNoChange}
                  id="mobileNo"
                  autoComplete="off"
                  label="Mobile Number"
                  name="mobileNo"
                  error={error}
                />
                {error && (
                  <FormHelperText id="mobileNo-error">
                    Mobile Number should not exceed 10 digits.
                  </FormHelperText>
                )}
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  fullWidth
                  name="password"
                  autoComplete="off"
                  label="Password"
                  type="password"
                  id="password"
                />
                <div className={classes.forgotPassword}>
                  <Typography
                    style={{ color: "#555", fontSize: "15px" }}
                  ></Typography>
                  <Link
                    href="javascript:void(0)"
                    onClick={handlePasswordChange}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      style={{
                        color: "#800000",
                        fontSize: "15px",
                        fontWeight: "bold",
                        // fontSize: "14px",
                      }}
                    >
                      Forgot Password?
                    </Typography>
                  </Link>
                </div>

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => submitMobileLogin()}
                >
                  Log in
                </Button>
              </div>
            ) : (
              <div className={classes.form}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={otp}
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  onChange={(event) => setOTP(event.target.value)}
                  fullWidth
                  autoComplete="off"
                  name="otp"
                  label="OTP"
                  type="password"
                  id="otp"
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => verifyOTP()}
                >
                  Submit
                </Button>
                <Grid container>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    {!disableOtpBtn ? (
                      <Link
                        href="javascript:void(0)"
                        onClick={() => resendOTP()}
                        variant="body2"
                      >
                        Resend OTP
                      </Link>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}

            {/* <Box style={{ margin: "10px auto" }}>
            <Copyright />
          </Box> */}
          </div>
        </Grid>
      ) : (
        <div className={classes.paper} style={{ marginTop: "80px" }}>
          <Typography
            style={{
              fontSize: "28px",
              fontWeight: 600,
              color: "#444",
              textAlign: "center",
            }}
          >
            Reset Password
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: 500, color: "#777" }}
          >
            {OTP
              ? "Enter the OTP you received"
              : passwordField
              ? "Enter your new password"
              : "Please provide registered Email for password reset"}
          </Typography>

          <div className={classes.form}>
            <TextField
              className={classes.input}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              id="email"
              autoComplete="off"
              label="Email Id"
              name="email"
              style={{ display: passwordField === true ? "none" : "" }}
              disabled={OTP}
            />
            {OTP ? (
              <div style={{ display: passwordField === true ? "none" : "" }}>
                <div
                  style={{
                    marginBottom: "12px",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "16px",
                      display: passwordField === true ? "none" : "",
                    }}
                  >
                    Enter OTP
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "25px",
                    width: "100%",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <OtpInput
                    id="demo_input"
                    value={otpass}
                    onChange={setOTPass}
                    numInputs={6}
                    separator={<span style={{ color: "#666" }}> </span>}
                    containerStyle={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 10,
                      width: "95%",
                      maxWidth: "500px",
                      margin: "0 auto",
                    }}
                    inputStyle={{
                      flex: "1",
                      width: "30%",
                      height: "50px",
                      textAlign: "center",
                      border: "2px solid #E1E1E1",
                      backgroundColor: "#F0F5F9",
                      fontSize: "16px",
                      fontWeight: "600",
                      borderRadius: "10px",
                      outline: "none",
                      transition: "border-color 0.3s, background-color 0.3s",
                    }}
                    focusStyle={{
                      borderColor: "#3498db", // Change border color on focus
                      backgroundColor: "#ECF5FC", // Change background color on focus
                    }}
                    isInputNum={true}
                  />
                </div>

                <div
                  style={{
                    display: passwordField === true ? "none" : "",
                    textAlign: "center",
                    marginTop: "20px",
                    color: "black",
                  }}
                >
                  Enter 6 digit OTP received on your email
                </div>
              </div>
            ) : null}

            {passwordField ? (
              <>
                <TextField
                  id="outlined-adornment-password"
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  required
                  label="Enter New Password"
                  value={passwordnew}
                  onChange={(event) => setPasswordnew(event.target.value)}
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => confirmPassword()}
                  disabled={password === "" && password.length < 5}
                  style={{ color: "white" }}
                >
                  Confirm Password
                </Button>
              </>
            ) : null}

            {OTP ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => verifyOTPReset()}
                style={{
                  display: passwordField === true ? "none" : "",
                  margin: "100px",
                }}
              >
                Verify OTP
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => GETOTP()}
                style={{ display: passwordField === true ? "none" : "" }}
              >
                Reset Password
              </Button>
            )}
          </div>
        </div>
      )}
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
      <SlideInDialog
        title={"Need to reset your password?"}
        contentComponent={
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="justify">
              <Typography variant="body1">
                I would like to reset my password
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              align="justify"
              style={{ margin: "10px 0px" }}
            >
              <Divider />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="justify">
              <Typography variant="body1">
                By clicking “Agree” below you will get an email with a link to
                reset your password. After clicking the link to reset your
                password in that email you will get another email with system
                generated password.'
              </Typography>
            </Grid>
          </Grid>
        }
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
    </Grid>
  );
}

export default Login;

class ConfigAPIURL {
  //Base URL For Live & Staging
  static baseUrl =
    process.env.REACT_APP_PayDefination_BaseUrl ||
    process.env.REACT_APP_Dev_PayDefination_BaseUrl;
  // static baseUrl = "http://localhost:4000";
  // static baseUrl = "http://35.154.184.255:4001";
  // static baseUrl = "http://2ndsmart.com";
  // process.env.REACT_APP_PayDefination_BaseUrl || process.env.REACT_APP_Dev_PayDefination_BaseUrl;

  //Upload API

  //List of Pay Defination APIs and request method type
  static sessionValidation = this.baseUrl + "/api" + "/admin/islogin"; //get
  static accountLogin = this.baseUrl + "/api" + "/admin/accountLogin";
  static verifyOtp = this.baseUrl + "/api" + "/admin/verify/otp";
  static resendOtp = this.baseUrl + "/api" + "/admin/resend/otp";
  static adminLogout = this.baseUrl + "/api" + "/admin/logout";
  static adminGeneratePassword =
    this.baseUrl + "/api" + "/admin/password/generate";
  static forgotPassword = this.baseUrl + "/api" + "/admin/forgot/password";
  static resetPassword = this.baseUrl + "/api" + "/admin/reset/password";

  //Admin APIS
  static roleTableURL = this.baseUrl + "/api" + "/admin/roles/all"; //post
  static roleTitles = this.baseUrl + "/api" + "/admin/roles/titles"; //post
  static roleDetails = this.baseUrl + "/api" + "/admin/role/details"; //post
  static roleCreate = this.baseUrl + "/api" + "/admin/role/create"; //post
  static roleUpdate = this.baseUrl + "/api" + "/admin/role/permission/update"; //post
  static roleUnassigned =
    this.baseUrl + "/api" + "/admin/role/permission/unassigned"; //post
  static roleGrant = this.baseUrl + "/api" + "/admin/role/permission/grant"; //post
  static resetPasswordAttempt =
    this.baseUrl + "/api" + "/admin/user/resetpasswordattempt";
  static getRoleTitles = this.baseUrl + "/api" + "/admin/roles/titles";
  static deleteRole = this.baseUrl + "/api" + "/admin/roles/delete"; // delete

  // Survye
  static reportsQuery = this.baseUrl + "/api" + "/admin/survey/all";
  static getReport = this.baseUrl + "/api" + "/admin/survey/report";

  //reports
  static roleGrant = this.baseUrl + "/api" + "/admin/role/permission/grant"; //post
  static getReportsDetails = this.baseUrl + "/api" + "/admin/survey/details";
  static deleteReports = this.baseUrl + "/api" + "/admin/survey/report/delete";
  static updateReport = this.baseUrl + "/api" + "/admin/survey/report/update";

  //Shop APIs
  static getAllShopData = this.baseUrl + "/api" + "/admin/shop/all";
  static getShopDetails = this.baseUrl + "/api" + "/admin/shop/details";
  static addShop = this.baseUrl + "/api" + "/admin/shop/add";
  static updateShop = this.baseUrl + "/api" + "/admin/shop/update";
  static deleteShop = this.baseUrl + "/api" + "/admin/shop/delete";
  static shopFilters = this.baseUrl + "/api" + "/admin/shop/filteroptions";

  //Enquiry APIs
  static getAllEnquiry = this.baseUrl + "/api" + "/admin/enquiry/all";
  static updateEnquiry = this.baseUrl + "/api" + "/admin/enquiry/update";
  static getEnquiryDetails = this.baseUrl + "/api" + "/admin/enquiry/details";

  // Tax APIs
  static getAllTax = this.baseUrl + "/api" + "/admin/options"; // post
  static updateTaxList = this.baseUrl + "/api" + "/admin/tax/update";

  //User APIs
  static getAllUser = this.baseUrl + "/api" + "/admin/user/all";
  static getUserDetails = this.baseUrl + "/api" + "/admin/user/details";
  static addUser = this.baseUrl + "/api" + "/admin/user/add";
  static updateUser = this.baseUrl + "/api" + "/admin/user/update";
  static deleteUser = this.baseUrl + "/api" + "/admin/user/delete";
  static updateAddress = this.baseUrl + "/api" + "/admin/profile/update";

  //Inventory Maintenance
  static inventoryTable = this.baseUrl + "/api" + "/admin/inventory/all"; //post
  static inventoryUploadItems =
    this.baseUrl + "/api" + "/admin/inventory/upload/items"; //post
  static inventoryUploadStock =
    this.baseUrl + "/api" + "/admin/inventory/upload/stock";
  static getFromInventory = this.baseUrl + "/api" + "/admin/inventory/getitem"; //post
  static getAvailableCountOptions =
    this.baseUrl + "/api" + "/admin/inventory/availablecount"; //post
  static uploadInventoryImages =
    this.baseUrl + "/api" + "/admin/inventory/upload/image";
  static uploadInventoryVideos =
    this.baseUrl + "/api" + "/admin/inventory/upload/video";
  static deactivateInventory =
    this.baseUrl + "/api" + "/admin/inventory/deactivate";
  static deleteInventory = this.baseUrl + "/api/admin/inventory/delete";
  static inventoryProductAllDownload =
    this.baseUrl + "/api/admin/inventory/download/all"; // post
  static getInventoryDetail =
    this.baseUrl + "/api/admin/process/inventory/info"; // Get
  static editInventory = this.baseUrl + "/api/admin/inventory/update"; // post
  //Advertisement
  static getAllAdvertisement = this.baseUrl + "/api" + "/admin/Advertisements"; //post
  static inventoryProductList =
    this.baseUrl + "/api" + "/admin/Advertisement/search"; //get
  static updateAdvertisement =
    this.baseUrl + "/api" + "/admin/Advertisement/update";
  static getAdvertisementDetails =
    this.baseUrl + "/api" + "/admin/Advertisement/details";
  static addAdvertisement =
    this.baseUrl + "/api" + "/admin/Advertisement/create";
  static getCategoryForAd =
    this.baseUrl + "/api" + "/admin/Advertisement/category";
  static advertisementDelete =
    this.baseUrl + "/api" + "/admin/Advertisement/delete";

  static inventoryTable = this.baseUrl + "/api" + "/admin/inventory/all"; //post
  static inventoryUploadItems =
    this.baseUrl + "/api" + "/admin/inventory/upload/items"; //post
  static inventoryUploadStock =
    this.baseUrl + "/api" + "/admin/inventory/upload/stock";
  static getFromInventory = this.baseUrl + "/api" + "/admin/inventory/getitem"; //post
  static getAvailableCountOptions =
    this.baseUrl + "/api" + "/admin/inventory/availablecount"; //post
  static uploadInventoryImages =
    this.baseUrl + "/api" + "/admin/inventory/upload/image";
  static uploadInventoryVideos =
    this.baseUrl + "/api" + "/admin/inventory/upload/video";

  //Catgory APIs
  static getAllCategory = this.baseUrl + "/api" + "/admin/category/all";
  static getCategoryDetails = this.baseUrl + "/api" + "/admin/category/details";
  static addCategory = this.baseUrl + "/api" + "/admin/category/add";
  static updateCategory = this.baseUrl + "/api" + "/admin/category/update";
  static deleteCategory = this.baseUrl + "/api" + "/admin/category/delete";
  static category = this.baseUrl + "/api/admin/category";

  //Order APIs
  static getAllOrders = this.baseUrl + "/api" + "/admin/order/all";
  static getOrderDetails = this.baseUrl + "/api" + "/admin/order/details";
  static addOrder = this.baseUrl + "/api" + "/admin/order/add";
  static updateOrder = this.baseUrl + "/api" + "/admin/order/update";
  static deleteOrder = this.baseUrl + "/api" + "/admin/order/delete";

  //Dropdown APIs
  static getCountryList = this.baseUrl + "/api" + "/country/list";
  static getStateList = this.baseUrl + "/api" + "/state/list";
  static getCityList = this.baseUrl + "/api" + "/city/list";
  static getUserList = this.baseUrl + "/api" + "/admin/getUser";
  static getOptions = this.baseUrl + "/api" + "/admin/options";
  static getUserByMobileNo = this.baseUrl + "/api" + "/admin/user/mobileNo";
  static getInventoryItems = this.baseUrl + "/api" + "/admin/inventory/item";

  // Discount
  static discountURL = this.baseUrl + "/api" + "/admin/discount/all"; //post
  static deleteDiscount = this.baseUrl + "/api" + "/admin/discount/delete"; // delete
  static discountDetails = this.baseUrl + "/api" + "/admin/discount/info"; //post
  static createDiscount = this.baseUrl + "/api" + "/admin/discount/create"; //post
  static updateDiscount = this.baseUrl + "/api" + "/admin/discount/update"; //put
  static getUserForDiscount = this.baseUrl + "/api" + "/admin/discount/users"; // post
  static getAppliedUser = this.baseUrl + "/api" + "/admin/discount/appliedUser"; // post

  static getParentCategories = this.baseUrl + "/api" + "/admin/category"; // get

  static applyCoupon = this.baseUrl + "/api" + "/user/coupon/check"; //post

  static uploadHistory = this.baseUrl + "/api" + "/admin/list/all/upload/file"; // put

  // QC Types
  static qcTypesURL = this.baseUrl + "/api" + "/admin/qctypes/all"; //post
  static deleteQCTypes = this.baseUrl + "/api" + "/admin/qctypes/delete"; // delete
  static qcTypesDetails = this.baseUrl + "/api" + "/admin/qctypes/details"; //post
  static createQCTypes = this.baseUrl + "/api" + "/admin/qctypes/add"; //post
  static updateQCTypes = this.baseUrl + "/api" + "/admin/qctypes/update"; //put

  // Prices Related API
  static pricesURL = this.baseUrl + "/api" + "/admin/prices/all"; //post
  static deletePrices = this.baseUrl + "/api" + "/admin/prices/delete"; // delete
  static pricesDetails = this.baseUrl + "/api" + "/admin/prices/details"; //post
  static createPrices = this.baseUrl + "/api" + "/admin/prices/add"; //post
  static updatePrices = this.baseUrl + "/api" + "/admin/prices/update"; //put
  static priceUploadItems =
    this.baseUrl + "/api" + "/admin/prices/upload/items"; //post
  static getInventoryKeys =
    this.baseUrl + "/api" + "/admin/screen/inventoryKeys"; //get
  static getAllScreens = this.baseUrl + "/api" + "/admin/screen/all"; //post
  static deleteScreen = this.baseUrl + "/api" + "/admin/screen/all"; //post
  static screenDetail = this.baseUrl + "/api" + "/admin/screen/detail"; //post
  static createScreen = this.baseUrl + "/api" + "/admin/screen/add"; //post
  static updateScreen = this.baseUrl + "/api" + "/admin/screen/update"; //put

  // HCP Related api
  // Survey
  static myDraft = this.baseUrl + "/api" + "/hcp/survey/draft"; // get
  static surveyHistory = this.baseUrl + "/api" + "/hcp/survey/history"; // get
  static createSurvey = this.baseUrl + "/api" + "/hcp/survey/create"; // post
  static updateSurvey = this.baseUrl + "/api" + "/hcp/survey/update"; // put
  static surveyDetails = this.baseUrl + "/api" + "/hcp/survey/details"; // post
  static deleteDraft = this.baseUrl + "/api" + "/hcp/survey/deleteDraft"; // delete

  static uploadFile = this.baseUrl + "/api" + "/hcp/upload/file";

  // user

  static hcpSessionValidation = this.baseUrl + "/api" + "/hcp/islogin"; //get
  static hcpAccountLogin = this.baseUrl + "/api" + "/hcp/accountLogin";
  static hcpLogout = this.baseUrl + "/api" + "/hcp/logout";
  static hcpResetPassword = this.baseUrl + "/api" + "/hcp/password-reset"; // post

  //forgot password page
  static getOTP=this.baseUrl+"/api/hcp"+ "/password/email/otp" //POST
  static OTPverify=this.baseUrl+"/api/hcp"+ "/password/email/otp/verify" //POST
  static confirmPassword=this.baseUrl+"/api/hcp" +"/password/reset"  //POST
}

export default ConfigAPIURL;

import React, { useState } from "react";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { savePdf } from "../../../../node";
import Invoice from "./invoice/Invoice";
import { PDFViewer } from "@react-pdf/renderer";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 18,
    // fontFamily: "open sans",
    marginTop: 5,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    // fontFamily: "open sans",
    marginTop: 10,
    marginBottom: 5,
  },
}));

const SideModal = (props) => {
  const classes = useStyles();
  const [pdf, setPdf] = useState(null);

  const generatePdf = () => {
    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.text("LETTER of MEDICAL NECESSITY", 66, 10);

    doc.setFontSize(12);
    // health care
    doc.text("Information of the Healthcare Professional", 70, 25);
    doc.setFontSize(10);
    doc.text(
      `Title and name : Dr. ${hcpInfo.name} | ${hcpInfo.specialization} `,
      20,
      30
    );
    doc.text(`Address: ${hcpInfo.address ? hcpInfo.address : ""}`, 20, 45);
    doc.text(`Contact information: Telephone (W) ${hcpInfo.mobileNo}`, 20, 40);
    doc.text(`Email : ${hcpInfo.email}`, 20, 35);

    doc.setFontSize(14);

    doc.text("Patient Information :", 80, 55);
    //patient information
    doc.setFontSize(10);
    doc.text(`Title and Name: ${patientInfo.name}`, 20, 60);
    doc.text(`Address: ${patientInfo.address} `, 20, 65);
    doc.text(
      `Contact information: Telephone (H) ${patientInfo.mobileNo} | Telephone (W) : ${patientInfo.mobileNo2}`,
      20,
      70
    );
    doc.text(`Email: ${patientInfo.email}`, 20, 75);
    doc.text(`Medical Aid Name : ${patientInfo.email}`, 20, 80);
    doc.text(`Membership number : ${patientInfo.email}`, 20, 85);
    doc.text(`ID or passport number: ${patientInfo.email}`, 20, 90);

    doc.setFontSize(12);
    doc.text(
      "REQUEST: Authorization for treatment with ZYQUIS (apixaban)",
      50,
      100
    );
    doc.setFontSize(10);
    doc.text(`Diagnosis: ${authorization.diagnosis}`, 20, 105);
    doc.text(
      `Additional Diagnosis: ${authorization.additionalDiagnosis}`,
      20,
      110
    );
    doc.text(`ICD-10 Code: ${authorization.icd10Code}`, 20, 110);
    doc.text(
      `Dose and Request Type: ${authorization.dosage} | ${authorization.reqType}`,
      20,
      115
    );
    doc.text(
      `${authorization?.name ? "Dear " : ""}${authorization.name}${
        authorization?.writtenInfos
          ? " I am writing to request a formulary exception for the above mentioned patient"
          : ""
      }`,
      20,
      120
    );
    doc.text(
      `to receive treatment with ZYQUIS (apixaban) for the indication ${authorization?.writtenInfos}`,
      20,
      125
    );

    doc.setFontSize(12);
    doc.text("Patient Diagnosis", 10, 140);

    doc.setFontSize(10);
    doc.text("Diagnosis: " + patientDiagnosis.diagnosis, 20, 145);
    doc.text(
      "Date of Diagnosis: " +
        new Date(patientDiagnosis.diagnosisDate * 1000).toLocaleDateString(),
      20,
      150
    );
    doc.text(
      "Current condition of the patient: " +
        patientDiagnosis.patientCurrentCondition,
      20,
      155
    );

    doc.setFontSize(12);
    doc.text("Patient’s Lab Results", 10, 175);
    doc.setFontSize(10);
    doc.text(
      `
    INR             : ${patientLabResult.inr} | ${new Date(
        patientLabResult.inrDate * 1000
      ).toLocaleDateString()}
    PT TIME         : ${patientLabResult.ptTime} | ${new Date(
        patientLabResult.ptTimeDate * 1000
      ).toLocaleDateString()}
    D-DIMER         : ${patientLabResult.dDimer} | ${new Date(
        patientLabResult.dDimerDate * 1000
      ).toLocaleDateString()}
    CrCI            : ${patientLabResult.ccrl} | ${new Date(
        patientLabResult.ccrlDate * 1000
      ).toLocaleDateString()}
    ${patientLabResult.comment}
`,
      20,
      180
    );

    doc.setFontSize(12);
    doc.text("Patient’s Examination Results", 10, 210);
    doc.setFontSize(10);
    doc.text(
      `
Blood pressure : ${examResult.bloodPressure} | ${examResult.bloodPressureCheckbox}
Heart rate : ${examResult.heartRate} | ${examResult.heartRateCheckbox}
Weight : ${examResult.weight} | ${examResult.weightCheckbox}
Age : ${examResult.age} | ${examResult.ageCheckbox}
HbA1c : ${examResult.hba1c} | ${examResult.hba1cCheckbox}
Other : ${examResult.other} | ${examResult.otherCheckbox}
${examResult.comment}`,
      20,
      215
    );

    doc.addPage(); //add new page

    doc.setFontSize(12);
    doc.text("CHA2DS2-VASc Score for Atrial Fibrillation Stroke Risk", 10, 20);
    doc.setFontSize(10);
    doc.text(
      `
Age : ${atrialFibrillationData.ageCriteria} | ${atrialFibrillationData.age}
Sex : ${atrialFibrillationData.sexCriteria} | ${atrialFibrillationData.sex}
CHF History : ${atrialFibrillationData.chfHistoryCriteria} | ${atrialFibrillationData.chfHistory}
Hypertension history : ${atrialFibrillationData.hypertensionHistoryCriteria} | ${atrialFibrillationData.hypertensionHistory}
Thromboembolism history : ${atrialFibrillationData.thromboembolismHistoryCriteria} | ${atrialFibrillationData.thromboembolismHistory}
Vascular disease history (Prior MI, Peripheral artery disease, or aortic plaque) : ${atrialFibrillationData.vascularDiseaseHistoryCriteria} | ${atrialFibrillationData.vascularDiseaseHistory}
Diabetes history : ${atrialFibrillationData.diabeteshistoryCriteria} | ${atrialFibrillationData.diabeteshistory}
Total Risk: ${atrialFibrillationData.totalRisk} | ${atrialFibrillationData.riskType}
`,
      20,
      25
    );

    doc.setFontSize(12);
    doc.text("Other Medications for Stroke Risk", 10, 70);
    doc.setFontSize(10);
    medicationRows.forEach((item, index) => {
      const yCoord = 75 + index * 5;
      doc.text(
        `${item.medication} | ${item.dose} | ${item.condition}`,
        20,
        yCoord
      );
    });

    doc.setFontSize(12);
    doc.text("HAS-BLED Score for Major Bleeding Risk", 10, 100);
    doc.setFontSize(10);
    doc.text(
      `
Hypertension : ${hasBledScore.hypertension}
Renal disease : ${hasBledScore.renalDisease}
Liver disease : ${hasBledScore.liverDisease}
Stroke history : ${hasBledScore.strokeHistory}
Prior major bleeding or predisposition to bleeding : ${hasBledScore.priorMajorBleeding}
Labile INR : ${hasBledScore.LabileINR}
Age >65 : ${hasBledScore.age}
Medication usage predisposing to bleeding : ${hasBledScore.medicationUsage}
Alcohol use : ${hasBledScore.alcoholUse}
Total Risk : ${hasBledScore.totalRisk} | ${hasBledScore.riskType}
`,
      20,
      105
    );

    doc.setFontSize(12);
    doc.text("Other Medications for Bleeding Risk", 10, 160);
    doc.setFontSize(10);
    medicationBleedingRows.forEach((item, index) => {
      const y = 165 + index * 5;
      doc.text(`${item.medication} | ${item.dose} | ${item.condition}`, 20, y);
    });

    doc.setFontSize(12);
    doc.text("Patients Lifestyle", 10, 190);
    doc.setFontSize(10);
    doc.text(
      `
Patient is not able to get the regular INR testing required with the medication on your formulary : ${
        patientLifeStyle.notAbleToGetRegularINR ? "Yes" : "No"
      }
Patient lives alone and will need supervision / assistance with medication listed on your furmulary : ${
        patientLifeStyle.liveAlone ? "Yes" : "No"
      }
Patient has a history of falling / injury : ${
        patientLifeStyle.historyOfFalling ? "Yes" : "No"
      }
Patient does not have access to regular meals / dietary needs required for the medication listed on your formulary : ${
        patientLifeStyle.doesNotAccessToRegularMeals ? "Yes" : "No"
      }
Patient does not have access to regular meals / dietary needs required for the medication listed on your formulary : ${
        patientLifeStyle.patientOccupationSideEffect ? "Yes" : "No"
      }
Others: ${patientLifeStyle.others}
`,
      20,
      195
    );

    // doc.addPage();//add new page
    doc.setFontSize(12);
    doc.text("Child-Pugh Score for Cirrhosis Mortality", 10, 230);
    doc.setFontSize(10);
    doc.text(
      `
Bilirubin (Total) : ${childPughScore.bilirubin} | ${childPughScore.bilirubinScore}
Albumin : ${childPughScore.albumin} | ${childPughScore.albuminScore}
INR : ${childPughScore.inr} | ${childPughScore.inrScore}
Ascites : ${childPughScore.ascites} | ${childPughScore.ascitesScore}
Encephalopathy : ${childPughScore.encephalopathy} | ${childPughScore.encephalopathyScore}
Total Risk : ${childPughScore.totalRisk}
Cirrhosis severity : ${childPughScore.patientHasCirrhosis}
It is with all the above mentioned reasons, and my professional medical opinion that should my patient not 
receive ZYQUIS (apixaban), it would result in : ${childPughScore.resultIn}
ZYQUIS: ${childPughScore.ZYQUISmgBD}`,
      20,
      235
    );

    doc.setFontSize(12);
    doc.text(`HCP Name: ${childPughScore.hcpName}`, 140, 285);

    doc.save("Patient_Survey.pdf");
    setPdf(doc.output("blob"));
  };

  // const downloadPdf = () => {
  //   pdf.save("file.pdf");
  // };

  const {
    handleActiveStep,
    isOpen,
    dismissPanel,
    header,
    hcpInfo,
    patientInfo,
    authorization,
    patientLabResult,
    examResult,
    patientDiagnosis,
    atrialFibrillationData,
    hasBledScore,
    childPughScore,
    medicationRows,
    medicationBleedingRows,
    pdfFiles,
    consentientLetterPdf,
    scriptPdf,
    patientLifeStyle,
    setIsOpen,
  } = props;

  const closeModal = () => {
    setIsOpen(false);
  };
  console.log(props, "this is props data");

  return (
    <Panel
      headerText={header}
      isOpen={isOpen}
      onDismiss={closeModal}
      // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
    >
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Invoice data={{ ...props }} />
      </PDFViewer>
      {/* {"Hcp Info",authorization.diagnosis} */}
      {hcpInfo &&
      patientInfo &&
      authorization &&
      patientDiagnosis &&
      patientLabResult &&
      examResult &&
      atrialFibrillationData &&
      hasBledScore &&
      childPughScore &&
      medicationRows &&
      medicationBleedingRows &&
      // pdfFiles &&
      // consentientLetterPdf &&
      // scriptPdf &&
      patientLifeStyle ? (
        <>
          {/* <h6 className={classes.title}>
            Information of the Healthcare Professional
          </h6>
          <Typography className={classes.text}>
            Dr. {hcpInfo.name} | {hcpInfo.specialization} | P.No:{" "}
            {hcpInfo.practiceNo}
          </Typography>
          <Typography className={classes.text}>{hcpInfo.email}</Typography>
          <Typography className={classes.text}>{hcpInfo.mobileNo}</Typography>
          <Typography className={classes.text}>{hcpInfo.address}</Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(0);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>Patient Information</h6>

          <Typography className={classes.text}>
            {" "}
            Title and Name{"    "} : {patientInfo.name}{" "}
          </Typography>
          <Typography className={classes.text}>
            {" "}
            Email{"    "} : {patientInfo.email}
          </Typography>
          <Typography className={classes.text}>
            Tele Phone{"    "} : {patientInfo.mobileNo} |{" "}
            {patientInfo.mobileNo2}
          </Typography>
          <Typography className={classes.text}>
            {patientInfo.address ? "Patient Address :" : ""}{" "}
            {patientInfo.address}
          </Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(1);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>
            REQUEST: Authorization for treatment with ZYQUIS (apixaban)
          </h6>

          <Typography className={classes.text}>
            Diagnosis{"    "} : {authorization.diagnosis}{" "}
          </Typography>
          <Typography className={classes.text}>
            Additional Diagnosis{"    "} : {authorization.additionalDiagnosis}
          </Typography>
          <Typography className={classes.text}>
            ICD-10 Code{"    "} : {authorization.icd10Code}
          </Typography>

          <Typography className={classes.text}>
            Dose and Request Type{"    "} : {authorization.dosage} |{" "}
            {authorization.reqType}
          </Typography>
          <Typography className={classes.text}>
            {authorization.name ? "Dear " : ""}
            {authorization.name}
            {authorization.writtenInfos
              ? " I am writing to request a formulary exception for the above mentioned patient to receive treatment with ZYQUIS (apixaban) for the indication"
              : ""}{" "}
            {authorization.writtenInfos}
          </Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(2);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>Patient Diagnosis</h6>

          <Typography className={classes.text}>
            Diagnosis{"    "} : {patientDiagnosis.diagnosis}
          </Typography>
          <Typography className={classes.text}>
            Date of Diagnosis{"    "} :{" "}
            {new Date(
              patientDiagnosis.diagnosisDate * 1000
            ).toLocaleDateString()}
          </Typography>
          <Typography className={classes.text}>
            Current condition of the patient{"    "} :{" "}
            {patientDiagnosis.patientCurrentCondition}
          </Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(3);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>Patient’s Lab Results</h6>

          <Typography className={classes.text} style={{ whiteSpace: "pre" }}>
            INR{"             "} : {patientLabResult.inr} |{" "}
            {new Date(patientLabResult.inrDate * 1000).toLocaleDateString()}
          </Typography>
          <Typography className={classes.text} style={{ whiteSpace: "pre" }}>
            PT TIME{"     "} : {patientLabResult.ptTime} |{" "}
            {new Date(patientLabResult.ptTimeDate * 1000).toLocaleDateString()}
          </Typography>
          <Typography className={classes.text} style={{ whiteSpace: "pre" }}>
            D-DIMER{"    "} : {patientLabResult.dDimer} |{" "}
            {new Date(patientLabResult.dDimerDate * 1000).toLocaleDateString()}
          </Typography>
          <Typography className={classes.text} style={{ whiteSpace: "pre" }}>
            CrCI:{"           "} : {patientLabResult.ccrl} |{" "}
            {new Date(patientLabResult.ccrlDate * 1000).toLocaleDateString()}
          </Typography>
          <Typography className={classes.text}>
            {patientLabResult.comment}
          </Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(4);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>Patient’s Examination Results</h6>

          <Typography className={classes.text}>
            Blood pressure : {examResult.bloodPressure}
            {" | "}
            {examResult.bloodPressureCheckbox}
          </Typography>
          <Typography className={classes.text}>
            Heart rate : {examResult.heartRate}
            {" | "}
            {examResult.heartRateCheckbox}
          </Typography>

          <Typography className={classes.text}>
            Weight : {examResult.weight}
            {" | "}
            {examResult.weightCheckbox}
          </Typography>

          <Typography className={classes.text}>
            Age : {examResult.age}
            {" | "}
            {examResult.ageCheckbox}
          </Typography>

          <Typography className={classes.text}>
            HbA1c : {examResult.hba1c}
            {" | "}
            {examResult.hba1cCheckbox}
          </Typography>
          <Typography className={classes.text}>
            Other : {examResult.other}
            {" | "}
            {examResult.otherCheckbox}
          </Typography>

          <Typography className={classes.text}>{examResult.comment}</Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(5);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>
            CHA2DS2-VASc Score for Atrial Fibrillation Stroke Risk
          </h6>
          <Typography className={classes.text}>
            Age : {atrialFibrillationData.ageCriteria}
            {" | "}
            {atrialFibrillationData.age}
          </Typography>
          <Typography className={classes.text}>
            Sex : {atrialFibrillationData.sexCriteria}
            {" | "}
            {atrialFibrillationData.sex}
          </Typography>

          <Typography className={classes.text}>
            CHF History : {atrialFibrillationData.chfHistoryCriteria}
            {" | "}
            {atrialFibrillationData.chfHistory}
          </Typography>

          <Typography className={classes.text}>
            Hypertension history :{" "}
            {atrialFibrillationData.hypertensionHistoryCriteria}
            {" | "}
            {atrialFibrillationData.hypertensionHistory}
          </Typography>
          <Typography className={classes.text}>
            hypertensionHistory :{" "}
            {atrialFibrillationData.thromboembolismHistoryCriteria}
            {" | "}
            {atrialFibrillationData.thromboembolismHistory}
          </Typography>
          <Typography className={classes.text}>
            Vascular disease history (Prior MI, Peripheral artery disease, or
            aortic plaque) :{" "}
            {atrialFibrillationData.vascularDiseaseHistoryCriteria}
            {" | "}
            {atrialFibrillationData.vascularDiseaseHistory}
          </Typography>

          <Typography className={classes.text}>
            Diabetes history : {atrialFibrillationData.diabeteshistoryCriteria}
            {" | "}
            {atrialFibrillationData.diabeteshistory}
          </Typography>

          <Typography className={classes.text}>
            Total Risk: {atrialFibrillationData.totalRisk} |{" "}
            {atrialFibrillationData.riskType}
          </Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(6);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>Other Medications for Stroke Risk</h6>
          {medicationRows.map((item) => (
            <Typography className={classes.text}>
              {item.medication} | {item.dose} | {item.condition}
            </Typography>
          ))}  */}

          {/* <Button
            onClick={() => {
              handleActiveStep(7);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>
            HAS-BLED Score for Major Bleeding Risk
          </h6>
          <Typography className={classes.text}>
            Hypertension : {hasBledScore.hypertension}
          </Typography>

          <Typography className={classes.text}>
            Renal disease : {hasBledScore.renalDisease}
          </Typography>
          <Typography className={classes.text}>
            Liver disease : {hasBledScore.liverDisease}
          </Typography>
          <Typography className={classes.text}>
            Stroke history : {hasBledScore.strokeHistory}
          </Typography>
          <Typography className={classes.text}>
            Prior major bleeding or predisposition to bleeding :{" "}
            {hasBledScore.priorMajorBleeding}
          </Typography>
          <Typography className={classes.text}>
            Labile INR : {hasBledScore.LabileINR}
          </Typography>
          <Typography className={classes.text}>
            {"Age >65"} : {hasBledScore.age}
          </Typography>
          <Typography className={classes.text}>
            Medication usage predisposing to bleeding :{" "}
            {hasBledScore.medicationUsage}
          </Typography>
          <Typography className={classes.text}>
            Alcohol use : {hasBledScore.alcoholUse}
          </Typography>

          <Typography className={classes.text}>
            Total Risk : {hasBledScore.totalRisk} | {hasBledScore.riskType}
          </Typography> */}
          {/* <Button
            onClick={() => {
              handleActiveStep(8);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>Other Medications for Bleeding Risk</h6>
          {medicationBleedingRows.map((item) => (
            <Typography className={classes.text}>
              {item.medication} | {item.dose} | {item.condition}
            </Typography>
          ))} */}

          {/* <Button
            onClick={() => {
              handleActiveStep(9);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>Patients Lifestyle</h6>

          <Typography className={classes.text}>
            Patient is not able to get the regular INR testing required with the
            medication on your formulary :{" "}
            {patientLifeStyle.notAbleToGetRegularINR ? "Yes" : "No"}
          </Typography>
          <Typography className={classes.text}>
            Patient lives alone and will need supervision / assistance with
            medication listed on your furmulary :{" "}
            {patientLifeStyle.liveAlone ? "Yes" : "No"}
          </Typography>
          <Typography className={classes.text}>
            Patient has a history of falling / injury :{" "}
            {patientLifeStyle.historyOfFalling ? "Yes" : "No"}
          </Typography>

          <Typography className={classes.text}>
            Patient does not have access to regular meals / dietary needs
            required for the medication listed on your formulary :{" "}
            {patientLifeStyle.doesNotAccessToRegularMeals ? "Yes" : "No"}
          </Typography>
          <Typography className={classes.text}>
            Patient does not have access to regular meals / dietary needs
            required for the medication listed on your formulary :{" "}
            {patientLifeStyle.patientOccupationSideEffect ? "Yes" : "No"}
          </Typography>

          <Typography className={classes.text}>
            Others: {patientLifeStyle.others}
          </Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(10);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <h6 className={classes.title}>
            Child-Pugh Score for Cirrhosis Mortality
          </h6>
          <Typography className={classes.text}>
            Bilirubin (Total) : {childPughScore.bilirubin}
            {" | "}
            {childPughScore.bilirubinScore}
          </Typography>
          <Typography className={classes.text}>
            Albumin : {childPughScore.albumin}
            {" | "}
            {childPughScore.albuminScore}
          </Typography>
          <Typography className={classes.text}>
            INR : {childPughScore.inr}
            {" | "}
            {childPughScore.inrScore}
          </Typography>
          <Typography className={classes.text}>
            Ascites : {childPughScore.ascites}
            {" | "}
            {childPughScore.ascitesScore}
          </Typography>
          <Typography className={classes.text}>
            Encephalopathy : {childPughScore.encephalopathy}
            {" | "}
            {childPughScore.encephalopathyScore}
          </Typography>

          <Typography className={classes.text}>
            Total Risk : {childPughScore.totalRisk}
          </Typography>

          <Typography className={classes.text}>
            Cirrhosis severity : {childPughScore.patientHasCirrhosis}
          </Typography>
          <Typography className={classes.text}>
            It is with all the above mentioned reasons, and my professional
            medical opinion that should my patient not receive ZYQUIS
            (apixaban), it would result in : {childPughScore.resultIn}
          </Typography>

          <Typography className={classes.text}>
            ZYQUIS: {childPughScore.ZYQUISmgBD}
          </Typography>

          <Typography className={classes.text}>
            HCP Name: {childPughScore.hcpName}
          </Typography> */}

          {/* <Button
            onClick={() => {
              handleActiveStep(11);
              dismissPanel();
            }}
          >
            Edit
          </Button> */}

          {/* <Button
            onClick={() => {
              dismissPanel();
            }}
          >
            Close
          </Button> */}
          <Grid
            style={{
              marginRight: "40px",
              marginTop: "10px",
              textAlign: "center",
              alignItems: "center",
            }}
            sm={12}
          >
            {/* <Button
              //   onClick={props.handlePrev}
              onClick={() => {
                props.saveSurvey();
                // props.openPanel();
              }}
              sx={{ mr: 2 }}
              style={{
                backgroundColor: "#800000",
                borderRadius: "2px",
                width: "102px",
                height: "32px",
                color: "#fff",
                textAlign: "center",
                textTransform: "capitalize",
                Padding: "6px 28px",
                margin: "7px",
              }}
            >
              Submit
            </Button> */}
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#800000",
                  borderRadius: "2px",
                  height: "32px",
                  color: "#fff",
                  textAlign: "center",
                  textTransform: "capitalize",
                  Padding: "6px 28px",
                  margin: "7px",
                }}
                // onClick={generatePdf}
                onClick={() => {
                  savePdf(
                    <Invoice data={{ ...props }} isMentor={false} />,
                    "Doc-" + ".pdf"
                  );
                }}
              >
                Generate PDF
              </Button>
            </>
          </Grid>
        </>
      ) : (
        <div>Loading</div>
      )}
    </Panel>
  );
};

export default SideModal;

import {
  Callout,
  DefaultButton,
  DirectionalHint,
  Dropdown,
  Popup,
} from "@fluentui/react";
import React, { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, Radio, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";

const FilterOption = (props) => {
  const {
    showPopover,
    handleFromDateChange,
    handleToDateChange,
    handleSave,
    fromDate,
    toDate,
    handleReset,
  } = props;

  return (
    <div isOpen={showPopover} style={{ width: "220px" }}>
      <div
        style={{
          padding: "20px",
          marginTop: "0",
          paddingBottom: "15px",
          paddingTop: "0",
          position: "relative",
        }}
      >
        <div
        // style={{ position: "relative", marginBottom: "5px" }}
        >
          <h4>Start Date</h4>
          <div
            style={{
              //   zIndex: "9999",
              //   position: "absolute",
              backgroundColor: "white",
              boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="draftDate"
                inputFormat="dd/MM/yyyy"
                label={"Start Date"}
                // value={filterDate !== null ? new Date(filterDate * 1000) : null}
                maxDate={new Date()}
                value={fromDate}
                onChange={handleFromDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        {/* --------------- */}
        <div style={{ position: "relative", marginBottom: "5px" }}>
          <h4>End Date</h4>
          <div
            style={{
              //   zIndex: "9999",
              //   position: "absolute",
              backgroundColor: "white",
              boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
              //   top: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="draftDate"
                inputFormat="dd/MM/yyyy"
                label={"End Date"}
                // value={filterDate !== null ? new Date(filterDate * 1000) : null}
                maxDate={new Date()}
                minDate={fromDate}
                value={toDate}
                onChange={handleToDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  />
                )}
                PopoverProps={{
                  style: {
                    maxWidth: "350px",
                    maxHeight: "350px",
                    overflow: "auto",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "20px",
          paddingTop: "0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            marginRight: "10px",
            backgroundColor: "#800000",
            color: "#fff",
          }}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
};

export default FilterOption;

import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Hidden,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import Toaster from "../../../../utils/Toaster";

const Setting = (props) => {
  // const [oldPass, setOldPassword] = useState(null);
  // const [password, setNewPassword] = useState(null);
  const [match, setMatch] = useState(true);

  const [input, setInput] = useState({
    oldPass: "",
    password: "",
    confirmPassword: "",
  });

  const sendToServer = () => {
    if (input.password === input.oldPass) {
      Toaster.error(
        "New password should not be same as old password",
        "topRight"
      );

      return;
    }
    if (input.password !== input.confirmPassword) {
      Toaster.error("password not matching to new password", "topRight");

      return;
    }
    if (input.password == input.confirmPassword) {
      let fieldValidation = ["password", "oldPass"];
      FormValidation.validation(fieldValidation, input).then(
        (ValidationResult) => {
          if (ValidationResult === true) {
            APIRequest.request(
              "POST",
              ConfigAPIURL.hcpResetPassword,
              JSON.stringify({
                password: input.password,
                oldPassword: input.oldPass,
              })
            ).then((response) => {
              if (response !== undefined && response !== null) {
                if (response.data !== undefined && response.data !== null) {
                  if (
                    response.data.responseCode !== undefined &&
                    response.data.responseCode !== null
                  ) {
                    if (
                      response.data.responseCode === 108 ||
                      response.data.responseCode === 103
                    ) {
                      props.history.replace("/hcp/login");
                    } else if (response.data.responseCode === 105) {
                      Toaster.sucess(
                        "Password updated successfully",
                        "topRight"
                      );
                    } else if (response.data.responseCode === 104) {
                      Toaster.error("Password not match", "topRight");
                    }
                    setInput({
                      oldPass: "",
                      password: "",
                      confirmPassword: "",
                    });
                  }
                }
              }
            });
          }
        }
      );
    } else {
      setMatch(false);
    }
  };

  const Separator = () => {
    return <Typography color="inherit">&gt;</Typography>;
  };
  return (
    <div style={{ marginTop: "-35px" }}>
      <Paper
        style={{
          height: "100%",
          width: "95%",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 10px -3px",
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            flexWrap: "nowrap",
            height: "100%",
          }}
        >
          <Hidden only={["xs"]}>
            <Grid
              item
              xs={4}
              sx={{
                flexBasis: "30%",
                // borderRight: "0.5px solid #EDEBE9",
                height: "100%",
                margin: "10px",
                marginTop: "0",
              }}
            >
              <div style={{ color: "#323130" }}>
                <h1 style={{ color: "#323130" }}>Settings</h1>
                <h3 style={{ color: "#323130" }}>Change Password</h3>
              </div>
            </Grid>
          </Hidden>
          {/* <Divider orientation="vertical" flexItem sx={{ height: "100%" }} /> */}
          <Grid
            item
            xs={12}
            xl={8}
            lg={8}
            md={8}
            sx={{ flexBasis: "100%", margin: "30px" }}
          >
            <Hidden only={["xs"]}>
              <Breadcrumbs separator={<Separator />} aria-label="breadcrumb">
                {/* <Link color="inherit" href="/">
                Home
              </Link> */}
                <Typography color="inherit" href="/setting">
                  Settings
                </Typography>
                <Typography color="inherit">Change Password</Typography>
              </Breadcrumbs>
            </Hidden>

            <div style={{ marginTop: "32px" }}>
              <Typography variant="h6">Create New Password</Typography>
              <Typography>
                Your new password must be different from the previously used
                passwords.
              </Typography>
              <form style={{ marginTop: "32px" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      label="Previous Password"
                      id="oldPass"
                      type="password"
                      variant="outlined"
                      // required
                      fullWidth
                      size="small"
                      value={input.oldPass}
                      onChange={(e) =>
                        setInput({
                          ...input,
                          oldPass: e.target.value,
                        })
                      }
                      sx={{ width: "100%", height: "35px" }}
                      InputProps={{
                        style: {
                          boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                          borderRadius: "6px",
                          border: "none",
                          width: "90%",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="New Password"
                      type="password"
                      id="password"
                      variant="outlined"
                      // required
                      value={input.password}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          password: e.target.value,
                        });

                        if (!match) {
                          if (e.target.value == input.confirmPassword) {
                            setMatch(true);
                          }
                        }
                      }}
                      fullWidth
                      size="small"
                      sx={{ width: "100%", height: "35px" }}
                      InputProps={{
                        style: {
                          boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                          borderRadius: "6px",
                          border: "none",
                          width: "90%",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Confirm Password"
                      type="password"
                      id="confirmPassword"
                      variant="outlined"
                      // required
                      fullWidth
                      size="small"
                      value={input.confirmPassword}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          confirmPassword: e.target.value,
                        });

                        if (!match) {
                          if (e.target.value == input.password) {
                            setMatch(true);
                          }
                        }
                      }}
                      onBlur={() => {
                        if (input.password != input.confirmPassword) {
                          setMatch(false);
                        } else {
                          setMatch(true);
                        }
                      }}
                      sx={{ width: "100%", height: "35px" }}
                      InputProps={{
                        style: {
                          boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                          borderRadius: "6px",
                          border: "none",
                          width: "90%",
                        },
                      }}
                    />
                  </Grid>
                  {!match && (
                    <span
                      style={{ marginTop: 20, marginLeft: 35, color: "red" }}
                    >
                      Confirm Password not match
                    </span>
                  )}
                </Grid>
                <Grid container justifyContent="center">
                  <Button
                    variant="contained"
                    // color="primary"
                    style={{
                      marginTop: "30px",
                      width: "163px",
                      backgroundColor: "#800000",
                      textTransform: "capitalize",
                    }}
                    onClick={sendToServer}
                  >
                    Save Password
                  </Button>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Setting;

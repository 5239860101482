import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { AppContext } from "../AppState";
import { useEffect } from "react";
import FormValidation from "../../../../utils/FormValidation";

const HasBledScore = (props) => {
  const { hasBledScore, setHasBledScore,hasbledScorePage } = props;
  const validateHasBled = () => {
    let fieldValidation = [
      "hypertension",
      "renalDisease",
      "liverDisease",
      "strokeHistory",
      "priorMajorBleeding",
      "LabileINR",
      "age",
      "medicationUsage",
      "alcoholUse",
    ];
    FormValidation.validation(fieldValidation, hasBledScore).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };

  const handleHypertension = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      hypertension: newValue,
    });
  };
  const handleRenalDisease = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      renalDisease: newValue,
    });
  };
  const handleLiverDisease = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      liverDisease: newValue,
    });
  };

  const handleStrokeHistory = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      strokeHistory: newValue,
    });
  };
  const handlePriorMajorBleeding = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      priorMajorBleeding: newValue,
    });
  };
  const handleLabileINR = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      LabileINR: newValue,
    });
  };
  const handleAge = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      age: newValue,
    });
  };
  const handleMedicationUsage = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      medicationUsage: newValue,
    });
  };
  const handleAlcoholUse = (newValue) => {
    setHasBledScore({
      ...hasBledScore,
      alcoholUse: newValue,
    });
  };

  function calculateRiskScore(patientData) {
    let score = 0;
    const hypertension = patientData.hypertension;
    const renalDisease = patientData.renalDisease;
    const liverDisease = patientData.liverDisease;
    const strokeHistory = patientData.strokeHistory;
    const priorMajorBleeding = patientData.priorMajorBleeding;
    const LabileINR = patientData.LabileINR;
    const age = patientData.age;
    const medicationUsage = patientData.medicationUsage;
    const alcoholUse = patientData.alcoholUse;

    if (hypertension == 1) {
      score += 1;
    }
    // if (sex == 0) {
    //   score += 1;
    // }
    if (renalDisease == 1) {
      score += 1;
    }
    if (age == 1) {
      score += 1;
    }
    if (liverDisease == 1) {
      score += 1;
    }
    if (strokeHistory == 1) {
      score += 1;
    }
    if (priorMajorBleeding == 1) {
      score += 1;
    }
    if (LabileINR == 1) {
      score += 1;
    }
    if (medicationUsage == 1) {
      score += 1;
    }
    if (alcoholUse == 1) {
      score += 1;
    }

    return score;
  }
  const scores = calculateRiskScore(hasBledScore);
  useEffect(() => {
    setHasBledScore({
      ...hasBledScore,
      totalRisk: scores,
    });
  }, [scores]);

  const handleCheckboxChange = (event) => {
    const riskType = event.target.value;

    if (hasBledScore.riskType === riskType) {
      // if the clicked checkbox is already checked, uncheck it
      setHasBledScore({
        ...hasBledScore,
        riskType: null,
      });
    } else {
      // otherwise, check the clicked checkbox and uncheck the others
      setHasBledScore({
        ...hasBledScore,
        riskType: riskType,
      });
    }
  };

  const handleNext = () => {
    console.log(hasBledScore);
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                HAS-BLED Score for Major Bleeding Risk
              </h2>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  boxShadow:
                    " 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)",
                  padding: "15px",
                  paddingBottom: "30px",
                  width: "96%",
                  borderRadius: "8px",
                  paddingRight:'0',
                  paddingLeft:'0',
                  paddingTop:'0'
                  // margin: "10px",
                }}
              >
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    backgroundColor: "#FAFAFA",
                    // marginTop: "-13px",
                    // marginLeft: "-13px",
                  }}
                >
                  <Hidden only={["xs","md","sm"]}>

                  <Grid xl={8.8} lg={8.8} md={4} style={{ paddingLeft: "17px",fontWeight:'600' }}>
                    <p>Criteria</p>
                  </Grid>
                  </Hidden>
                  <Grid xl={1} lg={1} md={4}xs={5} style={{ paddingLeft: "14px",fontWeight:'600' }}>
                    <p>No</p>
                  </Grid>
                  <Grid xl={1} lg={1} md={4}xs={7} style={{ paddingLeft: "6px",fontWeight:'600' }}>
                    <p>Yes</p>
                  </Grid>
                  <Grid xl={1} lg={1} md={4} xs={3}style={{ paddingLeft: "20px",fontWeight:'600' }}>
                    <p>Score</p>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container >
                    <Grid item xs={12} style={{paddingLeft:'10px'}}>
                      <InputLabel
                        htmlFor="Hypertension"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontWeight:"600"
                        }}
                      >
                        Hypertension<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={10}xl={11} lg={11} md={11} style={{ marginRight: "8px" }}>
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12} xl={9.5} lg={9.5} md={9.5} >
                          <TextField
                            fullWidth
                            id="Hypertension"
                            value={"Uncontrolled, >160 mmHg systolic"}
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                                
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleHypertension(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.hypertension === 0 ? "#fff" : "#fff",
                              border: hasBledScore.hypertension === 0 ? "2px solid #800000" : "1px solid #8A8886",
                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2}xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleHypertension(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.hypertension === 1 ? "#fff" : "#fff",
                              border: hasBledScore.hypertension === 1 ? "2px solid #800000" : "1px solid #8A8886",
                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5}>
                      <TextField
                        id="hypertension"
                        value={hasBledScore.hypertension}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ------ */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container >
                    <Grid item xs={12}  style={{paddingLeft:'10px'}}>
                      <InputLabel
                        htmlFor="Renal-disease"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontWeight:"600"
                        }}
                      >
                        Renal disease<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={10} xl={11} lg={11} md={11}style={{ marginRight: "8px" }}>
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="Renal-disease"
                            
                            value={
                              "Dialysis, transplant, Cr >2.26 mg/dL or >200 μmol/L"
                            }
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleRenalDisease(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.renalDisease === 0 ? "#fff" : "#fff",
                              border: hasBledScore.renalDisease === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2}xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleRenalDisease(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.renalDisease === 1 ? "#fff" : "#fff",
                              border: hasBledScore.renalDisease === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5}>
                      <TextField
                        id="renalDisease"
                        value={hasBledScore.renalDisease}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container>
                    <Grid item xs={12}  style={{paddingLeft:'10px'}}>
                      <InputLabel
                        htmlFor="Liverdisease"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontWeight:"600"
                        }}
                      >
                        Liver disease<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={10} xl={11} lg={11} md={11} style={{ marginRight: "8px" }}>
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="Liverdisease"
                            value={
                              "Cirrhosis or bilirubin >2x normal with AST / ALT / AP >3x normal"
                            }
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleLiverDisease(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.liverDisease === 0 ? "#fff" : "#fff",
                              border: hasBledScore.liverDisease === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleLiverDisease(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.liverDisease === 1 ? "#fff" : "#fff",
                              border: hasBledScore.liverDisease === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5}>
                      <TextField
                        id="liverDisease"
                        value={hasBledScore.liverDisease}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                    {/* ---- */}
                    <Grid
                      item
                      xs={10} xl={11} lg={11} md={11}
                      style={{ marginRight: "8px", marginTop: "10px" }}
                    >
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="Liverdisease"
                            value={"Stroke history"}
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleStrokeHistory(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.strokeHistory === 0 ? "#fff" : "#fff",
                              border: hasBledScore.strokeHistory === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleStrokeHistory(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.strokeHistory === 1 ? "#fff" : "#fff",
                              border: hasBledScore.strokeHistory === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5} style={{marginTop: "10px" }}>
                      <TextField
                        id="strokeHistory"
                        value={hasBledScore.strokeHistory}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600",
                            fontFamily: "Segoe UI",
                            
                          },
                        }}
                      />
                    </Grid>
                    {/* ---- */}
                    <Grid
                      item
                      xs={10} xl={11} lg={11} md={11}
                      style={{ marginRight: "8px", marginTop: "10px" }}
                    >
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="Liverdisease"
                            value={
                              "Prior major bleeding or predisposition to bleeding"
                            }
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handlePriorMajorBleeding(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.priorMajorBleeding === 0 ? "#fff" : "#fff",
                              border: hasBledScore.priorMajorBleeding === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handlePriorMajorBleeding(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.priorMajorBleeding === 1 ? "#fff" : "#fff",
                              border: hasBledScore.priorMajorBleeding === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5} style={{marginTop: "10px" }}>
                      <TextField
                        id="priorMajorBleeding"
                        value={hasBledScore.priorMajorBleeding}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container >
                    <Grid item xs={12}  style={{paddingLeft:'10px'}}>
                      <InputLabel
                        htmlFor="LabileINRs"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontWeight:"600"
                        }}
                      >
                        Labile INR<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item  xs={10} xl={11} lg={11} md={11} style={{ marginRight: "8px" }}>
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="LabileINRs"
                            value={
                              "Unstable / high INRs, time in therapeutic range <60%"
                            }
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleLabileINR(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.LabileINR === 0 ? "#fff" : "#fff",
                              border: hasBledScore.LabileINR === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleLabileINR(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.LabileINR === 1 ? "#fff" : "#fff",
                              border: hasBledScore.LabileINR === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5}>
                      <TextField
                        id="LabileINR"
                        value={hasBledScore.LabileINR}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                    {/* ---- */}
                    <Grid
                      item
                      xs={10} xl={11} lg={11} md={11}
                      style={{ marginRight: "8px", marginTop: "10px" }}
                    >
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="LabileINR"
                            value={"Age >65"}
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAge(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.age === 0 ? "#fff" : "#fff",
                              border: hasBledScore.age === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAge(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.age === 1 ? "#fff" : "#fff",
                              border: hasBledScore.age === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5}style={{marginTop: "10px" }}>
                      <TextField
                        id="age"
                        value={hasBledScore.age}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container >
                    <Grid item xs={12}  style={{paddingLeft:'10px'}}>
                      <InputLabel
                        htmlFor="Medicationusage"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          overflowWrap: "break-word",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        fontWeight:"600"
                        }}
                      >
                        Medication usage predisposing to bleeding<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={10} xl={11} lg={11} md={11} style={{ marginRight: "8px" }}>
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="Medicationusage"
                            value={"Aspirin, clopidogrel, NSAIDs"}
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleMedicationUsage(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.medicationUsage === 0 ? "#fff" : "#fff",
                              border: hasBledScore.medicationUsage === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleMedicationUsage(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.medicationUsage === 1 ? "#fff" : "#fff",
                              border: hasBledScore.medicationUsage === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5}>
                      <TextField
                        id="medicationUsage"
                        value={hasBledScore.medicationUsage}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container >
                    <Grid item xs={12}  style={{paddingLeft:'10px'}}>
                      <InputLabel
                        htmlFor="Alcoholuse"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontWeight:"600"
                        }}
                      >
                        Alcohol use<span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={10} xl={11} lg={11} md={11}  style={{ marginRight: "8px" }}>
                      <Grid container spacing={2} justify="space-between"  style={{paddingLeft:'10px'}}>
                        <Grid item xs={12}xl={9.5} lg={9.5} md={9.5}>
                          <TextField
                            fullWidth
                            id="Alcoholuse"
                            value={"≥8 drinks / week"}
                            InputProps={{
                              style: {
                                height: "44px", // adjust this value as needed
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5} style={{ marginRight: "38px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAlcoholUse(0)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.alcoholUse === 0 ? "#fff" : "#fff",
                              border: hasBledScore.alcoholUse === 0 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"0"}
                          </Button>
                        </Grid>
                        <Grid item xs={2} xl={0.5} lg={0.5} md={0.5}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAlcoholUse(1)}
                            style={{
                              // backgroundColor: "#FAFAFA",
                              backgroundColor: hasBledScore.alcoholUse === 1 ? "#fff" : "#fff",
                              border: hasBledScore.alcoholUse === 1 ? "2px solid #800000" : "1px solid #8A8886",

                              color: "#000",
                              // border: "1px solid #8A8886",
                              width: "100%",
                              boxShadow: "none",
                              fontWeight:"600",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            {"1"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}xl={0.5} lg={0.5} md={0.5}>
                      <TextField
                        id="alcoholUse"
                        value={hasBledScore.alcoholUse}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            fontWeight:"600"
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ---- */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid
                    container
                    alignItems="center"
                    style={{ justifyContent: "right" }}
                  >
                    <Grid item xs={1}xl={3} lg={3} style={{ marginRight: "8px" }}></Grid>
                    <Grid
                      item
                      xs={8}
                      
                      style={{ textAlign: "right", marginRight: "12px" }}
                    >
                      <InputLabel
                        htmlFor="totalRisk"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        Total Bleeding Risk Score
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ textAlign: "right", marginRight: "12px",marginLeft:'0' }}
                    >
                      <TextField
                        id="totalRisk"
                        value={calculateRiskScore(hasBledScore)}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "50px",
                            textAlign: "center",
                            marginRight:'10px'
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ------ */}
              </Grid>

              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FFF3F3",
                  marginTop: "30px",
                  borderRadius: "8px",
                  width:'96%'
                }}
              >
                <p style={{ marginLeft: "18px" }}>
                  From the above risk assessment it is established that the
                  patient has<span style={{ color: "#D13438" }}>*</span>
                </p>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5, marginLeft: "18px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Low Risk"
                        checked={hasBledScore.riskType === "Low Risk"}
                        onChange={handleCheckboxChange}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="Low Risk"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="Moderate Risk"
                        checked={hasBledScore.riskType === "Moderate Risk"}
                        onChange={handleCheckboxChange}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="Moderate Risk"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="High Risk"
                        checked={hasBledScore.riskType === "High Risk"}
                        onChange={handleCheckboxChange}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="High Risk"
                  />
                </Grid>
              </Grid>

              {/* ------ */}
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FAFAFA",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ margin: "12px", lineHeight: 2.5 }}>
                From the bleeding risk assessment it is established that the patient has a
                  <input
                    id="patientHasCirrhosis"
                    variant="outlined"
                    className="segoe-input"
                    style={{
                      width: "20%",
                      height: "28px",
                      marginLeft: "4px",
                      marginRight: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                      borderRadius: "2px",
                      fontFamily:'Segoe UI'
                    }}
                    disabled
                    placeholder="LOW / MODERATE / HIGH risk"
                    value={hasBledScore.riskType}
                  />
risk for major bleeding. From the information in references listed below it is advised that ZYQUIS
(apixaban will have a lower risk of major bleeding events for the patient than the medication currently
listed on the formulary.<sup>4,5</sup> 

                </p>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
              <Hidden only={["xs"]}>
                    <Button
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "91px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={(e) => {
                        setHasBledScore(hasbledScorePage);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "121px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        margin: "7px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={props.saveSurveyAsDraft}
                    >
                      Save as Draft{" "}
                    </Button>
                  </Hidden>

                  <Hidden only={["lg", "sm", "xl", "md"]}>
                    <Button
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "40px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={(e) => {
                        setHasBledScore(hasbledScorePage);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#E8E8E8",
                        borderRadius: "2px",
                        width: "40px",
                        height: "32px",
                        color: "#323130",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "6px 28px 6px 28px",
                        margin: "7px",
                        fontFamily: "Segoe UI",
                      }}
                      onClick={props.saveSurveyAsDraft}
                    >
                      Draft{" "}
                    </Button>
                  </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
              <Hidden only={["xs"]}>
                <Button
                  onClick={props.handlePrev}
                  // sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    width: "96px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Previous
                </Button>
                <Button
                  onClick={validateHasBled}
                  // sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    width: "96px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    margin: "7px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Next
                </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                <Button
                  onClick={props.handlePrev}
                  // sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    width: "32px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    margin: "7px",
                  }}
                >
                  <ArrowBackIosNewIcon/>
                </Button>
                    <Button
                      onClick={validateHasBled}
                      // sx={{ mr: 2 }}
                      style={{
                        backgroundColor: "#800000",
                        borderRadius: "2px",
                        width: "32px",
                        height: "32px",
                        color: "#fff",
                        textAlign: "center",
                        textTransform: "capitalize",
                        Padding: "0",
                        margin: "7px",
                        // marginRight: "10px",
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                    
                  </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default HasBledScore;

import React from "react";
import ResponsiveDrawer from "../Drawers/ResponsiveDrawer";
import TopBar from "../Drawers/TopBar";

const Home = () => {
  return (
    <div>
      {/* <TopBar />
      <ResponsiveDrawer /> */}
      Home Page HCP
      {/* <div style={{ position: "relative", zIndex: 1 }}>
        <TopBar style={{ zIndex: 2 }} />
      </div>
      <div style={{ position: "relative", zIndex: 0 }}>
        <ResponsiveDrawer />
      </div> */}
    </div>
  );
};

export default Home;

import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login";
import PasswordGenaration from "./PasswordGenaration";

export default class Content extends Component {
	render() {
		return (
			<Switch>
				<Route exact path={["/"]} render={() => <Redirect to="/login" />} />
				<Route exact path={["/login"]} component={Login} />
				<Route exact path={["/user/password/generate"]} component={PasswordGenaration} />

				<Route render={() => <h3>Coming soon.</h3>} />
			</Switch>
		);
	}
}

import React, { useState, useEffect } from "react";
import ReactPDF from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";

const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";

  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};
export const savePdf = async (document, filename) => {
  console.log(pdf(document).toBlob());
  saveBlob(await pdf(document).toBlob(), filename);
};

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Paper, TextField, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "200px",
    maxWidth: "250px",
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formField: {
    "& .MuiInputLabel-shrink": {
      color: "black !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

function TableQuery(props) {
  const classes = useStyles();
  const [shopFilterOptions, setShopFilterOptions] = useState([]);

  useEffect(() => {
    getShopFilters();
  }, []);

  const getShopFilters = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.shopFilters,
      JSON.stringify({ active: true })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        console.log(response.data);
        if (response.code === 100 && response.data.responseCode === 109) {
          setShopFilterOptions(response.data.options);
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.activeStatus")}
              select
              fullWidth
              defaultValue={props.query.active}
              value={props.query.active}
              className={classes.formField}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t("query.active")}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t("query.inactive")}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.status")}
              select
              fullWidth
              defaultValue={props.query.status}
              value={props.query.status}
              className={classes.formField}
              onChange={(event) =>
                props.setQuery({ ...props.query, status: event.target.value })
              }
              style={{ marginBottom: 10 }}
            >
              <MenuItem key={0} value={""}>
                {props.t("query.all")}
              </MenuItem>
              <MenuItem key={1} value={"received"}>
                {props.t("query.approve")}
              </MenuItem>
              <MenuItem key={2} value={"reject"}>
                {props.t("query.reject")}
              </MenuItem>
              <MenuItem key={3} value={"pending"}>
                {props.t("query.pending")}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="startAt"
                inputFormat="dd/MM/yyyy"
                label={props.t("query.startDate")}
                value={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : null
                }
                // maxDate={
                //   props.query.endDate !== null
                //     ? new Date(props.query.endDate * 1000)
                //     : null
                // }
                maxDate={new Date()}
                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    startDate: Math.floor(new Date(date).getTime() / 1000.0),
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: "10px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="endAt"
                inputFormat="dd/MM/yyyy"
                label={props.t("query.endDate")}
                value={
                  props.query.endDate !== null
                    ? new Date(props.query.endDate * 1000)
                    : null
                }
                maxDate={new Date()}
                minDate={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : null
                }
                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    endDate: Math.floor(new Date(date).getTime() / 1000.0),
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);

import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  Font,
  View,
  StyleSheet,
  Image,
  Polygon,
  Svg,
  Rect,
  Path,
} from "@react-pdf/renderer";

import OpenSansBold from "./OpenSans-Bold.ttf";
import OpenSansRegular from "./OpenSans-Regular.ttf";
import styled from "@react-pdf/styled-components";
import { Typography } from "@mui/material";
import { styles } from "./stylesheet";
import "./invoice.scss";

// Create styles
Font.register({
  family: "Opensans",
  fonts: [
    { src: OpenSansRegular }, // font-style: normal, font-weight: normal
    { src: OpenSansBold, fontWeight: "bold" },
  ],
});
function replaceSpecialCharacters(text) {
  const replacements = {
    "&lt;": "<",
    "&gt;": ">",
  };

  return text ? text.replace(/&lt;|&gt;/g, (match) => replacements[match]) : "";
}

const CinosisComponent = ({ value }) => {
  return (
    <View style={{ flexDirection: "row", marginTop: 3, marginBottom: 3 }}>
      <View style={{ width: "25%" }}>
        <Text style={{ ...styles.tableRow, textAlign: "left" }}>
          {value?.key}
        </Text>
      </View>
      <View
        style={{
          width: "50%",
          flexDirection: "row",
          gap: 6,
          justifyContent: "flex-start",
          backgroundColor: "#e5e5e5",
        }}
      >
        <Text
          style={{
            ...styles.tableRow,
            paddingLeft: 10,
            // paddingRight: 6,
            textAlign: "left",
          }}
        >
          {replaceSpecialCharacters(value?.severity)}
          {/* {value?.severity} */}
        </Text>
        {/* <Text style={{ ...styles.tableRow, backgroundColor: "#e5e5e5" }}>
          {value?.status}
        </Text> */}
      </View>
      <View style={{ width: "25%" }}>
        <Input value={value?.value} />
      </View>
    </View>
  );
};
const Heading = ({ children, headerStyles, styles }) => {
  return (
    <>
      <View
        style={{
          marginTop: 9,
          marginBottom: 4,

          padding: 3,
          backgroundColor: "#e5e5e5",
          ...headerStyles,
        }}
      >
        <Text
          style={{
            textAlign: "center",
            fontSize: 14,
            ...styles,
            fontWeight: 600,
            fontFamily: "Opensans",
          }}
        >
          {children}
        </Text>
      </View>
    </>
  );
};

const Input = ({ heading, value, styles }) => {
  return (
    <>
      <View
        style={{
          padding: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          width: "100%",
          ...styles,
        }}
      >
        <Text
          style={{
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {heading}
        </Text>
        <view
          style={{
            flexGrow: 2,
            display: "flex",
            justifyContent: "center",
            border: "1px solid grey",
            backgroundColor: value ? "white" : "#F1F4FF",
            height: 17,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 12,
              paddingLeft: 7,
            }}
          >
            {value}
          </Text>
        </view>
      </View>
    </>
  );
};

const Grid = ({ children, width, styles }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
        ...styles,
      }}
    >
      {children?.map((value, i) => {
        return (
          <View
            style={{
              justifyContent: "center",
              width: width,
            }}
            key={i}
          >
            {value}
          </View>
        );
      })}
    </View>
  );
};

const Checkmark = ({ value }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        display: "block",
        width: "25px",
        height: "25px",
        border: "1px solid black",
      }}
    >
      {" "}
      {value ? (
        <Image src="https://static.vecteezy.com/system/resources/previews/006/059/254/original/black-check-mark-icon-tick-symbol-in-black-color-illustration-for-web-mobile-and-concept-design-free-vector.jpg" />
      ) : (
        <View
          style={{ backgroundColor: "#F1F4FF", width: "100%", height: "100%" }}
        ></View>
      )}
    </View>
  );
};
const ExampleSvg = () => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
    id="tick"
  >
    <Path d="M223.9 329.7c-2.4 2.4-5.8 4.4-8.8 4.4s-6.4-2.1-8.9-4.5l-56-56 17.8-17.8 47.2 47.2L340 177.3l17.5 18.1-133.6 134.3z"></Path>
  </Svg>
);

const TextArea = (value) => {
  return (
    <view
      style={{
        flexGrow: 2,
        display: "flex",
        justifyContent: "center",
        border: "1px solid grey",
        backgroundColor: value ? "white" : "#F1F4FF",
        height: 17,
      }}
    >
      <Text
        style={{
          textAlign: "center",
          fontSize: 12,
          paddingLeft: 7,
        }}
      >
        {value}
      </Text>
    </view>
  );
};

// Create Document Component
const Invoice = ({ data, isMentor }) => {
  const {
    hcpInfo,
    patientInfo,
    authorization,
    patientDiagnosis,
    patientLabResult,
    examResult,
    atrialFibrillationData,
    medicationRows,
    hasBledScore,
    medicationBleedingRows,
    childPughScore,
    patientLifeStyle,
    date,
  } = data;

  console.log("this is auth", examResult);

  const details = [
    {
      key: "Title and name",
      value: hcpInfo?.name,
    },
    {
      key: "Address",
      value: hcpInfo?.address,
    },
    {
      key: "Contact information: Telephone (W)",
      value: hcpInfo?.mobileNo,
    },
    {
      key: "Email",
      value: hcpInfo?.email,
    },
    {
      key: "practice Number",
      value: hcpInfo?.practiceNo,
    },
    {
      heading: true,
      value: "Patient Information",
    },
    {
      key: "Title and name",
      value: patientInfo?.name,
    },
    {
      key: "Address",
      value: patientInfo?.address,
    },

    {
      key: "Contact information: Telephone (H)",
      value: patientInfo?.mobileNo,
    },
    {
      key: "Contact information: Telephone (W)",
      value: patientInfo?.mobileNo2,
    },
    {
      key: "Email",
      value: patientInfo?.email,
    },
    {
      key: "Medical Aid Name",
      value: patientInfo?.medicalAidName,
    },
    {
      key: "Medical Aid Number",
      value: patientInfo?.membershipNumber,
    },
    {
      key: "ID or passport number",
      value: patientInfo?.idProofNumber,
    },
  ];

  const diagnosisDetails = [
    {
      heading: true,
      value: "Patient Diagnosis",
    },
    {
      key: "Diagnosis",
      value: patientDiagnosis?.diagnosis,
    },
    {
      key: "Diagnosis Date",
      value: new Date(
        patientDiagnosis?.diagnosisDate * 1000
      ).toLocaleDateString(),
    },
    {
      key: "Current condition of the patient",
      value: patientDiagnosis?.patientCurrentCondition,
    },
  ];

  const labResults = [
    {
      key: "INR",
      value1: patientLabResult?.inr,
      value2: new Date(patientLabResult?.inrDate * 1000).toLocaleDateString(),
    },
    {
      key: "PT TIME",
      value1: patientLabResult?.ptTime,
      value2: new Date(
        patientLabResult?.ptTimeDate * 1000
      ).toLocaleDateString(),
    },
    {
      key: "D-DIMER",
      value1: patientLabResult?.dDimer,
      value2: new Date(
        patientLabResult?.dDimerDate * 1000
      ).toLocaleDateString(),
    },
    {
      key: "CrCl",
      value1: patientLabResult?.ccrl,
      value2: new Date(patientLabResult?.ccrlDate * 1000).toLocaleDateString(),
    },
    // {
    //   key: "CrCle",
    //   value1: patientLabResult?.ccrl,
    //   value2: new Date(patientLabResult?.ccrlDate * 1000).toLocaleDateString(),
    // },
    // {
    //   key: "other",
    //   value1: patientLabResult?.other,
    //   value2: new Date(patientLabResult?.other * 1000).toLocaleDateString(),
    // },
    // {
    //   key: "others",
    //   value1: patientLabResult?.other,
    //   value2: new Date(patientLabResult?.otherDate * 1000).toLocaleDateString(),
    // },
  ];

  const examResultData = [
    {
      key: "Age",
      key2: atrialFibrillationData?.ageCriteria,
      value: atrialFibrillationData?.age,
    },
    {
      key: "Sex",
      key2: atrialFibrillationData?.sexCriteria,
      value: atrialFibrillationData?.sex,
    },
    {
      key: "CHF History",
      key2: atrialFibrillationData?.chfHistoryCriteria,
      value: atrialFibrillationData?.chfHistory,
    },
    {
      key: "Hypertension history",
      key2: atrialFibrillationData?.hypertensionHistoryCriteria,
      value: atrialFibrillationData?.hypertensionHistory,
    },
    {
      key: "Stroke / TIA / thromboembolism history",
      key2: atrialFibrillationData?.thromboembolismHistoryCriteria,
      value: atrialFibrillationData?.thromboembolismHistory,
    },
    {
      key: "Vascular disease history (Prior MI,Peripheral artery disease, or aortic plaque)",
      key2: atrialFibrillationData?.vascularDiseaseHistoryCriteria,
      value: atrialFibrillationData?.vascularDiseaseHistory,
    },
    {
      key: "Diabetes history",
      key2: atrialFibrillationData?.diabeteshistoryCriteria,
      value: atrialFibrillationData?.diabeteshistory,
    },
    {
      key: "Total Risk",
      key2: "",
      value: atrialFibrillationData?.totalRisk,
    },
  ];

  const hasBledScoreData = [
    {
      heading: "Hypertension",
    },
    {
      key: "Uncontrolled, >160 mmHg systolic",
      value: hasBledScore?.hypertension,
    },
    {
      heading: "Renal disease",
    },
    {
      key: "Dialysis, transplant, Cr >2.26 mg/dL or >200 μmol/L",
      value: hasBledScore?.renalDisease,
    },
    {
      heading: "Liver disease",
    },
    {
      key: "Cirrhosis or bilirubin >2x normal with AST / ALT / AP >3x normal",
      value: hasBledScore?.liverDisease,
    },

    {
      key: "Stroke history",
      value: hasBledScore?.strokeHistory,
    },
    {
      key: "Prior major bleeding or predisposition to bleeding",
      value: hasBledScore?.priorMajorBleeding,
    },
    {
      heading: "Labile INR",
    },
    {
      key: "Unstable / high INRs, time in therapeutic range <60%",
      value: hasBledScore?.labileINR,
    },
    {
      key: " Age >65 ",
      value: hasBledScore?.age,
    },
    {
      heading: "Medication usage predisposing to bleeding",
    },
    {
      key: "Aspirin, clopidogrel, NSAIDs",
      value: hasBledScore?.medicationUsage,
    },
    {
      heading: "Alcohol use",
    },
    {
      key: "≥8 drinks / week",
      value: hasBledScore?.alcoholUse,
    },
    {
      key: "Total Bleeding Risk Score",
      value: hasBledScore?.totalRisk,
    },
  ];

  const childPughScoreData = [
    {
      key: "Bilirubin (Total)",
      severity: childPughScore?.bilirubin,
      status: 1,
      value: childPughScore?.bilirubinScore,
    },
    {
      key: "Albumin",
      severity: childPughScore?.albumin,
      status: 1,
      value: childPughScore?.albuminScore,
    },
    {
      key: "INR",
      severity: childPughScore?.inr,
      status: 1,
      value: childPughScore?.inrScore,
    },
    {
      key: "Ascites",
      severity: childPughScore?.ascites,
      status: 1,
      value: childPughScore?.ascitesScore,
    },
    {
      key: "Encephalopathy",
      severity: childPughScore?.encephalopathy,
      status: 1,
      value: childPughScore?.encephalopathyScore,
    },
    {
      key: "Total Risk Score",
      severity: "",
      status: 1,
      value: childPughScore?.totalRisk,
    },
  ];

  const patientLifeStyleData = [
    {
      key: "Patient is not able to get the regular INR testing required with the medication on your formulary",
      value: patientLifeStyle?.notAbleToGetRegularINR,
    },
    {
      key: "Patient lives alone and will need supervision / assistance with medication listed on your furmulary",
      value: patientLifeStyle?.liveAlone,
    },
    {
      key: "Patient has a history of falling / injury",
      value: patientLifeStyle?.historyOfFalling,
    },
    {
      key: "Patient does not have access to regular meals / dietary needs required for the medication listed on your formulary",
      value: patientLifeStyle?.doesNotAccessToRegularMeals,
    },
    {
      key: `Patient’s occupation: some of the side effects of the medication listed on your formulary would put my patient at risk of injury and / or result in a loss of income for my patient`,
      value: patientLifeStyle?.patientOccupationSideEffect,
    },
  ];

  const examResultDetails = [
    {
      key: "Blood pressure",
      value: examResult?.bloodPressure,
      checked: examResult?.bloodPressureCheckbox,
    },
    {
      key: "Heart rate",
      value: examResult?.heartRate,
      checked: examResult?.heartRateCheckbox,
    },
    {
      key: "Weight",
      value: examResult?.weight,
      checked: examResult?.weightCheckbox,
    },
    {
      key: "Age",
      value: examResult?.age,
      checked: examResult?.ageCheckbox,
    },
    {
      key: "HbA1c",
      value: examResult?.hba1c,
      checked: examResult?.hba1cCheckbox,
    },
    {
      key: "Other",
      value: examResult?.other,
      checked: examResult?.otherCheckbox,
    },
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottomColor: "#000",
            borderBottomWidth: 1,
            backgroundColor: "black",
            padding: "8px 0",
          }}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 40,
            }}
          >
            <Text
              style={{
                // width: 120,
                fontFamily: "Opensans",

                resizeMode: "contain",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {"LETTER of MEDICAL NECESSITY"}
            </Text>
          </View>
          <View style={{ justifyContent: "center", paddingRight: 30 }}>
            <Text
              style={{
                fontSize: 10,
                // padding: 4,
                color: "white",
                fontFamily: "Opensans",
                fontWeight: "bold",
              }}
            >
              {"Date of Request : "}
              {date ? date : ""}
            </Text>
          </View>
        </View>
        <Heading>Information of the Healthcare Professional</Heading>
        <View style={{}}>
          {details?.map((detail, index) => (
            <React.Fragment key={index}>
              {detail?.heading === true ? (
                <Heading>{detail?.value}</Heading>
              ) : (
                <Input heading={detail.key} value={detail.value} />
              )}
            </React.Fragment>
          ))}
          {/* health care is done */}
          <Heading>
            REQUEST: Authorization for treatment with ZYQUIS (apixaban)
          </Heading>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "80%" }}>
              <Input value={authorization?.diagnosis} heading="Diagnosis" />
            </View>
            <Input value={authorization?.icd10Code} heading="ICD-10 Code" />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 6,
            }}
          >
            <View>
              <Text style={{ ...styles.tableRow, fontSize: 12 }}>
                Dose and frequency:
              </Text>
            </View>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 5 }}
            >
              <Text style={{ ...styles.tableRow, fontSize: 12 }}>5 mg BD</Text>{" "}
              <Checkmark
                value={authorization?.dosage !== "2.5mg BD" ? true : false}
              />
            </View>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 5 }}
            >
              <Text style={{ ...styles.tableRow, fontSize: 12 }}>
                2.5 mg BD
              </Text>{" "}
              <Checkmark
                value={authorization?.dosage === "2.5mg BD" ? true : false}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 6,
            }}
          >
            <View>
              <Text style={{ ...styles.tableRow, fontSize: 12 }}>
                Request type:
              </Text>
            </View>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 5 }}
            >
              <Text style={{ ...styles.tableRow, fontSize: 12 }}>Standard</Text>{" "}
              <Checkmark
                value={authorization?.reqType === "Standard" ? true : false}
              />
            </View>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 5 }}
            >
              <Text style={{ ...styles.tableRow, fontSize: 12 }}>
                Expedited
              </Text>{" "}
              <Checkmark
                value={authorization?.reqType !== "Standard" ? true : false}
              />
            </View>
          </View>

          <Text style={{ ...styles.tableRow, fontSize: 12, textAlign: "left" }}>
            {authorization?.name && "Dear"} {authorization?.name} I am writing
            to request a formulary exception for the above mentioned patient to
            receive treatment with ZYQUIS (apixaban) for the indication{" "}
            {authorization?.writtenInfos}.
          </Text>
          <Text
            style={{
              ...styles.tableRow,
              fontSize: 10,
              textAlign: "left",
              fontWeight: 600,
            }}
          >
            My request is supported by the following:
          </Text>

          {/* patient diagnosis */}
          <View break />
          {diagnosisDetails?.map((detail, index) => (
            <React.Fragment key={index}>
              {detail?.heading === true ? (
                <Heading>{detail?.value}</Heading>
              ) : (
                <Input heading={detail.key} value={detail.value} />
              )}
            </React.Fragment>
          ))}
          <View></View>
          <Heading>Patient’s Lab Results </Heading>
          <View className={styles.gridContainer}>
            <Grid width="33%" styles={{ backgroundColor: "#e5e5e5" }}>
              {[
                <Text
                  style={{
                    ...styles.tableText,
                    textAlign: "left",
                  }}
                  className={styles.gridContainer}
                >
                  TEST
                </Text>,
                <Text
                  style={{
                    ...styles.tableText,
                  }}
                  className={styles.tableText}
                >
                  RESULT
                </Text>,
                <Text
                  style={{
                    ...styles.tableText,
                  }}
                >
                  DATE
                </Text>,
              ]}
            </Grid>
            {labResults &&
              labResults?.length > 0 &&
              labResults?.map((value, ind) => {
                return (
                  <React.Fragment key={value?.key}>
                    <Grid width="33%">
                      {[
                        <Text
                          style={{ ...styles.tableRow, textAlign: "left" }}
                          className={styles.tableRow}
                        >
                          {value?.key}
                        </Text>,
                        <Input value={value?.value1} />,
                        <Input value={value?.value2} />,

                        // <TextArea value={value?.value2} />,
                      ]}
                    </Grid>
                  </React.Fragment>
                );
              })}
            <View>
              <View style={{ display: "flex" }}>
                <View
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ ...styles.tableRow, textAlign: "left" }}>
                    {"Other"}
                  </Text>
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Input value={patientLabResult?.other} />
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Input
                    value={new Date(
                      patientLabResult?.otherDate * 1000
                    ).toLocaleDateString()}
                  />
                </View>
              </View>
            </View>
            <Text
              style={{
                backgroundColor: "#e5e5e5",
                padding: 3,
                ...styles.tableText,
                textAlign: "left",
              }}
            >
              COMMENTS
            </Text>
            <Input
              styles={{ padding: 0, gap: 0, marginTop: 6 }}
              value={patientLabResult?.writtenInfos}
            />
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#e5e5e5",
                justifyContent: "space-around",
                marginTop: 3,
              }}
            >
              <Heading styles={{ flexGrow: 2 }}>
                Patient’s Examination Results
              </Heading>
              <Heading>Risk</Heading>
            </View>
            {examResultDetails?.map((value, i) => {
              return (
                <React.Fragment key={i}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 3,
                    }}
                  >
                    <View style={{ width: "70%s" }}>
                      <Input value={value?.value} heading={value?.key} />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 3,
                      }}
                    >
                      <Text style={{ ...styles.tableRow, fontSize: 12 }}>
                        Yes
                      </Text>
                      <Checkmark
                        value={value?.checked?.toLowerCase() === "yes"}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 3,
                      }}
                    >
                      <Text style={{ ...styles.tableRow, fontSize: 12 }}>
                        No
                      </Text>
                      <Checkmark
                        value={value?.checked?.toLowerCase() === "no"}
                      />
                    </View>
                  </View>
                </React.Fragment>
              );
            })}
            <Text
              style={{
                backgroundColor: "#e5e5e5",
                padding: 3,
                ...styles.tableText,
                textAlign: "left",
              }}
            >
              COMMENTS
            </Text>
            <Input
              styles={{ padding: 0, gap: 0, marginTop: 6 }}
              value={examResult?.comments}
            />
            <View break></View>
            <Heading>
              CHA2DS2-VASc Score for Atrial Fibrillation Stroke Risk
            </Heading>
            <Grid width="33%" styles={{ backgroundColor: "#e5e5e5" }}>
              {[
                <Text></Text>,
                <Text style={{ ...styles.tableText }}>Criteria</Text>,
                <Text style={{ ...styles.tableText }}>Score</Text>,
              ]}
            </Grid>
            {examResultData?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid width="33%">
                    {[
                      <Text style={{ fontSize: 12, fontWeight: 400 }}>
                        {value?.key}
                      </Text>,
                      <Text
                        style={{
                          ...styles.tableText,
                          backgroundColor: "#e5e5e5",
                          fontWeight: 400,
                        }}
                      >
                        {value?.key2}
                      </Text>,
                      <Input value={value?.value} />,
                    ]}
                  </Grid>
                </React.Fragment>
              );
            })}
            <View style={{ ...styles.border, marginTop: 10 }}>
              <Text
                style={{ ...styles.tableRow, fontSize: 12, textAlign: "left" }}
              >
                From the above risk assessment it is established that the
                patient has{" "}
                <Text
                  style={{ ...styles.tableRow, fontWeight: 600, fontSize: 12 }}
                >
                  {atrialFibrillationData?.riskType}{" "}
                </Text>{" "}
                risk for stroke. From the information in references listed below
                it is advised that ZYQUIS (apixaban) will have better results
                for stroke prevention for my patient than the medication
                currently listed on the formulary.1,2,3
              </Text>
            </View>
            <View style={{ ...styles.border, marginTop: 10 }}>
              <Text
                style={{ ...styles.tableRow, fontSize: 12, textAlign: "left" }}
              >
                The patient has / is being treated on other medications (listed
                below) for other conditions (listed below). These drugs may
                reduce the efficacy of the drug covered on your formulary, and
                may result in inadequate treatment and / or stroke in the
                patient.
              </Text>
            </View>
            {/* <View break></View> */}
            <Grid
              width="33%"
              styles={{ backgroundColor: "#e5e5e5", marginTop: 10 }}
            >
              {[
                <Text style={{ ...styles.tableText }}>Medication</Text>,
                <Text style={{ ...styles.tableText }}>Dose</Text>,
                <Text style={{ ...styles.tableText }}>Condition</Text>,
              ]}
            </Grid>
            {medicationRows?.length > 0 &&
              medicationRows?.map((medication, ind) => {
                return (
                  <React.Fragment key={ind}>
                    <Grid width="33%">
                      {[
                        <Input value={medication?.medication} />,
                        <Input value={medication?.dose} />,
                        <Input value={medication?.condition} />,
                      ]}
                    </Grid>
                  </React.Fragment>
                );
              })}
            <Heading>HAS-BLED Score for Major Bleeding Risk</Heading>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              Estimated risk of major bleeding for patients on anticoagulation
              to assess risk-benefit in atrial fibrillation care
            </Text>
            <View
              style={{
                backgroundColor: "#e5e5e5",
                marginTop: 2,
                width: "100%",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <View style={{ width: "70%" }}>
                <Text
                  style={{
                    ...styles.tableText,
                    fontWeight: 400,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "left",
                  }}
                >
                  Criteria{" "}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "center",
                  width: "15%",
                }}
              >
                <Text style={{ ...styles.tableText }}>No</Text>
                <Text style={{ ...styles.tableText }}>+Yes</Text>
              </View>
              <View style={{ width: "15%" }}>
                <Text style={{ ...styles.tableText }}>Score</Text>
              </View>
            </View>
            {hasBledScoreData?.map((value, ind) => {
              return (
                <React.Fragment key={ind}>
                  {value?.heading ? (
                    <Text style={{ ...styles.tableText, textAlign: "left" }}>
                      {value?.heading}
                    </Text>
                  ) : (
                    <View
                      style={{
                        marginTop: 2,
                        width: "100%",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <View
                        style={{ width: "70%", backgroundColor: "#e5e5e5" }}
                      >
                        <Text
                          style={{
                            ...styles.tableText,
                            fontWeight: 400,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "left",
                          }}
                        >
                          {value?.key}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          gap: 2,
                          justifyContent: "center",
                          width: "15%",
                        }}
                      >
                        <View
                          style={{ backgroundColor: "#e5e5e5", width: "100%" }}
                        >
                          <Text
                            style={{
                              ...styles.tableText,
                            }}
                          >
                            0
                          </Text>
                        </View>
                        <View
                          style={{ backgroundColor: "#e5e5e5", width: "100%" }}
                        >
                          <Text
                            style={{
                              ...styles.tableText,
                              // backgroundColor: "#e5e5e5",
                            }}
                          >
                            +1
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{ width: "15%", backgroundColor: "#e5e5e5" }}
                      >
                        <Text style={{ ...styles.tableText }}>
                          {value?.value}
                        </Text>
                      </View>
                    </View>
                  )}
                </React.Fragment>
              );
            })}
            <View style={{ ...styles.border, marginTop: 10 }}>
              <Text
                style={{ ...styles.tableRow, fontSize: 12, textAlign: "left" }}
              >
                From the bleeding risk assessment it is established that the
                patient has a{" "}
                <Text
                  style={{ ...styles.tableRow, fontWeight: 600, fontSize: 12 }}
                >
                  {hasBledScore?.riskType}
                </Text>{" "}
                for major bleeding. From the information in references listed
                below it is advised that ZYQUIS (apixaban will have a lower risk
                of major bleeding events for the patient than the medication
                currently listed on the formulary.4,5
              </Text>
            </View>
            <View style={{ ...styles.border, marginTop: 10 }}>
              <Text
                style={{ ...styles.tableRow, fontSize: 12, textAlign: "left" }}
              >
                The patient has / is being treated on other medications (listed
                below) for other conditions (listed below). These drugs may
                increase the concentration / action of the drug covered on your
                formulary, and may result in an increased risk of major bleeding
                in the patient
              </Text>
            </View>
            <Grid
              width="33%"
              styles={{ backgroundColor: "#e5e5e5", marginTop: 10 }}
            >
              {[
                <Text style={{ ...styles.tableText }}>Medication</Text>,
                <Text style={{ ...styles.tableText }}>Dose</Text>,
                <Text style={{ ...styles.tableText }}>Condition</Text>,
              ]}
            </Grid>
            {medicationBleedingRows?.length > 0 &&
              medicationBleedingRows?.map((medication, ind) => {
                return (
                  <React.Fragment key={ind}>
                    <Grid width="33%">
                      {[
                        <Input value={medication?.medication} />,
                        <Input value={medication?.dose} />,
                        <Input value={medication?.condition} />,
                      ]}
                    </Grid>
                  </React.Fragment>
                );
              })}
            <Heading
              styles={{ fontSize: 14, textAlign: "left", fontWeight: 600 }}
            >
              The patient’s lifestyle could affect adherence with the medication
              covered by the formulary and should therefore take ZYQUIS
              (apixaban):6
            </Heading>
            {patientLifeStyleData?.map((value, key) => {
              const ind = key + 1;
              return (
                <View
                  key={key}
                  style={{
                    flexDirection: "row",
                    gap: 3,
                    justifyContent: "space-between",
                    marginTop: 8,
                  }}
                >
                  <View style={{ width: "87%" }}>
                    <Text
                      style={{
                        ...styles.tableRow,
                        textAlign: "left",
                        fontSize: 12,
                      }}
                    >
                      {ind}. {value?.key}
                    </Text>
                  </View>
                  <Checkmark value={value?.value} />
                </View>
              );
            })}
            <View
              style={{
                flexDirection: "row",
                gap: 3,
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <View style={{ width: "87%", marginLeft: 2 }}>
                <Input heading="6. Other" value={patientLifeStyle?.others} />
              </View>
            </View>
            <Heading>Child-Pugh Score for Cirrhosis Mortality</Heading>
            <Heading styles={{ fontSize: 12, marginTop: 3 }}>
              Estimates cirrhosis severity
            </Heading>
            {childPughScoreData?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <CinosisComponent value={value} />
                </React.Fragment>
              );
            })}
            <Grid width="33%" styles={{ gap: 2, backgroundColor: "#e5e5e5" }}>
              {[
                <View style={{ backgroundColor: "#e5e5e5" }}></View>,
                <Text style={{ ...styles.tableText }}>Classification</Text>,
                <View style={{ backgroundColor: "#e5e5e5" }}></View>,
              ]}
            </Grid>
            <Grid
              width="33%"
              styles={{ marginTop: 10, gap: 2, backgroundColor: "#e5e5e5" }}
            >
              {[
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>A</Text>,
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>
                  Mild
                </Text>,
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>
                  5-6
                </Text>,
              ]}
            </Grid>
            <Grid
              width="33%"
              styles={{ marginTop: 4, gap: 2, backgroundColor: "#e5e5e5" }}
            >
              {[
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>B</Text>,
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>
                  Moderate
                </Text>,
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>
                  7-9
                </Text>,
              ]}
            </Grid>{" "}
            <Grid
              width="33%"
              styles={{ marginTop: 4, gap: 2, backgroundColor: "#e5e5e5" }}
            >
              {[
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>C</Text>,
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>
                  Severe
                </Text>,
                <Text style={{ ...styles.tableText, fontWeight: 400 }}>
                  10-15
                </Text>,
              ]}
            </Grid>
            <View break style={{ ...styles.border, marginTop: 10 }}>
              <Text
                style={{
                  ...styles.tableRow,
                  fontSize: 12,
                  textAlign: "left",
                  padding: 5,
                  marginTop: 10,
                }}
              >
                From the above assessment it is determined that the patient has{" "}
                {/* {<Input value={} />} */}
                Cirrhosis severity. Liver disease could result in an increased
                risk of bleeding for my patient. From studies listed in the
                references below it is established that ZYQUIS (apixaban) has a
                low risk of bleeding and can safely be used up to Category B
                Cirrhosis (Refer to Package insert referenced).1,2,6
              </Text>
            </View>
            <Text
              break
              style={{
                ...styles.tableRow,
                fontSize: 12,
                textAlign: "left",
                padding: 5,
                marginTop: 40,
              }}
            >{`It is with all the above mentioned reasons, and my professional medical opinion that should my patient not receive ZYQUIS (apixaban), it would result in : ${childPughScore?.resultIn}ZYQUIS: ${childPughScore?.ZYQUISmgBD}
            Please approve ZYQUIS mg BD to be covered as chronic or Prescribed Minimum Benefit (PMB)on the patient’s treatment plan.
            `}</Text>
            <Text
              style={{
                ...styles.tableRow,
                fontSize: 12,
                textAlign: "left",
                padding: 5,
                marginTop: 20,
              }}
            >
              Kind regards (Name of HCP) :{childPughScore?.hcpName}
            </Text>
            <Text
              style={{
                ...styles.tableRow,
                fontSize: 10,
                textAlign: "left",
                marginTop: 30,
              }}
            ></Text>
            <Text
              style={{ ...styles.tableRow, fontSize: 8, textAlign: "left" }}
            >
              Please send completed motivation, with all blood tests, Patient
              consent form and completed script to: Zyquis@multilinkhs.co.za
            </Text>
            <Text
              style={{
                ...styles.tableRow,
                fontSize: 8,
                textAlign: "justify",
                marginTop: 8,
              }}
            >
              References:{"\n"} 1. 2020 ESC Guidlines for the diagnosis and
              management of atrial fibrillation developed in collaboration with
              the European Association for Cardio-Thoracic Surgery (EACTS).
              {"\n"}
              2.Registered Professional information of Zyquis 5mg and 2.5mg as
              approved by SAHPRA (South African Products Regulatory Authority) 9
              February 2021.
              {"\n"}
              3. Apixaban versus Warfarin in Patients with Atrial Fibrillation.
              List of authors. Christopher B. Granger, M.D., John H. Alexander,
              M.D., M.H.S., John J.V. McMurray, M.D., Renato D. Lopes,
              M.D.,Ph.D., Elaine M. Hylek, M.D., M.P.H., Michael Hanna, M.D.,
              Hussein R. Al-khalidi, Ph.D., Jack Ansell, M.D., Dan Atar, M.D.,
              Alvaro Avezum, M.D., Ph.D., M. Cecilia Bahit, M.D., Rafael Diaz,
              M.D., et al., for the ARISTOTLE Committees and Investigators,
              September 15, 2011 N Engl J Med 2011; 365:981-992 DOI:
              10.1056/NEJM oa 1107039.
              {"\n"}
              4. Samuelson Bannow B. Management of heavy menstrual bleeding on
              anticoagulation. Hematology Am Soc Hematol Educ Program. 2020 Dec
              4;2020(1):533-537 doi:10.1182/hematology. 202020000138. PMID:
              33275699; PMCID:PMC7727540.
              {"\n"}
              5. Vinogradova Y, Coupland C, Hill T, Hippisley-Cox J. Risks and
              benefits of direct oral anticoagulants versus warfarin in a real
              world setting: cohorts study in primary care BMJ 2018; 362:K2505
              DOI:10.1136/BMJ.K2505.
              {"\n"}
              6. Elhosseiny S, AI Moussawi H, Chalhoub JM, Lafferty J, Deep L.
              Direct Oral Antcoagulants in Cirrhotic Patients: Current Evidence
              and Clinical Observations. Can J Gastroenterol Hepatol. 2019 Jan
              8;2019:4383269.doi:10.1155/2019/4383269.PMID:30792971; PMCID:
              PMC6354142.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;

import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { AppContext } from "../AppState";
import { useEffect } from "react";

const options = [
  "Patient is not able to get the regular INR testing required with the medication on your formulary",
  "Patient lives alone and will need supervision / assistance with medication listed on your furmulary",
  "Patient has a history of falling / injury",
  "Patient does not have access to regular meals / dietary needs required for the medication listed on your formulary",
  "Patient’s occupation: some of the side effects of the medication listed on your formulary would put my patient at risk of injury and / or result in a loss of income for my patient",
  // add more options here
];

const PatientLifestyle = (props) => {
  const { patientLifeStyle, setPatientLifeStyle,patientLifestyleDefaultValue } = props;

  return (
    <div>
      <Grid container>
        <Grid>
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                Patients Lifestyle
              </h2>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  boxShadow:
                    " 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)",
                  padding: "15px",
                  paddingBottom: "30px",
                  paddingLeft:'0',
                  paddingRight:'0',
                  paddingTop:'0',
                  width: "96%",
                  borderRadius: "8px",
                  // margin: "10px",
                }}
              >
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    backgroundColor: "#FAFAFA",
                    // marginTop: "-15px",
                    alignItems: "center",
                    paddingLeft:'6px'
                  }}
                >
                  <p
                    style={{
                      paddingLeft: "8px",
                      fontWeight: "600",
                      color: "#323130",
                      padding:'4px',
                      paddingLeft:'8px'
                    }}
                  >
                    The patient’s lifestyle could affect adherence with the
                    medication covered by the formulary and should therefore
                    take ZYQUIS (apixaban):<span style={{ color: "#D13438" }}>*</span>
                  </p>
                </Grid>

                {/* {options.map((option, index) => ( */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={1}
                  style={{
                    padding: 5,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={10} style={{paddingLeft:'8px'}}>
                    <p>
                      1. Patient is not able to get the regular INR testing
                      required with the medication on your formulary
                    </p>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      style={{
                        color: "#800000",
                        transform: "scale(1.8)",
                        border: "0.5px solid #fff",
                        padding: "0",
                      }}
                      disableRipple
                      onChange={(event) =>
                        // handleCheckboxChange(event, option)

                        setPatientLifeStyle({
                          ...patientLifeStyle,
                          notAbleToGetRegularINR:
                            !patientLifeStyle.notAbleToGetRegularINR,
                        })
                      }
                      checked={patientLifeStyle.notAbleToGetRegularINR}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={1}
                  style={{
                    padding: 5,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={10} style={{paddingLeft:'8px'}}>
                    <p>
                      2. Patient lives alone and will need supervision /
                      assistance with medication listed on your furmulary
                    </p>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      style={{
                        color: "#800000",
                        transform: "scale(1.8)",
                        border: "0.5px solid #fff",
                        padding: "0",
                      }}
                      disableRipple
                      onChange={(event) =>
                        setPatientLifeStyle({
                          ...patientLifeStyle,
                          liveAlone: !patientLifeStyle.liveAlone,
                        })
                      }
                      checked={patientLifeStyle.liveAlone}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={1}
                  style={{
                    padding: 5,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={10} style={{paddingLeft:'8px'}}>
                    <p>3. Patient has a history of falling / injury</p>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      style={{
                        color: "#800000",
                        transform: "scale(1.8)",
                        border: "0.5px solid #fff",
                        padding: "0",
                      }}
                      disableRipple
                      onChange={(event) =>
                        setPatientLifeStyle({
                          ...patientLifeStyle,
                          historyOfFalling: !patientLifeStyle.historyOfFalling,
                        })
                      }
                      checked={patientLifeStyle.historyOfFalling}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={1}
                  style={{
                    padding: 5,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={10} style={{paddingLeft:'8px'}}>
                    <p>
                      4. Patient does not have access to regular meals / dietary
                      needs required for the medication listed on your formulary
                    </p>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      style={{
                        color: "#800000",
                        transform: "scale(1.8)",
                        border: "0.5px solid #fff",
                        padding: "0",
                      }}
                      disableRipple
                      onChange={(event) =>
                        setPatientLifeStyle({
                          ...patientLifeStyle,
                          doesNotAccessToRegularMeals:
                            !patientLifeStyle.doesNotAccessToRegularMeals,
                        })
                      }
                      checked={patientLifeStyle.doesNotAccessToRegularMeals}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={1}
                  style={{
                    padding: 5,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={10} style={{paddingLeft:'8px'}}>
                    <p>
                      5. Patient’s occupation: some of the side effects of the
                      medication listed on your formulary would put my patient
                      at risk of injury and / or result in a loss of income for
                      my patient
                    </p>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      style={{
                        color: "#800000",
                        transform: "scale(1.8)",
                        border: "0.5px solid #fff",
                        padding: "0",
                      }}
                      disableRipple
                      onChange={(event) =>
                        setPatientLifeStyle({
                          ...patientLifeStyle,
                          patientOccupationSideEffect:
                            !patientLifeStyle.patientOccupationSideEffect,
                        })
                      }
                      checked={patientLifeStyle.patientOccupationSideEffect}
                    />
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={1}
                  style={{
                    padding: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} style={{paddingLeft:'8px'}}>
                    <p>6. Others</p>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} xs={12} style={{padding:'8px',paddingTop:'0'}}>
                    <p>
                      <TextField
                        fullWidth
                        value={patientLifeStyle.others}
                        onChange={(event) =>
                          setPatientLifeStyle({
                            ...patientLifeStyle,
                            others: event.target.value,
                          })
                        }
                        InputProps={{
                          style: {
                            backgroundColor: "#FAFAFA",
                            height: "94px", // adjust this value as needed
                            borderRadius: "2px",
                          },
                        }}
                      />
                    </p>
                  </Grid>
                </Grid>
                <p style={{fontSize:'14px',paddingLeft:'8px'}}>Please refer to reference 6 for motivation of the above decision</p>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setPatientLifeStyle(patientLifestyleDefaultValue);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setPatientLifeStyle(patientLifestyleDefaultValue);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={props.handleNext}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={props.handleNext}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientLifestyle;

import React, { Component } from "react";
import Content from "./Content";
export default class General extends Component {
	render() {
		return (
			<div>
				<Content />
			</div>
		);
	}
}

class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        visibility:- Use to toggle column visibility.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static tableHeader = [
    {
      id: "reportId",
      accessor: "reportId",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "report.reportId",
      className: "",
      functionName: "",
    },
    {
      id: "hcpName",
      accessor: "hcpName",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "report.hcpName",
      className: "",
      functionName: "",
    },
    // {
    //   id: "fname",
    //   accessor: "userId.fname",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "user.fname",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "lname",
    //   accessor: "userId.lname",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "user.lname",
    //   className: "",
    //   functionName: "",
    // },

    {
      id: "hcpMobileNo",
      accessor: "hcpMobileNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 100,
      label: "report.hcpMobileNo",
      className: "",
      functionName: "",
    },
    {
      id: "patientName",
      accessor: "patientName",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "report.patientName",
      className: "",
      functionName: "",
    },
    {
      id: "patientMobileNo",
      accessor: "patientMobileNo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 100,
      label: "report.patientMobileNo",
      className: "",
      functionName: "",
    },
    // {
    //   id: "shopId.title",
    //   accessor: "shopId.title",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "report.name",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "itemCount",
    //   accessor: "itemCount",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: false,
    //   width: 50,
    //   label: "report.itemCount",
    //   className: "",
    //   functionName: "",
    // },

    // {
    //   id: "payment",
    //   accessor: "payment",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "report.payment",
    //   className: "",
    //   functionName: "",
    // },

    // {
    //   id: "recievedPayment",
    //   accessor: "recievedPayment",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: false,
    //   width: 50,
    //   label: "report.recievedPayment",
    //   className: "",
    //   functionName: "",
    // },

    {
      id: "reportStatus",
      accessor: "status",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 100,
      label: "report.reportStatus",
      className: "",
      functionName: "",
    },
    // {
    //   id: "viewReport",
    //   accessor: "viewReport",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 100,
    //   label: "report.viewReport",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "downloadReport",
    //   accessor: "downloadReport",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 100,
    //   label: "report.downloadReport",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "paymentMode",
    //   accessor: "paymentMode",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "report.paymentMode",
    //   className: "",
    //   functionName: "",
    // },

    // {
    //   id: "deliveryOtp",
    //   accessor: "deliveryOtp",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "report.deliveryOtp",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "invoiceId",
    //   accessor: "Invoice Id",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 50,
    //   label: "report.invoiceId",
    //   className: "",
    //   functionName: "",
    // },

    {
      id: "createdAt",
      accessor: "createdAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 60,
      label: "user.createdAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "updatedAt",
      accessor: "updatedAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 60,
      label: "user.updatedAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
  ];
}
export default TableHeader;

import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Paper, TextField, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formField: {
    "& .MuiInputLabel-shrink": {
      color: "black !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.activeStatus")}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              className={classes.formField}
              style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t("role.active")}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t("role.inactive")}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);

import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";

import { AppContext } from "../AppState";
import { useEffect } from "react";
// import { DatePicker, defaultDatePickerStrings } from "@fluentui/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { makeStyles } from "@mui/styles";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { style } from "@mui/system";
import FormValidation from "../../../../utils/FormValidation";

const useStyles = makeStyles((theme) => ({
  saveDraftButton: {
    // backgroundColor: "#E8E8E8",
    // borderRadius: "2px",
    // width: "121px",
    // height: "32px",
    // color: "black",/
    // [theme.breakpoints.down("sm")]: {
    //   width: "30px",
    // },
    // [theme.breakpoints.up("xs")]: {
    //   width: "30px",
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: "35%",
    // },
  },
  
}));


const LabResult = (props) => {
  const classes = useStyles();
  const { patientLabResult, setPatientLabResult,patientLabResultDefaultValue } = props;
  const validatelabresult = () => {
    let fieldValidation = [
      "inr",
      "ptTime",
      "dDimer",
      "ccrl",
      // "mobileNo2",
      // "medicalAidName",
      // "membershipNumber",
      // "idProofNumber",
      // "address",
    ];
    FormValidation.validation(fieldValidation, patientLabResult).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };

 

  const handleInrDateChange = (date) => {
    setPatientLabResult({
      ...patientLabResult,
      inrDate: date,
    });
  };

  const handlePtTimeDateChange = (date) => {
    setPatientLabResult({
      ...patientLabResult,
      ptTimeDate: date,
    });
  };

  const handledDimerDateChange = (date) => {
    setPatientLabResult({
      ...patientLabResult,
      dDimerDate: date,
    });
  };

  const handleccrlDateChange = (date) => {
    setPatientLabResult({
      ...patientLabResult,
      ccrlDate: date,
    });
  };

  const handleotherDateChange = (date) => {
    setPatientLabResult({
      ...patientLabResult,
      otherDate: date,
    });
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                Patient’s Lab Results
              </h2>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  boxShadow:
                    " 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)",
                  padding: "15px",
                  paddingTop:'0',
                  paddingLeft:'0',
                  paddingRight:'0',
                  paddingBottom: "30px",
                  width: "96%",
                  borderRadius: "8px",
                  // margin: "10px",
                }}
              >
                <Hidden only={["xs"]}>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      backgroundColor: "#FAFAFA",
                      margin: "0",
                      width:'140%'
                    }}
                  >
                    <Grid xl={3} lg={3} md={4} sm={5} style={{ paddingLeft: "18px",fontWeight:'600',fontSize:'16px' }}>
                      <p>Test</p>
                    </Grid>
                    <Grid xl={5} lg={5} md={4} sm={4}style={{ paddingLeft: "3px",fontWeight:'600' }}>
                      <p>Result</p>
                    </Grid>
                    <Grid xl={3} lg={3} md={4} style={{ paddingLeft: "75px" ,fontWeight:'600'}}>
                      <p>Date</p>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item sm={3} xs={12} style={{paddingLeft:"15px"}}>
                      <InputLabel
                        htmlFor="inr"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        INR
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item sm={5.8} xs={6} style={{ marginRight: "8px" }}>
                      <TextField
                        fullWidth
                        id="inr"
                        value={patientLabResult.inr}
                        onChange={(e) => {
                          setPatientLabResult({
                            ...patientLabResult,
                            inr: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "54px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={5}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale="en"
                      >
                        <DatePicker
                        id="inrDate"
                          fullWidth
                          inputFormat="dd/MM/yyyy"
                          value={
                            patientLabResult.inrDate !== null
                              ? new Date(patientLabResult.inrDate * 1000)
                              : null
                          }
                          maxDate={new Date()}
                          onChange={(date) =>
                            setPatientLabResult({
                              ...patientLabResult,
                              inrDate: Math.floor(
                                new Date(date).getTime() / 1000.0
                              ),
                            })
                          }
                          disableFuture
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* <Hidden only={["lg", "sm", "xl", "md"]}>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="inr"
                          style={{
                            color: "#1A1A1A",
                            marginBottom: "4px",
                          }}
                        >
                          INR
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6} style={{ marginRight: "8px" }}>
                        <TextField
                          fullWidth
                          id="inr"
                          value={patientLabResult.inr}
                          onChange={(e) => {
                            setPatientLabResult({
                              ...patientLabResult,
                              inr: e.target.value,
                            });
                          }}
                          InputProps={{
                            style: {
                              height: "54px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale="en"
                        >
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            value={
                              patientLabResult.ptTimeDate !== null
                                ? new Date(patientLabResult.ptTimeDate * 1000)
                                : null
                            }
                            maxDate={new Date()}
                            onChange={(date) =>
                              setPatientLabResult({
                                ...patientLabResult,
                                ptTimeDate: Math.floor(
                                  new Date(date).getTime() / 1000.0
                                ),
                              })
                            }
                            disableFuture
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Hidden> */}
                  </Grid>
                </Grid>

                {/* ///////// */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item sm={3} xs={12}style={{paddingLeft:"15px"}}>
                      <InputLabel
                        htmlFor="ptTime"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        PT TIME
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item sm={5.8} xs={6} style={{ marginRight: "8px" }}>
                      <TextField
                        fullWidth
                        id="ptTime"
                        value={patientLabResult.ptTime}
                        onChange={(e) => {
                          setPatientLabResult({
                            ...patientLabResult,
                            ptTime: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "54px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={5}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale="en"
                      >
                        <DatePicker
                          fullWidth
                          inputFormat="dd/MM/yyyy"
                          value={
                            patientLabResult.ptTimeDate !== null
                              ? new Date(patientLabResult.ptTimeDate * 1000)
                              : null
                          }
                          maxDate={new Date()}
                          onChange={(date) =>
                            setPatientLabResult({
                              ...patientLabResult,
                              ptTimeDate: Math.floor(
                                new Date(date).getTime() / 1000.0
                              ),
                            })
                          }
                          disableFuture
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* <Hidden only={["lg", "sm", "xl", "md"]}>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="pt-time"
                          style={{
                            color: "#1A1A1A",
                            marginBottom: "4px",
                          }}
                        >
                          PT TIME
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6} style={{ marginRight: "8px" }}>
                        <TextField
                          fullWidth
                          id="pt-time"
                          value={patientLabResult.ptTime}
                          onChange={(e) => {
                            setPatientLabResult({
                              ...patientLabResult,
                              ptTime: e.target.value,
                            });
                          }}
                          InputProps={{
                            style: {
                              height: "54px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale="en"
                        >
                          <DatePicker
                            placeholder="DD-MM-YY"
                            value={patientLabResult.ptTimeDate}
                            onChange={handlePtTimeDateChange}
                            disableFuture
                            renderInput={(params) => <TextField {...params} />}
                            format="DD/MM/YYYY"
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Hidden> */}
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item sm={3} xs={12}style={{paddingLeft:"15px"}}>
                      <InputLabel
                        htmlFor="dDimer"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        D-DIMER
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item sm={5.8} xs={6} style={{ marginRight: "8px" }}>
                      <TextField
                        fullWidth
                        id="dDimer"
                        value={patientLabResult.dDimer}
                        onChange={(e) => {
                          setPatientLabResult({
                            ...patientLabResult,
                            dDimer: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "54px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={5}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale="en"
                      >
                        <DatePicker
                          fullWidth
                          inputFormat="dd/MM/yyyy"
                          value={
                            patientLabResult.dDimerDate !== null
                              ? new Date(patientLabResult.dDimerDate * 1000)
                              : null
                          }
                          maxDate={new Date()}
                          onChange={(date) =>
                            setPatientLabResult({
                              ...patientLabResult,
                              dDimerDate: Math.floor(
                                new Date(date).getTime() / 1000.0
                              ),
                            })
                          }
                          disableFuture
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* <Hidden only={["lg", "sm", "xl", "md"]}>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="d-dimer"
                          style={{
                            color: "#1A1A1A",
                            marginBottom: "4px",
                          }}
                        >
                          D-DIMER
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6} style={{ marginRight: "8px" }}>
                        <TextField
                          fullWidth
                          id="d-dimer"
                          value={patientLabResult.dDimer}
                          onChange={(e) => {
                            setPatientLabResult({
                              ...patientLabResult,
                              dDimer: e.target.value,
                            });
                          }}
                          InputProps={{
                            style: {
                              height: "54px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale="en"
                        >
                          <DatePicker
                            placeholder="DD-MM-YY"
                            value={patientLabResult.dDimerDate}
                            onChange={handledDimerDateChange}
                            disableFuture
                            renderInput={(params) => <TextField {...params} />}
                            format="DD/MM/YYYY"
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Hidden> */}
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item sm={3} xs={12}style={{paddingLeft:"15px"}}>
                      <InputLabel
                        htmlFor="ccrl"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        CrCl
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item sm={5.8} xs={6} style={{ marginRight: "8px" }}>
                      <TextField
                        fullWidth
                        id="ccrl"
                        value={patientLabResult.ccrl}
                        onChange={(e) => {
                          setPatientLabResult({
                            ...patientLabResult,
                            ccrl: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "54px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={5}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale="en"
                      >
                        <DatePicker
                          fullWidth
                          inputFormat="dd/MM/yyyy"
                          value={
                            patientLabResult.ccrlDate !== null
                              ? new Date(patientLabResult.ccrlDate * 1000)
                              : null
                          }
                          maxDate={new Date()}
                          onChange={(date) =>
                            setPatientLabResult({
                              ...patientLabResult,
                              ccrlDate: Math.floor(
                                new Date(date).getTime() / 1000.0
                              ),
                            })
                          }
                          disableFuture
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* <Hidden only={["lg", "sm", "xl", "md"]}>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="ccrl"
                          style={{
                            color: "#1A1A1A",
                            marginBottom: "4px",
                          }}
                        >
                          CrCl
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6} style={{ marginRight: "8px" }}>
                        <TextField
                          fullWidth
                          id="ccrl"
                          value={patientLabResult.ccrl}
                          onChange={(e) => {
                            setPatientLabResult({
                              ...patientLabResult,
                              ccrl: e.target.value,
                            });
                          }}
                          InputProps={{
                            style: {
                              height: "54px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale="en"
                        >
                          <DatePicker
                            placeholder="DD-MM-YY"
                            value={patientLabResult.ccrlDate}
                            onChange={handleccrlDateChange}
                            disableFuture
                            renderInput={(params) => <TextField {...params} />}
                            format="DD/MM/YYYY"
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Hidden> */}
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item sm={3} xs={12}style={{paddingLeft:"15px"}}>
                      <InputLabel
                        htmlFor="other"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        (OTHER)
                      </InputLabel>
                    </Grid>
                    <Grid item sm={5.8} xs={6} style={{ marginRight: "8px" }}>
                      <TextField
                        fullWidth
                        id="other"
                        value={patientLabResult.other}
                        onChange={(e) => {
                          setPatientLabResult({
                            ...patientLabResult,
                            other: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "54px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={5}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale="en"
                      >
                        <DatePicker
                          fullWidth
                          inputFormat="dd/MM/yyyy"
                          value={
                            patientLabResult.otherDate !== null
                              ? new Date(patientLabResult.otherDate * 1000)
                              : null
                          }
                          maxDate={new Date()}
                          onChange={(date) =>
                            setPatientLabResult({
                              ...patientLabResult,
                              otherDate: Math.floor(
                                new Date(date).getTime() / 1000.0
                              ),
                            })
                          }
                          disableFuture
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* <Hidden only={["lg", "sm", "xl", "md"]}>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor="other"
                          style={{
                            color: "#1A1A1A",
                            marginBottom: "4px",
                          }}
                        >
                          (OTHER)
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6} style={{ marginRight: "8px" }}>
                        <TextField
                          fullWidth
                          id="other"
                          value={patientLabResult.other}
                          onChange={(e) => {
                            setPatientLabResult({
                              ...patientLabResult,
                              other: e.target.value,
                            });
                          }}
                          InputProps={{
                            style: {
                              height: "54px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale="en"
                        >
                          <DatePicker
                            placeholder="DD-MM-YY"
                            value={patientLabResult.otherDate}
                            onChange={handleotherDateChange}
                            disableFuture
                            renderInput={(params) => <TextField {...params} />}
                            format="DD/MM/YYYY"
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Hidden> */}
                  </Grid>
                </Grid>

                {/* ///////// */}

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 15 }}
                >
                  <InputLabel
                    htmlFor="writtenInfos"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                      whiteSpace: "normal", // Prevent line breaks
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis",
                    }}
                  >
                    Comments
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="writtenInfos"
                    multiline
                    value={patientLabResult.writtenInfos}
                    onChange={(e) => {
                      setPatientLabResult({
                        ...patientLabResult,
                        writtenInfos: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "84px",
                        backgroundColor: "#FAFAFA",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setPatientLabResult(patientLabResultDefaultValue);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setPatientLabResult(patientLabResultDefaultValue);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={validatelabresult}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={validatelabresult}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default LabResult;

import React, { Component, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./home/index";
import Setting from "./Setting/index";
import Layout from "./home/Layout";
import { AppProvider } from "./AppState";
import { createTheme, ThemeProvider } from "@mui/material";
import Drafts from "./Drafts";
import History from "./History";
import Survey from "./Survey";
const HcpRoute = () => {
  const callback = () => {
    console.log("Callback function called!");
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  return (
    <div className={"bg-light "} style={{ minHeight: "100vh" }}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Layout callback={callback}>
            <Suspense fallback={<div>Loading</div>}>
              <Switch>
                <Route exact path={["/hcp/home"]} component={Home} />
                <Route exact path={["/hcp/setting"]} component={Setting} />
                <Route exact path={["/hcp/mydrafts"]} component={Drafts} />
                <Route exact path={["/hcp/history"]} component={History} />
                <Route exact path={["/hcp/create/:id?"]} component={Survey} />
              </Switch>
            </Suspense>
          </Layout>
        </AppProvider>
      </ThemeProvider>
    </div>
  );
};

export default HcpRoute;

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import APIRequest from "./utils/APIRequest";
import ConfigAPIURL from "./config/ConfigAPIURL";
import { useState, useEffect } from "react";

function PrivateRoute({ component: Component, ...rest }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkIfUserIsLoggedIn();
  }, []);

  function checkIfUserIsLoggedIn() {
    setIsLoading(true);
    APIRequest.request("GET", ConfigAPIURL.hcpSessionValidation, "")
      .then((response) => {
        if (response?.data?.responseCode === 109) {
          setIsLoggedIn(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log("Is Login: ", isLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/hcp/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;

import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";

import { AppContext } from "../AppState";
import { useEffect } from "react";
import FormValidation from "../../../../utils/FormValidation";



const Authorizationreq = (props) => {
  const { authorization, setAuthorization,defaultAuthorization } = props;
  const [checkbox5mg, setCheckbox5mg] = useState(false);
  const [checkbox2_5mg, setCheckbox2_5mg] = useState(false);
  const [standardbox, setStandardbox] = useState(false);
  const [expeditedbox, setExpeditedbox] = useState(false);

  useEffect(() => {
    if (authorization.dosage === "5mg BD") {
      setCheckbox5mg(true);
      setCheckbox2_5mg(false);
    } else if (authorization.dosage === "2.5mg BD") {
      setCheckbox5mg(false);
      setCheckbox2_5mg(true);
    }
  }, [authorization.dosage]);

  useEffect(() => {
    if (authorization.reqType === "Standard") {
      setStandardbox(true);
      setExpeditedbox(false);
    } else if (authorization.reqType === "Expedited") {
      setStandardbox(false);
      setExpeditedbox(true);
    }
  }, [authorization.reqType]);
  const validatePatientInfo = () => {
    let fieldValidation = [
      "diagnosis",
      "icd10Code",
      // "dose" ,
    ];
    FormValidation.validation(fieldValidation, authorization).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                REQUEST: Authorization for treatment with ZYQUIS (apixaban)
              </h2>
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 ,paddingTop: 15, paddingBottom: 15}}
                >
                  <InputLabel
                    htmlFor="diagnosis"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                    }}
                  >
                    Diagnosis<span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="diagnosis"
                    value={authorization.diagnosis}
                    onChange={(e) => {
                      setAuthorization({
                        ...authorization,
                        diagnosis: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="additionalDiagnosis"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Additional Diagnosis
                    
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="additionalDiagnosis"
                    value={authorization.additionalDiagnosis}
                    onChange={(e) => {
                      setAuthorization({
                        ...authorization,
                        additionalDiagnosis: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="icd10Code"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    ICD-10 Code<span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="icd10Code"
                    value={authorization.icd10Code}
                    onChange={(e) => {
                      setAuthorization({
                        ...authorization,
                        icd10Code: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="additionalIcd10Code"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Additional ICD-10 Code
                  </InputLabel>
                  <TextField
                    id="additionalIcd10Code"
                    fullWidth
                    // label="Specialization"
                    value={authorization.additionalIcd10Code}
                    onChange={(e) => {
                      setAuthorization({
                        ...authorization,
                        additionalIcd10Code: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5, }}
                >
                  <InputLabel
                    htmlFor="Dose"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                    }}
                  >
                    Dose and Frequency
                    <span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <FormControlLabel
                  
                  id="dose"
                    control={
                      <Checkbox
                        checked={
                          authorization.dosage === "5mg BD" ? true : false
                        }
                        onChange={() => {
                          setAuthorization({
                            ...authorization,
                            // dosage: "5mg BD",
                            dosage: authorization.dosage === "5mg BD" ? "" : "5mg BD",
                          });
                        }}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="5mg BD"
                  />

                  <FormControlLabel
                  
                    control={
                      <Checkbox
                      // id="dose"
                        checked={
                          authorization.dosage === "2.5mg BD" ? true : false
                        }
                        onChange={() => {
                          setAuthorization({
                            ...authorization,
                            // dosage: "2.5mg BD",
                            dosage: authorization.dosage === "2.5mg BD" ? "" : "2.5mg BD",

                          });
                        }}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="2.5mg BD"
                  />
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5}}
                >
                  <InputLabel
                    htmlFor="requestType"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                    }}
                  >
                    Request Type
                    <span style={{ color: "#D13438" }}>*</span>
                  </InputLabel>
                  <FormControlLabel
                  id="requestType"
                    control={
                      <Checkbox
                        checked={
                          authorization.reqType === "Standard" ? true : false
                        }
                        // onChange={() => {
                        //   setAuthorization({
                        //     ...authorization,
                        //     reqType: "Standard",
                        //   });
                        // }}
                        onChange={() => {
                          setAuthorization({
                            ...authorization,
                            reqType:
                              authorization.reqType === "Standard" ? "" : "Standard",
                          });
                        }}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="Standard"
                  />

                  <FormControlLabel
                  id="requestType"
                    control={
                      <Checkbox
                        checked={
                          authorization.reqType === "Expedited" ? true : false
                        }
                        // onChange={() => {
                        //   setAuthorization({
                        //     ...authorization,
                        //     reqType: "Expedited",
                        //   });
                        // }}
                        onChange={() => {
                          setAuthorization({
                            ...authorization,
                            reqType:
                              authorization.reqType === "Expedited" ? "" : "Expedited",
                          });
                        }}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="Expedited"
                  />
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="name"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                    }}
                  >
                    Dear
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="medical-aid"
                    // label="Practice Number"
                    value={authorization.name}
                    onChange={(e) => {
                      setAuthorization({
                        ...authorization,
                        name: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "44px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5,paddingTop: 15, paddingBottom: 15 }}
                >
                  <InputLabel
                    htmlFor="writtenInfos"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "4px",
                      // fontWeight: "550",
                      whiteSpace: "normal", // Prevent line breaks
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis",
                    }}
                  >
                    I am writing to request a formulary exception for the above
                    mentioned patient to receive treatment with ZYQUIS
                    (apixaban) for the indication
                    {/* <span style={{ color: "#D13438" }}>*</span> */}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="writtenInfos"
                    multiline
                    value={authorization.writtenInfos}
                    onChange={(e) => {
                      setAuthorization({
                        ...authorization,
                        writtenInfos: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "74px", // adjust this value as needed
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setAuthorization(defaultAuthorization);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setAuthorization(defaultAuthorization);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={validatePatientInfo}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={validatePatientInfo}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default Authorizationreq;

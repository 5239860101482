import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";

import { AppContext } from "../AppState";
import { useEffect } from "react";
// import { DatePicker, defaultDatePickerStrings } from "@fluentui/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormValidation from "../../../../utils/FormValidation";

const AtrialFibrillation = (props) => {
  const { atrialFibrillationata, setAtrialFibrillationData ,atrialFibrillationPage} = props;
  // const [activeButton, setActiveButton] = useState(null);

  const validateArtrialFibrillation = () => {
    let fieldValidation = [
      "age",
      "sex",
      "chfHistory",
      "hypertensionHistory",
      "thromboembolismHistory",
      "vascularDiseaseHistory",
      "diabeteshistory",
      // "address",
    ];
    FormValidation.validation(fieldValidation, atrialFibrillationata).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };

  const handleButtonClick = (newValue, buttonIndex) => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      age: newValue,
    });
    // setActiveAgeButton(buttonIndex);
  };
  const handleSexClick = (newValue, buttonIndex) => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      sex: newValue,
    });
    // setActiveSexButton(buttonIndex);
  };
  const handleChfHistoryClick = (newValue) => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      chfHistory: newValue,
    });
  };

  const handlehypertensionHistory = (newValue) => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      hypertensionHistory: newValue,
    });
  };
  const handlethromboembolismHistory = (newValue) => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      thromboembolismHistory: newValue,
    });
  };
  const handlevascularDiseaseHistory = (newValue) => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      vascularDiseaseHistory: newValue,
    });
  };
  const handlediabeteshistory = (newValue) => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      diabeteshistory: newValue,
    });
  };

  function calculateRiskScore(patientData) {
    let score = 0;
    const age = patientData.age;
    const sex = patientData.sex;
    const chfHistory = patientData.chfHistory;
    const hypertensionHistory = patientData.hypertensionHistory;
    const thromboembolismHistory = patientData.thromboembolismHistory;
    const vascularDiseaseHistory = patientData.vascularDiseaseHistory;
    const diabeteshistory = patientData.diabeteshistory;

    if (sex == 1) {
      score += 1;
    }
    // if (sex == 0) {
    //   score += 1;
    // }
    if (age == 2) {
      score += 2;
    } else if (age == 1) {
      score += 1;
    }
    if (chfHistory) {
      score += 1;
    }
    if (hypertensionHistory) {
      score += 1;
    }
    if (vascularDiseaseHistory) {
      score += 1;
    }
    if (thromboembolismHistory) {
      score += 1;
    }
    if (diabeteshistory) {
      score += 1;
    }
    // setAtrialFibrillationData({
    //   ...atrialFibrillationata,
    //   totalRisk: score
    // });

    return score;
  }
  const scores = calculateRiskScore(atrialFibrillationata);
  useEffect(() => {
    setAtrialFibrillationData({
      ...atrialFibrillationata,
      totalRisk: scores,
    });
  }, [scores]);

  const handleCheckboxChange = (event) => {
    const riskType = event.target.value;

    if (atrialFibrillationata.riskType === riskType) {
      // if the clicked checkbox is already checked, uncheck it
      setAtrialFibrillationData({
        ...atrialFibrillationata,
        riskType: null,
      });
    } else {
      // otherwise, check the clicked checkbox and uncheck the others
      setAtrialFibrillationData({
        ...atrialFibrillationata,
        riskType: riskType,
      });
    }
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                CHA<sub style={{ fontSize: "0.6em" }}>2</sub>DS
                <sub style={{ fontSize: "0.6em" }}>2</sub>-VASc Score for Atrial
                Fibrillation Stroke Risk
              </h2>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  boxShadow:
                    " 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)",
                  padding: "15px",
                  paddingBottom: "30px",
                  width: "96%",
                  borderRadius: "8px",
                  // margin: "10px",
                }}
              >
                <Hidden only={["xs"]}>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      backgroundColor: "#FAFAFA",
                      marginTop: "-13px",
                      marginLeft: "-13px",
                    }}
                  >
                    <Grid xl={3} lg={3} md={4} sm={2} style={{ paddingLeft: "8px" }}>
                      {/* <p>Test</p> */}
                    </Grid>
                    <Grid
                      xl={10}
                      lg={10}
                      md={4}
                      sm={8}
                      style={{ paddingLeft: "8px", textAlign: "center",fontWeight:'600' }}
                    >
                      <p>Criteria</p>
                    </Grid>
                    <Grid xl={1} lg={1} md={4} style={{ paddingLeft: "6px",fontWeight:'600' }}>
                      <p>Score</p>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={4} lg={4}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        Age
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      xl={7}
                      lg={7}
                      style={{ marginRight: "8px" }}
                    >
                      <Grid container spacing={1} justify="space-between">
                        {["\u003C65    0", "65-75    +1", "\u226575   +2"].map(
                          (item, index) => (
                            <Grid item xs={12} xl={4} lg={4}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setAtrialFibrillationData({
                                    ...atrialFibrillationata,
                                    age: index,
                                    ageCriteria: item,
                                  });
                                }}
                                style={{
                                  // backgroundColor: "#FAFAFA",
                                  backgroundColor:
                                    atrialFibrillationata.age === index
                                      ? "#fff"
                                      : "#fff",
                                  color: "#000",
                                  border: atrialFibrillationata.age === index
                                  ? "2px solid #800000"
                                  : "1px solid #8A8886",
                                  // border: "1px solid #800000",
                                  width: "100%",
                                  boxShadow: "none",
                                  fontFamily: "Segoe UI",
                                }}
                              >
                                {item}
                              </Button>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="age"
                        value={atrialFibrillationata.age}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ------ */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={4} lg={4}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        Sex
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      xl={7}
                      lg={7}
                      style={{ marginRight: "8px" }}
                    >
                      {/* <Grid container spacing={1} justify="space-between"> */}
                        <Grid container spacing={1} justify="space-between">
                          {["Female   +1", "Male   0"].map((item, index) => (
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setAtrialFibrillationData({
                                    ...atrialFibrillationata,
                                    sex: item === "Female   +1" ? 1 : 0,
                                    sexCriteria: item,
                                  });
                                }}
                                style={{
                                  // backgroundColor: "#FAFAFA",
                                  backgroundColor:
                                    atrialFibrillationata.sexCriteria === item
                                      ? "#fff"
                                      : "#fff",
                                      border: atrialFibrillationata.sexCriteria === item
                                      ? "2px solid #800000"
                                      : "1px solid #8A8886",
                                  color: "#000",
                                  // border: "1px solid #8A8886",
                                  width: "100%",
                                  boxShadow: "none",
                                  fontFamily: "Segoe UI",
                                }}
                              >
                                {item}
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      {/* </Grid> */}
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="sex"
                        value={atrialFibrillationata.sex}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={4} lg={4}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        CHF History
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      xl={7}
                      lg={7}
                      style={{ marginRight: "8px" }}
                    >
                      <Grid container spacing={1} justify="space-between">
                        {["No   0", "Yes   +1"].map((item, index) => (
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setAtrialFibrillationData({
                                  ...atrialFibrillationata,
                                  chfHistory: index,
                                  chfHistoryCriteria: item,
                                });
                              }}
                              style={{
                                // backgroundColor: "#FAFAFA",
                                backgroundColor:
                                  atrialFibrillationata.chfHistoryCriteria ===
                                  item
                                    ? "#fff"
                                    : "#fff",
                                    border: atrialFibrillationata.chfHistoryCriteria === item
                                    ? "2px solid #800000"
                                    : "1px solid #8A8886",
                                color: "#000",
                                // border: "1px solid #8A8886",
                                width: "100%",
                                boxShadow: "none",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              {item}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="chfHistory"
                        value={atrialFibrillationata.chfHistory}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={4} lg={4}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        Hypertension history
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      xl={7}
                      lg={7}
                      style={{ marginRight: "8px" }}
                    >
                      <Grid container spacing={1} justify="space-between">
                        {["No   0", "Yes   +1"].map((item, index) => (
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setAtrialFibrillationData({
                                  ...atrialFibrillationata,
                                  hypertensionHistory: index,
                                  hypertensionHistoryCriteria: item,
                                });
                              }}
                              style={{
                                // backgroundColor: "#FAFAFA",
                                backgroundColor:
                                  atrialFibrillationata.hypertensionHistoryCriteria ===
                                  item
                                    ? "#fff"
                                    : "#fff",
                                    border: atrialFibrillationata.hypertensionHistoryCriteria === item
                                    ? "2px solid #800000"
                                    : "1px solid #8A8886",
                                color: "#000",
                                // border: "1px solid #8A8886",
                                width: "100%",
                                boxShadow: "none",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              {item}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="hypertensionHistory"
                        value={atrialFibrillationata.hypertensionHistory}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={4} lg={4}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Stroke / TIA / thromboembolism history
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      xl={7}
                      lg={7}
                      style={{ marginRight: "8px" }}
                    >
                      <Grid container spacing={1} justify="space-between">
                        {["No   0", "Yes   +1"].map((item, index) => (
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setAtrialFibrillationData({
                                  ...atrialFibrillationata,
                                  thromboembolismHistory: index,
                                  thromboembolismHistoryCriteria: item,
                                });
                              }}
                              style={{
                                // backgroundColor: "#FAFAFA",
                                backgroundColor:
                                  atrialFibrillationata.thromboembolismHistoryCriteria ===
                                  item
                                    ? "#fff"
                                    : "#fff",
                                    border: atrialFibrillationata.thromboembolismHistoryCriteria === item
                                    ? "2px solid #800000"
                                    : "1px solid #8A8886",
                                color: "#000",
                                // border: "1px solid #8A8886",
                                width: "100%",
                                boxShadow: "none",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              {item}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="thromboembolismHistory"
                        value={atrialFibrillationata.thromboembolismHistory}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={4} lg={4}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Vascular disease history (Prior MI,
                        <br />
                        Peripheral artery disease, or aortic plaque)
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      xl={7}
                      lg={7}
                      style={{ marginRight: "8px" }}
                    >
                      <Grid container spacing={1} justify="space-between">
                        {["No   0", "Yes   +1"].map((item, index) => (
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setAtrialFibrillationData({
                                  ...atrialFibrillationata,
                                  vascularDiseaseHistory: index,
                                  vascularDiseaseHistoryCriteria: item,
                                });
                              }}
                              style={{
                                // backgroundColor: "#FAFAFA",
                                backgroundColor:
                                  atrialFibrillationata.vascularDiseaseHistoryCriteria ===
                                  item
                                    ? "#fff"
                                    : "#fff",
                                    border: atrialFibrillationata.vascularDiseaseHistoryCriteria === item
                                    ? "2px solid #800000"
                                    : "1px solid #8A8886",
                                color: "#000",
                                // border: "1px solid #8A8886",
                                width: "100%",
                                boxShadow: "none",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              {item}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="vascularDiseaseHistory"
                        value={atrialFibrillationata.vascularDiseaseHistory}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} xl={4} lg={4}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        Diabetes history
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      xl={7}
                      lg={7}
                      style={{ marginRight: "8px" }}
                    >
                      <Grid container spacing={1} justify="space-between">
                        {["No   0", "Yes   +1"].map((item, index) => (
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setAtrialFibrillationData({
                                  ...atrialFibrillationata,
                                  diabeteshistory: index,
                                  diabeteshistoryCriteria: item,
                                });
                              }}
                              style={{
                                // backgroundColor: "#FAFAFA",
                                backgroundColor:
                                  atrialFibrillationata.diabeteshistoryCriteria ===
                                  item
                                    ? "#fff"
                                    : "#fff",
                                    border: atrialFibrillationata.diabeteshistoryCriteria === item
                                    ? "2px solid #800000"
                                    : "1px solid #8A8886",
                                color: "#000",
                                // border: "1px solid #8A8886",
                                width: "100%",
                                boxShadow: "none",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              {item}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={0.5}>
                      <TextField
                        id="diabeteshistory"
                        value={atrialFibrillationata.diabeteshistory}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* ---- */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xs={6}
                      xl={10}
                      lg={10}
                      md={10}
                      style={{ marginRight: "8px" }}
                    ></Grid>
                    <Grid item xs={4} xl={1} lg={1} md={1}>
                      <InputLabel
                        htmlFor="Age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                        }}
                      >
                        Total Risk
                      </InputLabel>
                    </Grid>
                    <Grid item xs={0.5} style={{ display: "flex" }}>
                      <TextField
                        id="score"
                        value={calculateRiskScore(atrialFibrillationata)}
                        disabled
                        InputProps={{
                          style: {
                            height: "40px",
                            width: "59px",
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ------ */}
              </Grid>

              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FFF3F3",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ marginLeft: "14px" }}>
                  From the above risk assessment it is established that the
                  patient has<span style={{ color: "#D13438" }}>*</span>
                </p>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5, marginLeft: "14px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={standardbox}
                        // onChange={() => {
                        //   setStandardbox(true);
                        //   setExpeditedbox(false);
                        //   setAuthorization({
                        //     ...authorization,
                        //     reqType: "Standard",
                        //   });
                        // }}
                        value="Low Risk"
                        checked={atrialFibrillationata.riskType === "Low Risk"}
                        onChange={handleCheckboxChange}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="Low Risk"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={expeditedbox}
                        // onChange={() => {
                        //   setStandardbox(false);
                        //   setExpeditedbox(true);
                        //   setAuthorization({
                        //     ...authorization,
                        //     reqType: "Expedited",
                        //   });
                        // }}
                        value="Moderate Risk"
                        checked={
                          atrialFibrillationata.riskType === "Moderate Risk"
                        }
                        onChange={handleCheckboxChange}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="Moderate Risk"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={expeditedbox}
                        // onChange={() => {
                        //   setStandardbox(false);
                        //   setExpeditedbox(true);
                        //   setAuthorization({
                        //     ...authorization,
                        //     reqType: "Expedited",
                        //   });
                        // }}
                        value="High Risk"
                        checked={atrialFibrillationata.riskType === "High Risk"}
                        onChange={handleCheckboxChange}
                        style={{ color: "#800000" }}
                      />
                    }
                    label="High Risk"
                  />
                </Grid>
                </Grid>

                {/* ------------ */}
                <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FAFAFA",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ margin: "12px", lineHeight: 2.5 }}>
                From the above risk assessment it is established that the patient has 
                  <input
                    id="patientHasCirrhosis"
                    variant="outlined"
                    className="segoe-input"
                    style={{
                      width: "30%",
                      height: "28px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                      borderRadius: "2px",
                      fontFamily:'Segoe UI'
                    }}
                    disabled
                    placeholder="LOW / MODERATE / HIGH risk"
                    value={atrialFibrillationata.riskType}
                  />
for stroke. From the information in references listed below it is advised that ZYQUIS (apixaban) will
have better results for stroke prevention for my patient than the medication currently listed on the
formulary.<sup>1,2,3</sup> 

                </p>
              </Grid>
              
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setAtrialFibrillationData(atrialFibrillationPage);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setAtrialFibrillationData(atrialFibrillationPage);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={validateArtrialFibrillation}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={validateArtrialFibrillation}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default AtrialFibrillation;

import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";

import { AppContext } from "../AppState";
import { useEffect } from "react";
// import { DatePicker, defaultDatePickerStrings } from "@fluentui/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import SideModal from "../Modals/SideModal";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import FormValidation from "../../../../utils/FormValidation";
import ImageIcon from "@mui/icons-material/Image";

const Classification = (props) => {
  const { childPughScore, setChildPughScore, handleNext, childPughScorePage } =
    props;
  //   const [pdfFiles, setPdfFiles] = useState([]);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const validateChildPugh = () => {
    let fieldValidation = [
      // "bilirubinScore",
      // "albuminScore",
      // "inrScore",
      // "ascitesScore",
      // "encephalopathyScore",
      // "patientHasCirrhosis"
      // "outlinedBasic",
      // "outlineBasic",
      // "address",
    ];
    FormValidation.validation(fieldValidation, childPughScore).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };

  const handleButtonClick = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      bilirubin: newValue,
    });
  };
  const handleAlbuminClick = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      albumin: newValue,
    });
  };
  const handleInrClick = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      inr: newValue,
    });
  };

  const handleAscitesHistory = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      ascites: newValue,
    });
  };
  const handleEncephalopathyHistory = (newValue) => {
    setChildPughScore({
      ...childPughScore,
      encephalopathy: newValue,
    });
  };
  const handlePatientHasCirrhosis = (e) => {
    setChildPughScore({
      ...childPughScore,
      patientHasCirrhosis: e.target.value,
    });
  };
  const handleResultIn = (e) => {
    setChildPughScore({
      ...childPughScore,
      resultIn: e.target.value,
    });
  };
  const handleZYQUISmgBD = (e) => {
    setChildPughScore({
      ...childPughScore,
      ZYQUISmgBD: e.target.value,
    });
  };

  function calculateRiskScore(patientData) {
    let score = 0;
    const bilirubin = patientData.bilirubinScore;
    const albumin = patientData.albuminScore;
    const inr = patientData.inrScore;
    const ascites = patientData.ascitesScore;
    const encephalopathy = patientData.encephalopathyScore;

    if (albumin == 1) {
      score += 1;
    } else if (albumin == 2) {
      score += 2;
    } else if (albumin == 3) {
      score += 3;
    }

    if (bilirubin == 1) {
      score += 1;
    } else if (bilirubin == 2) {
      score += 2;
    } else if (bilirubin == 3) {
      score += 3;
    }

    if (inr == 1) {
      score += 1;
    } else if (inr == 2) {
      score += 2;
    } else if (inr == 3) {
      score += 3;
    }

    if (ascites == 1) {
      score += 1;
    } else if (ascites == 2) {
      score += 2;
    } else if (ascites == 3) {
      score += 3;
    }

    if (encephalopathy) {
      if (encephalopathy == 1) {
        score += 1;
      } else if (encephalopathy == 2) {
        score += 2;
      } else if (encephalopathy >= 3) {
        score += 3;
      }
    }

    return score;
  }
  const scores = calculateRiskScore(childPughScore);
  useEffect(() => {
    setChildPughScore({
      ...childPughScore,
      totalRisk: scores,
    });
  }, [scores]);

  const handleCheckboxChange = (event) => {
    const riskType = event.target.value;

    if (childPughScore.riskType === riskType) {
      // if the clicked checkbox is already checked, uncheck it
      setChildPughScore({
        ...childPughScore,
        riskType: null,
      });
    } else {
      // otherwise, check the clicked checkbox and uncheck the others
      setChildPughScore({
        ...childPughScore,
        riskType: riskType,
      });
    }
  };

  const handleLogoUpload = (event, key) => {
    // console.log("Hello: ", event);
    if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
      [...event.target.files].map(async (data) => {
        let formData = new FormData();
        formData.append(`attachment`, data);

        formData.append("bucketName", "survey");

        console.log("Hello: ", formData);
        APIRequest.multipartForDataRequest(
          "PUT",
          ConfigAPIURL.uploadFile,
          formData
        ).then((response) => {
          if (response !== undefined && response !== null) {
            console.log("Hi");
            setChildPughScore((prevObject) => {
              if (Array.isArray(prevObject[key])) {
                return {
                  ...prevObject,
                  [key]: prevObject[key].concat(response.data.attachmentUrl[0]),
                };
              } else {
                return {
                  ...prevObject,
                  [key]: response.data.attachmentUrl[0],
                };
              }
            });
            setTimeout(function () {}, 1000);
          } else {
          }
        });
      });
    } else {
    }
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop: "4px",
                }}
              >
                Conclusion
              </h2>
              {/* <p>Estimates cirrhosis severity</p> */}

              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  backgroundColor: "#FAFAFA",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <p style={{ margin: "12px", lineHeight: 2.5 }}>
                  It is with all the above mentioned reasons, and my
                  professional medical opinion that should my patient not
                  receive ZYQUIS (apixaban), it would result in
                  <span style={{ color: "#D13438" }}>*</span>
                  <input
                    id="outlinebasic"
                    variant="outlined"
                    style={{
                      width: "50%",
                      height: "28px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #8A8886",
                      borderRadius: "2px",
                    }}
                    value={childPughScore.resultIn}
                    onChange={handleResultIn}
                  />
                  Please approve ZYQUIS
                  <input
                    id="outlined-basic"
                    variant="outlined"
                    style={{
                      width: "50px",
                      height: "28px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #8A8886",
                      borderRadius: "2px",
                    }}
                    value={childPughScore.ZYQUISmgBD}
                    onChange={handleZYQUISmgBD}
                  />
                  mg BD to be covered as chronic or Prescribed Minimum Benefit
                  (PMB) on the patient’s treatment plan.
                </p>
              </Grid>

              <p>Upload</p>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  //   backgroundColor: "#FAFAFA",
                  marginTop: "20px",
                  borderRadius: "8px",
                }}
              >
                <Grid
                  xl={3.5}
                  lg={3.5}
                  md={3.5}
                  sm={12}
                  xs={12}
                  style={{
                    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    paddingBottom: "30px",
                    borderRadius: "8px",
                    margin: "10px",
                    minWidth: 270,
                  }}
                >
                  <Grid
                    xs={12}
                    style={{
                      alignItems: "right",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      //   onClick={handleAddMedication}
                      onClick={() => {
                        document.getElementById("pdf-inputs").click();
                      }}
                      // style={{
                      //   color: "#fff",
                      //   backgroundColor:
                      //     childPughScore.consentientLetterPdf.length > 0
                      //       ? "#c1c1c1"
                      //       : "#800000",
                      //   height: "32px",
                      //   width: "12px",
                      //   marginBottom: "8px",
                      // }}
                      style={{
                        color: "#fff",
                        backgroundColor: "#800000",
                        height: "32px",
                        width: "12px",
                        marginBottom: "8px",
                      }}
                      // disabled={
                      //   childPughScore.consentientLetterPdf.length > 0
                      //     ? true
                      //     : false
                      // }
                    >
                      <AddIcon />
                    </Button>
                    <input
                      id="pdf-inputs"
                      type="file"
                    //   accept=".pdf, image/*"
                      accept="*"
                      key={Date.now()}
                      onChange={(e) => {
                        console.log("Okay");
                        handleLogoUpload(e, "consentientLetterPdf");
                      }}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  <p
                    style={{
                      padding: "10px",
                      fontWeight: "600",
                      color: "#323130",
                      paddingTop: "0",
                      marginTop: "-32px",
                      paddingLeft: "2px",
                    }}
                  >
                    Consent Letter
                  </p>
                  {childPughScore.consentientLetterPdf.length > 0 ? (
                    childPughScore.consentientLetterPdf.map(
                      (pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "190px",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {pdfFile.toLowerCase().endsWith(".pdf") ? (
                                <PictureAsPdfIcon
                                  style={{
                                    color: "red",
                                    marginRight: "5px",
                                    alignItems: "center",
                                  }}
                                />
                              ) : (
                                <ImageIcon
                                  style={{
                                    color: "blue",
                                    marginRight: "5px",
                                    alignItems: "center",
                                  }}
                                  alt="Icon"
                                />
                              )}
                              {/* <PictureAsPdfIcon
                                style={{
                                  color: "red",
                                  marginRight: "5px",
                                  alignItems: "center",
                                }}
                              /> */}
                              <span
                                className="pdf-file-name"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "120px",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: "#000000",
                                }}
                              >
                                {pdfFile.split("_")[1]}
                              </span>
                            </span>
                          </Grid>
                          <span style={{ display: "flex", marginLeft: "4px" }}>
                            <VisibilityIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                window.open(pdfFile, "_blank");
                              }}
                            />
                            <DeleteIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                const newArr = [
                                  ...childPughScore.consentientLetterPdf,
                                ];
                                newArr.splice(index, 1);
                                setChildPughScore({
                                  ...childPughScore,
                                  consentientLetterPdf: newArr,
                                });
                              }}
                            />
                          </span>
                        </Grid>
                      )
                    )
                  ) : (
                    <>
                      <p style={{ textAlign: "center", letterSpacing: "2px" }}>
                        Upload Consent Letter
                      </p>
                    </>
                  )}
                </Grid>

                <Grid
                  xl={3.5}
                  lg={3.5}
                  md={3.5}
                  sm={12}
                  xs={12}
                  style={{
                    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    paddingBottom: "30px",
                    borderRadius: "8px",
                    margin: "10px",
                    minWidth: 270,
                  }}
                >
                  <Grid>
                    <Grid
                      xs={12}
                      style={{
                        // paddingLeft: "12px",
                        alignItems: "right",
                        justifyContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Button
                        //   onClick={handleAddMedication}
                        onClick={() => {
                          document.getElementById("pdf-input").click();
                        }}
                        style={{
                          color: "#fff",
                          backgroundColor: "#800000",
                          height: "32px",
                          width: "12px",
                          marginBottom: "8px",
                        }}
                      >
                        <AddIcon />
                      </Button>
                      <input
                        id="pdf-input"
                        type="file"
                        // accept=".pdf, image/*"
                        accept="*"
                        onChange={(e) => {
                          handleLogoUpload(e, "labReports");
                        }}
                        style={{ display: "none" }}
                      />
                    </Grid>
                    <p
                      style={{
                        padding: "10px",
                        fontWeight: "600",
                        color: "#323130",
                        paddingTop: "0",
                        marginTop: "-32px",
                        paddingLeft: "2px",
                      }}
                    >
                      Lab Report
                    </p>
                    {/* -------------------------------------------- */}
                    {childPughScore.labReports.length > 0 ? (
                      childPughScore.labReports.map((pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "190px",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {pdfFile.toLowerCase().endsWith(".pdf") ? (
                                <PictureAsPdfIcon
                                  style={{
                                    color: "red",
                                    marginRight: "5px",
                                    alignItems: "center",
                                  }}
                                />
                              ) : (
                                <ImageIcon
                                  style={{
                                    color: "blue",
                                    marginRight: "5px",
                                    alignItems: "center",
                                  }}
                                  alt="Icon"
                                />
                              )}
                              {/* <PictureAsPdfIcon
                                style={{
                                  color: "red",
                                  marginRight: "5px",
                                  alignItems: "center",
                                }}
                              /> */}
                              <span
                                className="pdf-file-name"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "120px",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: "#000000",
                                }}
                              >
                                {pdfFile.split("_")[1]}
                              </span>
                            </span>
                          </Grid>
                          <span style={{ display: "flex", marginLeft: "4px" }}>
                            <VisibilityIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                window.open(pdfFile, "_blank");
                              }}
                            />
                            <DeleteIcon
                              style={{
                                color: "#000",
                                marginRight: "5px",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "6px",
                                width: "28px",
                                height: "28px",
                                padding: "6px",
                              }}
                              onClick={() => {
                                const newArr = [...childPughScore.labReports];
                                newArr.splice(index, 1);
                                setChildPughScore({
                                  ...childPughScore,
                                  labReports: newArr,
                                });
                              }}
                            />
                          </span>
                          {/* </Grid> */}
                        </Grid>
                      ))
                    ) : (
                      <>
                        <p
                          style={{ textAlign: "center", letterSpacing: "2px" }}
                        >
                          Upload lab Report
                        </p>
                      </>
                    )}

                    {/* ----------------------------------------------- */}
                  </Grid>
                </Grid>

                <Grid
                  xl={3.5}
                  lg={3.5}
                  md={3.5}
                  sm={12}
                  xs={12}
                  style={{
                    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    paddingBottom: "30px",
                    borderRadius: "8px",
                    margin: "10px",
                    minWidth: 270,
                  }}
                >
                  {/* <<>>>>???><></></> */}
                  <Grid
                    xs={12}
                    style={{
                      // paddingLeft: "12px",
                      alignItems: "right",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      //   onClick={handleAddMedication}
                      onClick={() => {
                        document.getElementById("pdf-input2").click();
                      }}
                      // style={{
                      //   color: "#fff",
                      //   backgroundColor:
                      //     childPughScore.scripts.length > 0
                      //       ? "#c1c1c1"
                      //       : "#800000",
                      //   height: "32px",
                      //   width: "12px",
                      //   marginBottom: "8px",
                      // }}
                      style={{
                        color: "#fff",
                        backgroundColor: "#800000",
                        height: "32px",
                        width: "12px",
                        marginBottom: "8px",
                      }}
                      // disabled={
                      //   childPughScore.scripts.length > 0 ? true : false
                      // }
                    >
                      <AddIcon />
                    </Button>
                    <input
                      id="pdf-input2"
                      type="file"
                    //   accept=".pdf, image/*"
                      accept="*"
                      onChange={(e) => {
                        handleLogoUpload(e, "scripts");
                      }}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  <p
                    style={{
                      padding: "10px",
                      fontWeight: "600",
                      color: "#323130",
                      paddingTop: "0",
                      marginTop: "-32px",
                      paddingLeft: "2px",
                    }}
                  >
                    Script
                  </p>
                  {childPughScore.scripts.length > 0 ? (
                    childPughScore.scripts.map((pdfFile, index) => (
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Grid
                          key={index}
                          style={{
                            boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                            width: "190px",
                            height: "54px",
                            borderRadius: "8px",
                            backgroundColor: "#FFFFFF",
                            marginLeft: "10px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {pdfFile.toLowerCase().endsWith(".pdf") ? (
                              <PictureAsPdfIcon
                                style={{
                                  color: "red",
                                  marginRight: "5px",
                                  alignItems: "center",
                                }}
                              />
                            ) : (
                              <ImageIcon
                                style={{
                                  color: "blue",
                                  marginRight: "5px",
                                  alignItems: "center",
                                }}
                                alt="Icon"
                              />
                            )}
                            {/* <PictureAsPdfIcon
                              style={{
                                color: "red",
                                marginRight: "5px",
                                alignItems: "center",
                              }}
                            /> */}
                            <span
                              className="pdf-file-name"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "120px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#000000",
                              }}
                            >
                              {pdfFile.split("_")[1]}
                            </span>
                          </span>
                        </Grid>
                        <span style={{ display: "flex", marginLeft: "4px" }}>
                          <VisibilityIcon
                            style={{
                              color: "#000",
                              marginRight: "5px",
                              backgroundColor: "#F2F2F2",
                              borderRadius: "6px",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                            }}
                            onClick={() => {
                              window.open(pdfFile, "_blank");
                            }}
                          />
                          <DeleteIcon
                            style={{
                              color: "#000",
                              marginRight: "5px",
                              backgroundColor: "#F2F2F2",
                              borderRadius: "6px",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                            }}
                            onClick={() => {
                              const newArr = [...childPughScore.scripts];
                              newArr.splice(index, 1);
                              setChildPughScore({
                                ...childPughScore,
                                scripts: newArr,
                              });
                            }}
                          />
                        </span>
                        {/* </Grid> */}
                      </Grid>
                    ))
                  ) : (
                    <>
                      <p style={{ textAlign: "center", letterSpacing: "2px" }}>
                        Upload Script
                      </p>
                    </>
                  )}
                  {/* <<>>>>???><></></> */}
                </Grid>

                {/* --------------------------------------- */}
                <Grid
                  xl={3.5}
                  lg={3.5}
                  md={3.5}
                  sm={12}
                  xs={12}
                  style={{
                    boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    paddingBottom: "30px",
                    borderRadius: "8px",
                    margin: "10px",
                    minWidth: 270,
                  }}
                >
                  {/* <<>>>>???><></></> */}
                  <Grid
                    xs={12}
                    style={{
                      // paddingLeft: "12px",
                      alignItems: "right",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      //   onClick={handleAddMedication}
                      onClick={() => {
                        document.getElementById("pdf-input3").click();
                      }}
                      // style={{
                      //   color: "#fff",
                      //   backgroundColor:
                      //     childPughScore.scripts.length > 0
                      //       ? "#c1c1c1"
                      //       : "#800000",
                      //   height: "32px",
                      //   width: "12px",
                      //   marginBottom: "8px",
                      // }}
                      style={{
                        color: "#fff",
                        backgroundColor: "#800000",
                        height: "32px",
                        width: "12px",
                        marginBottom: "8px",
                      }}
                      // disabled={
                      //   childPughScore.scripts.length > 0 ? true : false
                      // }
                    >
                      <AddIcon />
                    </Button>
                    <input
                      id="pdf-input3"
                      type="file"
                    //   accept=".pdf, image/*"
                      accept="*"
                      onChange={(e) => {
                        handleLogoUpload(e, "additionalMotivation");
                      }}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  <p
                    style={{
                      padding: "10px",
                      fontWeight: "600",
                      color: "#323130",
                      paddingTop: "0",
                      marginTop: "-32px",
                      paddingLeft: "2px",
                    }}
                  >
                    Additional Motivation
                  </p>
                  {childPughScore.additionalMotivation.length > 0 ? (
                    childPughScore.additionalMotivation.map((pdfFile, index) => (
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Grid
                          key={index}
                          style={{
                            boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                            width: "190px",
                            height: "54px",
                            borderRadius: "8px",
                            backgroundColor: "#FFFFFF",
                            marginLeft: "10px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {pdfFile.toLowerCase().endsWith(".pdf") ? (
                              <PictureAsPdfIcon
                                style={{
                                  color: "red",
                                  marginRight: "5px",
                                  alignItems: "center",
                                }}
                              />
                            ) : (
                              <ImageIcon
                                style={{
                                  color: "blue",
                                  marginRight: "5px",
                                  alignItems: "center",
                                }}
                                alt="Icon"
                              />
                            )}
                            {/* <PictureAsPdfIcon
                              style={{
                                color: "red",
                                marginRight: "5px",
                                alignItems: "center",
                              }}
                            /> */}
                            <span
                              className="pdf-file-name"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "120px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#000000",
                              }}
                            >
                              {pdfFile.split("_")[1]}
                            </span>
                          </span>
                        </Grid>
                        <span style={{ display: "flex", marginLeft: "4px" }}>
                          <VisibilityIcon
                            style={{
                              color: "#000",
                              marginRight: "5px",
                              backgroundColor: "#F2F2F2",
                              borderRadius: "6px",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                            }}
                            onClick={() => {
                              window.open(pdfFile, "_blank");
                            }}
                          />
                          <DeleteIcon
                            style={{
                              color: "#000",
                              marginRight: "5px",
                              backgroundColor: "#F2F2F2",
                              borderRadius: "6px",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                            }}
                            onClick={() => {
                              const newArr = [...childPughScore.additionalMotivation];
                              newArr.splice(index, 1);
                              setChildPughScore({
                                ...childPughScore,
                                additionalMotivation: newArr,
                              });
                            }}
                          />
                        </span>
                        {/* </Grid> */}
                      </Grid>
                    ))
                  ) : (
                    <>
                      <p style={{ textAlign: "center", letterSpacing: "2px" }}>
                        Upload Additional Motivation
                      </p>
                    </>
                  )}
                  {/* <<>>>>???><></></> */}
                </Grid>

                <p style={{ width: "100%" }}>Kind Regards</p>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    value={childPughScore.hcpName}
                    onChange={(e) =>
                      setChildPughScore({
                        ...childPughScore,
                        hcpName: e.target.value,
                      })
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      border: "none",
                      maxWidth: "370px",
                      width: "300px",
                    }}
                  />
                </Grid>
                <p>(Name of HCP)</p>

                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                  
                  <div style={{fontSize:'12px',color:'c1c1c1',padding:'0',margin:'0',backgroundColor:'#fafafa',padding:'10px',borderRadius:'8px'}}>
                  <p style={{fontSize:'12px'}}>
                    Please send completed motivation, with all blood tests,
                    Patient consent form and completed script to:
                    Zyquis@multilinkhs.co.za
                  </p>
                    <p> References:</p>
                    <p> 1. 2020 ESC Guidlines for the diagnosis and
                    management of atrial fibrillation developed in collaboration
                    with the European Association for Cardio-Thoracic Surgery
                    (EACTS). </p>
                   <p> 2. Registered Professional information of Zyquis
                    5mg and 2.5mg as approved by SAHPRA (South African Products
                    Regulatory Authority) 9 February 2021.</p>
                    <p> 3. Apixaban versus
                    Warfarin in Patients with Atrial Fibrillation. List of
                    authors. Christopher B. Granger, M.D., John H. Alexander,
                    M.D., M.H.S., John J.V. McMurray, M.D., Renato D. Lopes,
                    M.D.,Ph.D., Elaine M. Hylek, M.D., M.P.H., Michael Hanna,
                    M.D., Hussein R. Al-khalidi, Ph.D., Jack Ansell, M.D., Dan
                    Atar, M.D., Alvaro Avezum, M.D., Ph.D., M. Cecilia Bahit,
                    M.D., Rafael Diaz, M.D., et al., for the ARISTOTLE
                    Committees and Investigators, September 15, 2011 N Engl J
                    Med 2011; 365:981-992 DOI: 10.1056/NEJM oa 1107039.</p>
                    <p> 4.
                    Samuelson Bannow B. Management of heavy menstrual bleeding
                    on anticoagulation. Hematology Am Soc Hematol Educ Program.
                    2020 Dec 4;2020(1):533-537 doi:10.1182/hematology.
                    202020000138. PMID: 33275699; PMCID:PMC7727540. </p>
                    <p>5.
                    Vinogradova Y, Coupland C, Hill T, Hippisley-Cox J. Risks
                    and benefits of direct oral anticoagulants versus warfarin
                    in a real world setting: cohorts study in primary care BMJ
                    2018; 362:K2505 DOI:10.1136/BMJ.K2505. </p>
                   <p> 6. Elhosseiny S, AI
                    Moussawi H, Chalhoub JM, Lafferty J, Deep L. Direct Oral
                    Antcoagulants in Cirrhotic Patients: Current Evidence and
                    Clinical Observations. Can J Gastroenterol Hepatol. 2019 Jan
                    8;2019:4383269.doi:10.1155/2019/4383269.PMID:30792971;
                    PMCID: PMC6354142.</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setChildPughScore(childPughScorePage);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setChildPughScore(childPughScorePage);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Button
                  onClick={validateChildPugh}
                  // onClick={() => {
                  //   props.handleNext();
                  // }}
                  sx={{ mr: 2 }}
                  style={{
                    backgroundColor: "#800000",
                    borderRadius: "2px",
                    // width: "96px",
                    height: "32px",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "capitalize",
                    Padding: "6px 28px 6px 28px",
                    margin: "7px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  Preview & Submit
                </Button>
                <SideModal
                  isOpen={isOpen}
                  dismissPanel={dismissPanel}
                  header={"Preview"}
                />
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default Classification;

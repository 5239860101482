import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Autocomplete,
  Box,
  Stack,
  FormControl,
  TextareaAutosize,
  Typography,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  selectClasses,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import LocalStorage from "../../../../config/LocalStorage";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import isEmpty from "../../../../utils/isEmpty";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import SideModal from "./SideModal";
import ImageIcon from "@mui/icons-material/Image";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  formField: {
    color: " black !important",
  },
}));
const userFormDetails = {
  userType: "admin",
  reportId: "",
  hcpName: "",
  patientInfo: {
    name: "",
    mobileNo: "",
  },
  hcpInfo: {
    mobileNo: "",
    patientNumber: "",
  },
  status: "",
  active: true,
  isSuperAdmin: false,
  fname: "",
  lname: "",
  mobileNo: "",
  email: "",
  shopId: "",
  permission: null,
  password: "",
  scoreForCirrhosisMortality: {
    consentientLetterPdf: [],
    labReports: [],
    scripts: [],
    additionalMotivation: [],
  },
  remarks: "",
  surveyData: {},
};

function AEVForm(props) {
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(userFormDetails);
  const [modalOpen, setModalOpen] = useState(false);
  const [editAddress, setEditAddress] = useState({
    landmark: "",
    city: "",
    state: "",
    address: "",
    pincode: "",
    country: "",
  });
  // const [status, setStatus] = useState("pending");

  const handleChange = (event) => {
    setUserForm({
      ...userForm,
      status: event.target.value,
    });

    // setStatus(event.target.value);
  };

  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    }
  }, [props.page]);

  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    } else {
      setUserForm(userFormDetails);
    }
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getReportsDetails,
        JSON.stringify({ surveyId: props.rowDetails._id })
      ).then((response) => {
        console.log("editable responsesas", response.data.survey);

        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response.data.survey;
            userResult.dob = new Date(userResult.dob * 1000);

            let permissions = {};
            if (props.roles !== undefined && props.roles !== null) {
              props.roles.map((role) => {
                if (role._id === userResult.permission) {
                  permissions = role;
                }
              });
            }

            setUserForm({
              ...userForm,
              userType: userResult.userType,
              reportId: userResult.reportId,
              hcpName: userResult.hcpName,
              patientInfo: userResult.surveyData.patientInfo,
              hcpInfo: userResult.surveyData.hcpInfo,
              status: userResult.status,
              scoreForCirrhosisMortality:
                userResult.surveyData.scoreForCirrhosisMortality,
              active: userResult.active,
              isSuperAdmin: userResult.isSuperAdmin,
              fname: userResult.fname,
              lname: userResult.lname,
              mobileNo: userResult.mobileNo,
              email: userResult.email,
              shopId: userResult.shopId,
              permission: userResult.permission ? userResult.permission : null,
              password: userResult.password,
              surveyData: userResult.surveyData,
              remarks: userResult.remarks,
            });

            setSelectedRowId(userResult._id);
          }
        }
      });
    }
  };
  // console.log("thisis userform", userForm);
  //keyboard shortcut
  console.log("this is userform adadiuah", userForm);

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);
  // console.log("selected row id", selectedRowId);

  // const sendToServer = () => {
  //   let url = ConfigAPIURL.addUser;
  //   let method = "POST";

  //   if (
  //     props.formDialog.divType === "edit" ||
  //     props.formDialog.divType === "view"
  //   ) {
  //     url = ConfigAPIURL.updateReport;
  //     userForm["reportId"] = selectedRowId;
  //     userForm["status"] = userForm.status; // add status property
  //     method = "PUT";
  //   }
  //   let fieldValidation =
  //     userForm.userType === "user"
  //       ? LocalStorage.userDetails.isSuperAdmin
  //         ? []
  //         : []
  //       : LocalStorage.userDetails.isSuperAdmin
  //       ? props.formDialog.divType === "new"
  //         ? []
  //         : []
  //       : props.formDialog.divType === "new"
  //       ? [""]
  //       : [""];
  //   FormValidation.validation(fieldValidation, userForm).then(
  //     (ValidationResult) => {
  //       if (ValidationResult === true) {
  //         APIRequest.request(method, url, JSON.stringify(userForm)).then(
  //           (response) => {
  //             console.log(response, "this is fld validation result");
  //             if (response !== undefined && response !== null) {
  //               if (
  //                 response.code === 100 &&
  //                 response.data.responseCode === 109
  //               ) {
  //                 SnackbarUtils.sucess(
  //                   method === "POST"
  //                     ? props.t("snackbar.savedSuccess")
  //                     : props.t("snackbar.updatedSuccess"),
  //                   "bottomCenter",
  //                   3000
  //                 ).then((notification) => {
  //                   props.publishNotification(notification);
  //                 });
  //                 props.handleFormDialog();
  //                 setUserForm(userFormDetails);
  //                 setSelectedRowId("");
  //               }
  //               if (
  //                 response.code === 100 &&
  //                 response.data.responseCode === 114
  //               ) {
  //                 SnackbarUtils.error(
  //                   props.t("snackbar.dupliRecords"),
  //                   "bottomCenter",
  //                   3000
  //                 ).then((notification) => {
  //                   props.publishNotification(notification);
  //                 });
  //               }
  //             }
  //           }
  //         );
  //       }
  //     }
  //   );
  // };

  const sendToServer = (response) => {
    if (userForm.status === "rejected" && !userForm.remarks)
      return SnackbarUtils.error(
        props.t("snackbar.fillFields"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    APIRequest.request(
      "PUT",
      ConfigAPIURL.updateReport,
      JSON.stringify({
        reportId: selectedRowId,
        status: userForm.status,
        remarks: userForm.status === "rejected" ? userForm.remarks : "",
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          // getMyDraft();
          {
            props.handleFormDialog();
            setUserForm(userFormDetails);
            setSelectedRowId("");
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    });
    // setShowDeleteDialog(false);
  };

  const resetForms = () => {
    setEditAddress({
      ...editAddress,
      state: "",
      city: "",
      landmark: "",
      address: "",
      pincode: "",
      country: "",
    });
  };

  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          {/* <Paper className={classes.paper} elevation={1}> */}
          {/* <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="fname"
                  label={props.t("user.fname")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.fname}
                  fullWidth
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      fname: event.target.value,
                    })
                  }
                />
              </Grid> */}

          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            // className={classes.formGrid}
            style={{
              display: "flex",
              boxShadow:
                "0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 2px 14.4px rgba(0, 0, 0, 0.13)",
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            <Grid
              xl={6}
              lg={6}
              md={6}
              style={{ borderRight: "0.5px solid #F4F4F4" }}
            >
              <Grid>
                <h3
                  style={{
                    backgroundColor: "#800000",
                    color: "#fff",
                    padding: "10px",
                    marginTop: "0",
                    borderRadius: "10px 0 0 0",
                  }}
                >
                  Basic Details
                </h3>
                <div style={{ marginLeft: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    <p style={{ marginRight: "54px", padding: "0" }}>
                      Report ID{" "}
                    </p>
                    <span style={{ marginRight: "26px", padding: "0" }}>:</span>
                    <span> {userForm.reportId}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    <p style={{ marginRight: "42px", padding: "0" }}>
                      HCP Name{" "}
                    </p>
                    <span style={{ marginRight: "26px", padding: "0" }}>:</span>
                    <span> {userForm.hcpName}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    <p style={{ marginRight: "31px", padding: "0" }}>
                      Hcp Number{" "}
                    </p>
                    <span style={{ marginRight: "26px", padding: "0" }}>:</span>
                    <span> {userForm.hcpInfo.mobileNo}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    <p style={{ marginRight: "24px", padding: "0" }}>
                      Patient Name{" "}
                    </p>
                    <span style={{ marginRight: "26px", padding: "0" }}>:</span>
                    <span> {userForm.patientInfo.name}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "-15px",
                    }}
                  >
                    <p style={{ marginRight: "11px", padding: "0" }}>
                      Patient Number{" "}
                    </p>
                    <span style={{ marginRight: "26px", padding: "0" }}>:</span>
                    <span> {userForm.patientInfo.mobileNo}</span>
                  </div>
                  {/* <p>HCP Name : {userForm.hcpName} </p>
                  <p>Hcp Number :{userForm.hcpInfo.mobileNo} </p>
                  <p>Patient Name : {userForm.patientInfo.name}</p>
                  <p>Patient Number : {userForm.hcpInfo.patientNumber} </p> */}
                </div>
              </Grid>
              <Grid style={{ marginBottom: "0", paddingBottom: "0" }}>
                <h3
                  style={{
                    backgroundColor: "#800000",
                    color: "#fff",
                    padding: "10px",
                  }}
                >
                  Status
                </h3>
                <FormControl
                  component="fieldset"
                  style={{ marginLeft: "16px", marginBottom: "80px" }}
                >
                  <RadioGroup
                    row
                    aria-label="status"
                    name="status"
                    value={userForm.status}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="pending"
                      control={
                        <Radio style={{ color: "#FF5811" }} disableRipple />
                      }
                      label="Pending"
                    />
                    <FormControlLabel
                      value="received"
                      control={
                        <Radio
                          style={{ color: "#34CC55", marginLeft: "30px" }}
                          disableRipple
                        />
                      }
                      label="Receive"
                    />
                    <FormControlLabel
                      value="rejected"
                      control={
                        <Radio
                          style={{ color: "#FF0000", marginLeft: "30px" }}
                          disableRipple
                        />
                      }
                      label="Reject"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {userForm.status === "rejected" ? (
                <>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "-60px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <InputLabel
                      htmlFor="specialization"
                      style={{
                        color: "#1A1A1A",
                        marginBottom: "4px",
                        // fontWeight: "550",
                      }}
                    >
                      Remarks<span style={{ color: "#D13438" }}>*</span>
                    </InputLabel>
                    <TextareaAutosize
                      fullWidth
                      required
                      minRows="4"
                      id="remarks"
                      style={{ width: "100%" }}
                      // label="Specialization"
                      value={userForm.remarks}
                      onChange={(e) => {
                        setUserForm({
                          ...userForm,
                          remarks: e.target.value,
                        });
                      }}
                      InputProps={{
                        style: {
                          height: "44px", // adjust this value as needed
                          border: "1px solid #d9d9d9",
                          marginTop: "0",
                          marginBottom: "12px",
                        },
                      }}
                    />
                    {/* <p>hi ome owa ravi</p> */}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
            <Grid xl={6} lg={6} md={6}>
              <h3
                style={{
                  backgroundColor: "#800000",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "0 10px 0 0",
                  marginTop: "0",
                  marginLeft: "-2px",
                }}
              >
                Uploads
              </h3>
              <Grid style={{ display: "flex" }}>
                <Grid>
                  <Grid
                    style={{
                      boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                      width: "18vw",
                      // height: "126px",
                      minHeight: "130px",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      margin: "10px",
                      padding: "5px",
                      paddingTop: "0",
                    }}
                  >
                    <p
                      style={{
                        padding: "10px",
                        fontWeight: "600",
                        color: "#323130",
                      }}
                    >
                      consent Letter
                    </p>

                    {/* ////// */}
                    {userForm.scoreForCirrhosisMortality.consentientLetterPdf?.map(
                      (pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "10vw",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // margin: "10px",
                            }}
                          >
                            <span>
                              {pdfFile.toLowerCase().endsWith(".pdf") ? (
                                <PictureAsPdfIcon
                                  style={{ color: "red", marginRight: "5px" }}
                                />
                              ) : (
                                <ImageIcon
                                  style={{ color: "blue", marginRight: "5px" }}
                                />
                              )}
                              {/* <PictureAsPdfIcon
                                style={{ color: "red", marginRight: "5px" }}
                              /> */}
                            </span>
                            <span
                              className="pdf-file-name"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "120px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#000000",
                              }}
                            >
                              {pdfFile.split("_")[1]}
                            </span>
                          </Grid>
                          <Grid>
                            <span>
                              <VisibilityIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(pdfFile, "_blank");
                                }}
                              />
                              <DownloadIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                // onClick={() => {
                                //   fetch(pdfFile)
                                //     .then((response) => response.blob())
                                //     .then((blob) => {
                                //       saveAs(blob, "file.pdf");
                                //     });
                                // }}
                                onClick={() => {
                                  if (pdfFile.toLowerCase().endsWith(".pdf")) {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.pdf");
                                      });
                                  } else {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.jpg");
                                      });
                                  }
                                }}
                              />
                            </span>
                          </Grid>
                        </Grid>
                      )
                    )}

                    {/* //// */}
                  </Grid>
                  <Grid
                    style={{
                      boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                      width: "18vw",
                      minHeight: "130px",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      margin: "10px",
                      padding: "8px",
                      paddingTop: "0",
                    }}
                  >
                    <p
                      style={{
                        padding: "10px",
                        fontWeight: "600",
                        color: "#323130",
                      }}
                    >
                      Script
                    </p>
                    {userForm.scoreForCirrhosisMortality.scripts?.map(
                      (pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "10vw",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // margin: "10px",
                            }}
                          >
                            <span>
                              {pdfFile.toLowerCase().endsWith(".pdf") ? (
                                <PictureAsPdfIcon
                                  style={{ color: "red", marginRight: "5px" }}
                                />
                              ) : (
                                <ImageIcon
                                  style={{ color: "blue", marginRight: "5px" }}
                                />
                              )}
                              {/* <PictureAsPdfIcon
                                style={{ color: "red", marginRight: "5px" }}
                              /> */}
                            </span>
                            <span
                              className="pdf-file-name"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "120px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#000000",
                              }}
                            >
                              {pdfFile.split("_")[1]}
                            </span>
                          </Grid>
                          <Grid>
                            <span>
                              <VisibilityIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(pdfFile, "_blank");
                                }}
                              />
                              <DownloadIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                // onClick={() => {
                                //   fetch(pdfFile)
                                //     .then((response) => response.blob())
                                //     .then((blob) => {
                                //       saveAs(blob, "file.pdf");
                                //     });
                                // }}
                                onClick={() => {
                                  if (pdfFile.toLowerCase().endsWith(".pdf")) {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.pdf");
                                      });
                                  } else {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.jpg");
                                      });
                                  }
                                }}
                              />
                            </span>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid
                    style={{
                      boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                      width: "20vw",
                      // height: "268px",
                      minHeight: "130px",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      margin: "10px",
                      padding: "8px",
                      paddingTop: "0",
                    }}
                  >
                    <p
                      style={{
                        paddingLeft: "10px",
                        fontWeight: "600",
                        color: "#323130",
                      }}
                    >
                      Lab Report
                    </p>
                    {userForm.scoreForCirrhosisMortality.labReports?.map(
                      (pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "11vw",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              marginRight: "0",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // margin: "10px",
                            }}
                          >
                            <span>
                              {pdfFile.toLowerCase().endsWith(".pdf") ? (
                                <PictureAsPdfIcon
                                  style={{ color: "red", marginRight: "5px" }}
                                />
                              ) : (
                                <ImageIcon
                                  style={{ color: "blue", marginRight: "5px" }}
                                />
                              )}
                              {/* <PictureAsPdfIcon
                              style={{ color: "red", marginRight: "5px" }}
                            /> */}
                            </span>
                            <span
                              className="pdf-file-name"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "120px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#000000",
                              }}
                            >
                              {pdfFile.split("_")[1]}
                            </span>
                          </Grid>
                          <Grid>
                            <span
                              style={{ display: "flex", marginLeft: "2px" }}
                            >
                              <VisibilityIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(pdfFile, "_blank");
                                }}
                              />

                              <DownloadIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                // onClick={() => {
                                //   fetch(pdfFile)
                                //     .then((response) => response.blob())
                                //     .then((blob) => {
                                //       saveAs(blob, "file.pdf");
                                //     });
                                // }}
                                onClick={() => {
                                  if (pdfFile.toLowerCase().endsWith(".pdf")) {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.pdf");
                                      });
                                  } else {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.jpg");
                                      });
                                  }
                                }}
                              />
                            </span>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                  {/* ---------------------------------------- */}
                  <Grid
                    style={{
                      boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                      width: "20vw",
                      minHeight: "130px",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      margin: "10px",
                      padding: "8px",
                      paddingTop: "0",
                    }}
                  >
                    <p
                      style={{
                        padding: "10px",
                        fontWeight: "600",
                        color: "#323130",
                      }}
                    >
                      Additional Motivation
                    </p>
                    {userForm.scoreForCirrhosisMortality.additionalMotivation?.map(
                      (pdfFile, index) => (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Grid
                            key={index}
                            style={{
                              boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
                              width: "10vw",
                              height: "54px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              marginLeft: "10px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // margin: "10px",
                            }}
                          >
                            <span>
                              {pdfFile.toLowerCase().endsWith(".pdf") ? (
                                <PictureAsPdfIcon
                                  style={{ color: "red", marginRight: "5px" }}
                                />
                              ) : (
                                <ImageIcon
                                  style={{ color: "blue", marginRight: "5px" }}
                                />
                              )}
                              {/* <PictureAsPdfIcon
                                style={{ color: "red", marginRight: "5px" }}
                              /> */}
                            </span>
                            <span
                              className="pdf-file-name"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "120px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#000000",
                              }}
                            >
                              {pdfFile.split("_")[1]}
                            </span>
                          </Grid>
                          <Grid>
                            <span>
                              <VisibilityIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(pdfFile, "_blank");
                                }}
                              />
                              <DownloadIcon
                                style={{
                                  color: "#000",
                                  marginRight: "5px",
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: "6px",
                                  width: "38px",
                                  height: "38px",
                                  padding: "6px",
                                  cursor: "pointer",
                                }}
                                // onClick={() => {
                                //   fetch(pdfFile)
                                //     .then((response) => response.blob())
                                //     .then((blob) => {
                                //       saveAs(blob, "file.pdf");
                                //     });
                                // }}
                                onClick={() => {
                                  if (pdfFile.toLowerCase().endsWith(".pdf")) {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.pdf");
                                      });
                                  } else {
                                    fetch(pdfFile)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        saveAs(blob, "file.jpg");
                                      });
                                  }
                                }}
                              />
                            </span>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* ---- */}
            </Grid>
          </Grid>
          {/* </Paper> */}
        </Grid>
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);

import React, { useContext, useState } from "react";
import { FormGroup, FormControlLabel, Switch, Box, Paper } from "@mui/material";
import { withTranslation } from "react-i18next";
import { ThemeContext } from "../../themeProvider/ThemeProvider";

function ThemeSetting() {
	const curThemeName = localStorage.getItem("adminTheme") || "LIGHT";
	const [themeName, _setThemeName] = useState(curThemeName);
	const setThemeName = useContext(ThemeContext);
	const [state, setState] = React.useState({
		themeDark: themeName === "LIGHT" ? false : true,
	});

	const handleThemeChange = (event) => {
		if (event.target.checked) {
			_setThemeName("DARK");
			setThemeName("DARK");
		} else {
			_setThemeName("LIGHT");
			setThemeName("LIGHT");
		}
		setState({ ...state, [event.target.name]: event.target.checked });
	};
	return (
		<Paper elevation={3}>
			<Box pl={3} pr={3} pt={2} pb={1}>
				Dark theme turns the light surfaces of the page dark, creating an experience ideal for night. Try it out! Your Dark theme setting will apply
				to this browser only.
			</Box>
			<Box pl={3} pr={3} pt={1} pb={2}>
				<FormGroup row>
					<FormControlLabel
						control={<Switch checked={state.themeDark} onChange={handleThemeChange} name="themeDark" color="primary" />}
						label="Dark"
					/>
				</FormGroup>
			</Box>
		</Paper>
	);
}
export default withTranslation("translations")(ThemeSetting);

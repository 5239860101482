import React, { Component, Suspense } from "react";
import General from "./components/general";
import AdminIndex from "./components/privateRouting/admin";
import HcpRoute from "./components/privateRouting/hcp/HcpRoute";
import HcpGeneralRoute from "./components/general/hcp/HcpGeneralRoute";

import PrivateRouteValidation from "./PrivateRouteValidation"; // for restricted access route
import PrivateRoute from "./HcpPrivateRouteValidation";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./components/general/hcp/Login"

export default class App extends Component {
  render() {
    return (
      <div style={rootStyle}>
        <HashRouter>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/hcp/" />}
            />
            <Route exact path={["/hcp"]} component={HcpGeneralRoute} />
            <Route exact path={["/hcp/login"]} component={Login} />
            

            <Route
              exact
              path={["/", "/login", "/hcp/password/generate"]}
              component={General}
            />

            <PrivateRouteValidation path={["/admin"]} component={AdminIndex} />
            <PrivateRoute path={["/hcp"]} component={HcpRoute} />
          </Switch>
        </HashRouter>
        <ToastContainer />
      </div>
    );
  }
}
const rootStyle = {
  overflowX: "hidden",
};

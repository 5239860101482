import React, { lazy, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
// const User = React.lazy(() => {
// 	import("./user"); IKARD00160
// });
// const Role = React.lazy(() => {
// 	import("./role");
// });
// const Settings = React.lazy(() => {
// 	import("./setting");
// });
import User from "./user";
import Role from "./role";
import Settings from "./setting";
import Reports from "./report";
import Profile from "./profile";

function AdminContentRouting(props) {
  return (
    <Switch>
      <Route
        exact
        path={["/admin"]}
        render={() => <Redirect to="/admin/users" />}
      />
      <Route exact path={["/admin/users"]} component={User} />
      <Route exact path={["/admin/setting"]} component={Settings} />
      <Route exact path={["/admin/reports"]} component={Reports} />
      <Route exact path={["/admin/security/role"]} component={Role} />
      <Route exact path={["/admin/profile"]} component={Profile} />
      <Route render={() => <h3>Coming soon.</h3>} />
    </Switch>
  );
}
export default withTranslation("translations")(AdminContentRouting);

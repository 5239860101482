import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Paper, TextField, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "200px",
    maxWidth: "250px",
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formField: {
    "& .MuiInputLabel-shrink": {
      color: "black !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

function TableQuery(props) {
  const classes = useStyles();
  const [shopFilterOptions, setShopFilterOptions] = useState([]);

  useEffect(() => {
    getShopFilters();
  }, []);

  const getShopFilters = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.shopFilters,
      JSON.stringify({ active: true })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        console.log(response.data);
        if (response.code === 100 && response.data.responseCode === 109) {
          setShopFilterOptions(response.data.options);
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.activeStatus")}
              select
              fullWidth
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              className={classes.formField}
              style={{ marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t("query.active")}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t("query.inactive")}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t("query.userType")}
              select
              fullWidth
              defaultValue={props.query.userType}
              value={props.query.userType}
              className={classes.formField}
              onChange={(event) =>
                props.setQuery({ ...props.query, userType: event.target.value })
              }
              style={{ marginBottom: 10 }}
            >
              <MenuItem key={0} value={"all"}>
                {props.t("query.all")}
              </MenuItem>
              <MenuItem key={1} value={"hcp"}>
                {props.t("query.hcp")}
              </MenuItem>
              <MenuItem key={1} value={"admin"}>
                {props.t("query.admin")}
              </MenuItem>
            </TextField>
          </Grid>
          {/* {LocalStorage.userDetails.isSuperAdmin ? (
						<Grid xl={12} lg={12} md={12} sm={12} xs={12}>
							<TextField
								label={props.t("query.shopName")}
								select
								fullWidth
								defaultValue={props.query.shopName}
								value={props.query.shopName}
								onChange={(event) => props.setQuery({ ...props.query, shopName: event.target.value })}
								style={{ marginBottom: 10 }}
							>
								<MenuItem key={0} value={"all"}>
									{props.t("query.all")}
								</MenuItem>
								{shopFilterOptions.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.title}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					) : null} */}
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);

import {
  Document,
  Page,
  Text,
  Font,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
    backgroundColor: "#fff",
  },
  section: {
    marginTop: 50,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
  imageCover: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  section1: {
    marginTop: 60,
    textAlign: "center",
    fontSize: 34,
    fontWeight: "bold",
  },
  image: {
    objectFit: "cover",
  },
  gridContainer: {
    flexDirection: "row",
    marginBottom: 3,
    backgroundColor: "#e5e5e5",
    padding: 3,
  },
  tableText: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    paddingLeft: 6,
    fontFamily: "Opensans",
  },
  tableRow: {
    fontSize: 12,
    textAlign: "center",
    paddingLeft: 6,
    fontFamily: "Opensans",
  },
  border: {
    border: "1px solid grey",
    marginTop: 8,
    marginBottom: 4,
  },
  flex: {
    flexDirection: "row",
  },
});

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { useRef, useCallback } from "react";
import { ContextualMenu } from "@fluentui/react/lib/ContextualMenu";
import {
  CommandBarButton,
  DialogFooter,
  Dropdown,
  Popup,
  SearchBox,
} from "@fluentui/react";
import ViewColumn from "./ViewColumn";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { Link, useHistory } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import {
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
} from "@fluentui/react";
import { EditIcon, DeleteIcon } from "@fluentui/react-icons-mdl2";
import FilterOption from "./FilterOption";
import deleteIcon from "../../../../assets/images/delete.png";
// import { Dropdown, DatePicker, Popup } from "@fluentui/react";
import {
  IPersonaSharedProps,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
} from "@fluentui/react/lib/Persona";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "-35px",
  },
  searchBox: {
    width: "35%",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "35%",
    },
  },
  //   appBar: {
  //     [theme.breakpoints.up("md")]: {
  //       width: `calc(100% - ${drawerWidth}px)`,
  //       marginLeft: drawerWidth,
  //     },
  //     backgroundImage: theme.custom.topHeader.toolbarColor,
  //   },
}));
// function Dialogs() {

// return (

// );
// }

function Drafts(props) {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);
  const [draft, setDraft] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [draftIdToDelete, setDraftIdToDelete] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDateInSeconds, setFromDateInSeconds] = useState("");
  const [toDateInSeconds, setToDateInSeconds] = useState("");
  const columnOptionsRef = useRef();
  const popoverRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnOptionsRef.current &&
        !columnOptionsRef.current.contains(event.target) &&
        !event.target.closest("#view-column-button")
      ) {
        setIsColumnOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [columnOptionsRef]);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (popoverRef.current && !popoverRef.current.contains(event.target)) {
  //       setShowPopover(false);
  //     }
  //   };

  //   window.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     window.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [popoverRef]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  // console.log("this is daraffaff ", draft);

  const handleSave = () => {
    const fromDateInSeconds = fromDate
      ? Math.floor(fromDate.getTime() / 1000)
      : null;
    const toDateInSeconds = toDate ? Math.floor(toDate.getTime() / 1000) : null;
    setFromDateInSeconds(fromDateInSeconds);
    setToDateInSeconds(toDateInSeconds);
    getMyDraft();
    setShowPopover(false);
  };

  const handleFilterClick = () => {
    setShowPopover((prevState) => !prevState);
  };

  const handleDeleteCancel = () => {
    setShowDeleteDialog(false);
  };
  const handleReset = () => {
    setFromDateInSeconds("");
    setToDateInSeconds("");
    setFromDate(null);
    setToDate(null);
    setShowPopover(false);
  };

  useEffect(() => {
    getMyDraft();
  }, [fromDateInSeconds, toDateInSeconds]);

  const getMyDraft = () => {
    const url = `${ConfigAPIURL.myDraft}?startDate=${fromDateInSeconds}&endDate=${toDateInSeconds}`;

    APIRequest.request("GET", url, "").then((response) => {
      console.log("draft table data", response);
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setDraft(response.data.drafts);
        }
      }
    });
  };

  const deleteDraft = (response) => {
    APIRequest.request(
      "DELETE",
      ConfigAPIURL.deleteDraft,
      JSON.stringify({ surveyId: draftIdToDelete })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          getMyDraft();
        }
      }
    });
    setShowDeleteDialog(false);
  };

  const Popover = (props) => {
    const linkRef = useRef(null);
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const onShowContextualMenu = useCallback((ev) => {
      ev.preventDefault();
      setShowContextualMenu(true);
    }, []);

    const onHideContextualMenu = useCallback(
      () => setShowContextualMenu(false),
      []
    );

    return (
      <div>
        <p style={{ marginTop: "0" }}>
          <b>
            <div
              ref={linkRef}
              onClick={onShowContextualMenu}
              style={{
                cursor: "pointer",
                display: "inline-block",
                width: "20px",
              }}
            >
              <div
                style={{
                  height: "2px",
                  width: "2px",
                  borderRadius: "50%",
                  backgroundColor: "#868686",
                  margin: "2px auto",
                }}
              ></div>
              <div
                style={{
                  height: "2px",
                  width: "2px",
                  borderRadius: "50%",
                  backgroundColor: "#868686",
                  margin: "2px auto",
                }}
              ></div>
              <div
                style={{
                  height: "2px",
                  width: "2px",
                  borderRadius: "50%",
                  backgroundColor: "#868686",
                  margin: "2px auto",
                }}
              ></div>
            </div>
          </b>
        </p>
        <ContextualMenu
          items={props.menuItems}
          hidden={!showContextualMenu}
          target={linkRef}
          onItemClick={onHideContextualMenu}
          onDismiss={onHideContextualMenu}
          className="contextmenu"
          styles={{
            container: { width: "100px" }, // Set the width of the container element
            list: { width: "100px" }, // Set the width of the list element inside the menu
          }}
        />
      </div>
    );
  };
  // const examplePersona: IPersonaSharedProps = {
  //   secondaryText: "Designer",
  //   tertiaryText: "In a meeting",
  //   optionalText: "Available at 4:00pm",
  // };

  const columns = [
    {
      key: "column1",
      name: "Patient Name",
      fieldName: "patientName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => (
        <Persona
          // {...examplePersona}
          text={item.patientName}
          size={PersonaSize.size24}
        />
      ),
    },

    {
      key: "column6",
      name: "",
      fieldName: "",
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      data: "number",
      onRender: (item) => {
        const menuItems = [
          {
            key: "Edit",
            text: "Edit",

            iconProps: {
              iconName: "Edit",
              // styles: { color: "#800000 !important" },
              // iconType: EditIcon,
            },
            onClick: () => history.push(`/hcp/create/${item._id}`),
          },
          {
            key: "Delete",
            text: "Delete",

            iconProps: {
              iconName: "Delete",
              // styles: { color: "#800000 !important" },
              // iconType: deleteIcon,
            },
            // iconProps: {
            //   iconType: deleteIcon,
            // },

            // onClick: () => delete([item._id]),
            onClick: () => {
              setDraftIdToDelete(item._id);
              setShowDeleteDialog((prevState) => !prevState);
            },
          },
        ];

        return (
          <span
            style={{
              cursor: "pointer",
              fontWeight: 400,
              fontSize: "12px",
              color: "#323130",
              width: "10px",
            }}
          >
            <Popover menuItems={menuItems}></Popover>
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "column2",
      name: "Stage",
      fieldName: "stage",
      minWidth: 70,
      maxWidth: 80,
      isResizable: true,
      onRender: (item) => {
        return item.stage
          ? Object.values(item.stage).filter((value) => value === true).length +
              "/12"
          : 0 + "/12";
      },
    },
    {
      key: "column3",
      name: "Created At",
      fieldName: "createdAt",
      minWidth: 150,
      maxWidth: 160,
      isResizable: true,
      onRender: (item) => {
        return new Date(item.createdAt * 1000).toDateString();
      },
    },
    {
      key: "column4",
      name: "Updated Date",
      fieldName: "updatedAt",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item) => {
        return new Date(item.updatedAt * 1000).toDateString();
      },
      //   isResizable: true,
    },
  ];
  const [filteredItems, setFilteredItems] = useState(draft);
  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.map((c) => c.key)
  );
  // console.log("this is draft item", draft);
  const onSearch = (newValue) => {
    const filtered = draft.filter((item) =>
      item.patientName.toLowerCase().includes(newValue.toLowerCase())
    );
    // console.log("this is filtered item", filtered);
    setFilteredItems(filtered);
    setSearchValue(newValue);
    // setDraft(filtered);
  };
  const handleColumnOptionsClick = () => {
    setIsColumnOptionsOpen((prevState) => !prevState);
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        style={{
          boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
          padding: "10px",
          paddingBottom: "30px",
          width: "96%",
          borderRadius: "8px",
        }}
      >
        <h1 style={{ color: "#323130" }}>My Drafts</h1>
        <Grid
          style={{
            width: "98%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <SearchBox
              placeholder="Search Drafts by Name"
              value={searchValue}
              onChange={(e) => {
                if (e) {
                  onSearch(e.target.value);
                } else {
                  onSearch("");
                }
              }}
              onClear={() => onSearch("")}
              styles={{
                root: {
                  // width: "35%",
                  border: "none",
                  boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                  margin: "10px 0",
                  borderRadius: "5px",
                },
                field: { "::placeholder": { color: "#868686" } },
              }}
              className={classes.searchBox}
            />
            <div style={{ position: "relative" }}>
              <div
                onClick={handleColumnOptionsClick}
                id="view-column-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ViewColumnIcon
                  style={{
                    color: "#800000",
                    marginRight: "8px",
                    marginLeft: "12px",
                  }}
                />
                <p
                  style={{
                    fontSize: "14px",
                  }}
                >
                  View Column
                </p>
              </div>
              {/* <button onClick={handleColumnOptionsClick}>hii</button> */}
              <div
                ref={columnOptionsRef}
                style={{
                  zIndex: "9999",
                  position: "absolute",
                  backgroundColor: "white",
                  boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                  right: "10px",
                }}
              >
                {isColumnOptionsOpen && (
                  <ViewColumn
                    isColumnOptionsOpen={isColumnOptionsOpen}
                    setIsColumnOptionsOpen={setIsColumnOptionsOpen}
                    columnHeader={columns}
                    setVisibleColumns={setVisibleColumns}
                    visibleColumns={visibleColumns}
                  />
                )}
              </div>
            </div>
            {/* -----------------filter option------------------- */}
            <div
              style={{
                position: "relative",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <div
                onClick={handleFilterClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <FilterListIcon
                  style={{
                    color: "#800000",
                    marginRight: "8px",
                    marginLeft: "12px",
                  }}
                />
                <p
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Filter
                </p>
              </div>
              {/* <DefaultButton
                text="Filter"
                onClick={handleFilterClick}
                style={{ border: "none" }}
              /> */}
              <div
                ref={popoverRef}
                style={{
                  zIndex: "1",
                  position: "absolute",
                  backgroundColor: "white",
                  boxShadow: "0px 1px 5px rgba(109, 109, 109, 0.25)",
                  right: "10px",
                }}
              >
                {showPopover && (
                  <FilterOption
                    showPopover={showPopover}
                    setShowPopover={setShowPopover}
                    handleFromDateChange={handleFromDateChange}
                    handleToDateChange={handleToDateChange}
                    handleSave={handleSave}
                    fromDate={fromDate}
                    toDate={toDate}
                    handleReset={handleReset}
                  />
                )}
              </div>
            </div>
            {/* ---- */}
          </div>
          {draft.length > 0 ? (
            <DetailsList
              columns={columns.filter((c) => visibleColumns.includes(c.key))}
              items={searchValue.length > 0 ? filteredItems : draft}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  color: "#323130",
                  letterSpacing: "2px",
                  fontSize: "18px",
                }}
              >
                No Drafts to Show Here.
              </p>
            </div>
          )}
        </Grid>
      </Grid>
      {/* ---- */}
      <div>
        <Dialog
          hidden={!showDeleteDialog}
          onDismiss={handleDeleteCancel}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Confirm Delete",
            subText: "Do you want to delete this draft?",
          }}
          modalProps={{
            isBlocking: true,
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={deleteDraft}
              style={{ backgroundColor: "#800000", border: "none" }}
              text="Yes"
            />
            <DefaultButton onClick={handleDeleteCancel} text="No" />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
}
// const mapStateToProps = (state) => {
//   return {
//     shortcutKeyValue: state.shortcutKeyValue,

//     sideDrawerData: state.sideDrawerData,
//   };
// };
// const mapDispachToProps = (dispatch) => {
//   return {
//     shortcutKey: (shortcutKeyValue) =>
//       dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
//     publishNotification: (notification) =>
//       dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
//   };
// };
export default Drafts;

import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { AppContext } from "../AppState";
import { useEffect } from "react";
// import { DatePicker, defaultDatePickerStrings } from "@fluentui/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const OtherMedications = (props) => {
  const { medicationRows, setMedicationRows,OtherMedication } = props;

  // const [medicationRows, setMedicationRows] = useState([{}, {}, {}]);

  const handleAddMedication = () => {
    setMedicationRows([
      ...medicationRows,
      {
        medication: "",
        dose: "",
        condition: "",
      },
    ]);
  };
  const handleMedicationChange = (index, field, value) => {
    const newRows = [...medicationRows];
    newRows[index][field] = value;
    setMedicationRows(newRows);
  };

  const handleRemoveMedication = (index) => {
    setMedicationRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(index, 1);
      return newRows;
    });
  };



  //   useEffect(() => {
  //     // Simulate fetching data from a database
  //     const fetchData = async () => {
  //       const response = await fetch("https://example.com/medications");
  //       const data = await response.json();
  //       setMedicationRows(data);
  //     };

  //     fetchData();
  //   }, []);

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                minWidth:'72vw'
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                Other Medications for Stroke Risk
              </h2>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  boxShadow:
                    " 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)",
                  padding: "15px",
                  paddingBottom: "30px",
                  width: "96%",
                  borderRadius: "8px",
                  paddingLeft:"0",
                  paddingRight:"0",

                  
                  // margin: "10px",
                }}
              >
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Grid
                    xs={12}
                    style={{
                      paddingLeft: "12px",
                      alignItems: "right",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      onClick={handleAddMedication}
                      style={{
                        color: "#fff",
                        backgroundColor: "#800000",
                        height: "32px",
                        width: "12px",
                        marginBottom: "8px",
                        marginRight:'10px'
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                </Hidden>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    backgroundColor: "#FAFAFA",
                    marginTop: "-15px",
                    alignItems: "center",
                    
                  }}
                >
                  <Hidden only={["xs"]}>
                    <div style={{display:'flex',width:"100%",}}>
                    
                    <Grid xl={4} lg={4} md={4} sm={4.5} style={{ paddingLeft: "14px",fontWeight:'600' }}>
                      <p style={{fontSize:"15px"}}>Medication</p>
                    </Grid>
                    <Grid xl={4} lg={4} md={4}sm={3} style={{ paddingLeft: "6px",fontWeight:'600' }}>
                      <p  style={{fontSize:"15px"}}>Dose</p>
                    </Grid>
                    <Grid xl={3} lg={3} md={4}sm={2.5} style={{ paddingLeft: "6px",fontWeight:'600' }}>
                      <p  style={{fontSize:"15px"}}>Condition</p>
                    </Grid>
                    <Grid xl={0.5} lg={0.5} style={{ paddingLeft: "8px" }}>
                      <Button
                        onClick={handleAddMedication}
                        style={{
                          color: "#fff",
                          backgroundColor: "#800000",
                          height: "32px",
                          width: "32px",
                          marginTop:"6px"
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                    </div>
                  </Hidden>
                  <Hidden only={["lg", "sm", "xl", "md"]}>
                    
                    <Grid xs={4} style={{ paddingLeft: "8px" }}>
                      <p>Medication</p>
                    </Grid>
                    <Grid xs={3} style={{ paddingLeft: "8px" }}>
                      <p>Dose</p>
                    </Grid>
                    <Grid xs={4} style={{ paddingLeft: "8px" }}>
                      <p>Condition</p>
                    </Grid>
                    {/* <Grid xl={0.5} lg={0.5} style={{ paddingLeft: "8px" }}>
                    <Button
                      onClick={handleAddMedication}
                      style={{
                        color: "#fff",
                        backgroundColor: "#0078D4",
                        height: "22px",
                        width: "12px",
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </Grid> */}
                  </Hidden>
                </Grid>
                {medicationRows.length > 0 &&
                  medicationRows.map((medication, index) => (
                    <Grid
                      key={index}
                      container
                      spacing={1}
                      style={{ padding: 5, alignItems: "center" }}
                    >
                      <Grid item xs={4} xl={4} lg={4} md={4}style={{ paddingLeft: "14px" }}>
                        <TextField
                          fullWidth
                          placeholder="Type Here"
                          value={medication.medication}
                          onChange={(e) =>
                            handleMedicationChange(
                              index,
                              "medication",
                              e.target.value
                            )
                          }
                          InputProps={{
                            style: {
                              height: "44px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} xl={4} lg={4} md={4}>
                        <TextField
                          fullWidth
                          placeholder="Type Here"
                          value={medication.dose}
                          onChange={(e) =>
                            handleMedicationChange(
                              index,
                              "dose",
                              e.target.value
                            )
                          }
                          InputProps={{
                            style: {
                              height: "44px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} xl={3} lg={3} md={3}>
                        <TextField
                          fullWidth
                          placeholder="Type Here"
                          value={medication.condition}
                          onChange={(e) =>
                            handleMedicationChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                          InputProps={{
                            style: {
                              height: "44px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          style={{
                            height: "32px",
                            width: "32px",
                            color: "#323130",
                          }}
                          onClick={() => handleRemoveMedication(index)}
                        >
                          <RemoveIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <p style={{color:'c1c1c1',fontSize:'14px'}}>The patient has / is being treated on other medications (listed above) for other conditions
(listed above). These drugs may reduce the efficacy of the drug covered on your formulary, and
may result in inadequate treatment and / or stroke in the patient.
</p>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setMedicationRows(OtherMedication);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setMedicationRows(OtherMedication);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={props.handleNext}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={props.handleNext}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default OtherMedications;

import React from "react";
import SecurityIcon from "@mui/icons-material/Security";
import FilterListIcon from "@mui/icons-material/FilterList";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import StorageIcon from "@mui/icons-material/Storage";
import CategoryIcon from "@mui/icons-material/Category";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AddIcon from "@mui/icons-material/Add";
import HistoryIcon from "@mui/icons-material/History";
import DraftsIcon from "@mui/icons-material/Drafts";
import History from "../../../../assets/images/History.png";
import MyDrafts from "../../../../assets/images/MyDrafts.png";
import Setting from "../../../../assets/images/settings.png";

// import EditNoteIcon from "@mui/icons-material/EditNote";

class SideTopBar {
  static menuForAdmin = [
    {
      display: "sideNavMenu.drafts",
      link: "/hcp/mydrafts",
      linkPage: false,
      onclick: null,
      urlEndWith: "drafts",
      className: "",
      permissionLevel: "",
      // icon: <DraftsIcon style={{ marginRight: "16px" }} />,
      icon: (
        <img
          src={MyDrafts}
          alt="Logo"
          style={{ height: "24px", width: "24px" }}
        />
      ),
      eventKey: "drafts",
      subMenu: [],
    },

    {
      display: "sideNavMenu.history",
      link: "/hcp/history",
      linkPage: false,
      onclick: null,
      urlEndWith: "reports",
      className: "",
      permissionLevel: "",
      icon: (
        <img
          src={History}
          alt="Logo"
          style={{ height: "24px", width: "24px" }}
        />
      ),
      // icon: <HistoryIcon style={{ marginRight: "16px" }} />,
      eventKey: "reports",
      subMenu: [],
    },

    {
      display: "sideNavMenu.setting",
      link: "/hcp/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      // icon: <SettingsIcon style={{ marginRight: "16px" }} />,
      icon: (
        <img
          src={Setting}
          alt="Logo"
          style={{ height: "24px", width: "24px" }}
        />
      ),
      eventKey: "setting",
      subMenu: [],
    },
    // {
    //   display: "sideNavMenu.createNewSurvey",
    //   link: "/hcp/create",
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: "create",
    //   className: "",
    //   permissionLevel: "",
    //   icon: <AddIcon />,
    //   eventKey: "create",
    //   subMenu: [],
    // },
  ];
}
export default SideTopBar;

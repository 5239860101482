import React, { useEffect, lazy, Suspense } from "react";

import { withTranslation } from "react-i18next";

import { HashRouter, Switch, Route } from "react-router-dom";

// const Login = lazy(() => import("./Login"));


const LoginLanding = lazy(() => import("./LoginLanding"));

function HcpGeneralRoute(props) {
  return (
    <div className={"bg-light"}>
      <Suspense fallback={<div>Loading</div>}>
        <HashRouter>
          <Switch>
          <Route exact path={["/hcp"]} component={LoginLanding} />
            {/* <Route exact path={["/hcp/login"]} component={Login} /> */}
          </Switch>
        </HashRouter>
      </Suspense>
    </div>
  );
}

export default withTranslation("translations")(HcpGeneralRoute);

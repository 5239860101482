import React, { useState, useContext } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  TextField,
} from "@mui/material";

import { AppContext } from "../AppState";
import { useEffect } from "react";
// import { DatePicker, defaultDatePickerStrings } from "@fluentui/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormValidation from "../../../../utils/FormValidation";

const PatientExamResult = (props) => {
  const { examResult, setExamResult,patientExamResult } = props;
  const validateExamResult = () => {
    let fieldValidation = [
      "heartRate",
      // "email",
      "bloodPressure",
      // "mobileNo2",
      "weight",
      "age",
      "hba1c",
      // "address",
    ];
    FormValidation.validation(fieldValidation, examResult).then(
      (ValidationResult) => {
        if (ValidationResult === true) {
          props.handleNext();
        }
      }
    );
  };

  return (
    <div>
      <Grid container>
        <Grid>
          {/* // <Grid
            // // style={{ marginLeft: "-14px", marginRight: "auto" }}
            // > */}
          <>
            <Grid
              style={{
                boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                paddingBottom: "30px",
                width: "96%",
                borderRadius: "8px",
                // margin: "10px",
              }}
            >
              <h2
                style={{
                  color: "#323130",
                  fontSize: "28px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  marginTop:'4px'
                }}
              >
                Patient’s Examination Results
              </h2>
              <Grid
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  boxShadow:
                    " 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)",
                  padding: "15px",
                  paddingTop:'0',
                  paddingLeft:'0',
                  paddingRight:'0',
                  paddingBottom: "30px",
                  width: "96%",
                  borderRadius: "8px",
                  // margin: "10px",
                }}
              >
                {/* <Hidden only={["xs"]}> */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    backgroundColor: "#FAFAFA",
                    // marginTop: "-13px",
                    // marginLeft: "-13px",
                  }}
                >
                  <Hidden only={["xs"]}>
                    <Grid
                      xl={10}
                      lg={10}
                      md={8}
                      sm={8}
                      style={{ paddingLeft: "18px",fontWeight:'600' }}
                    >
                      <p>Risk</p>
                    </Grid>
                  </Hidden>
                  <Hidden only={["lg", "sm", "xl", "md"]}>
                    <Grid
                      xl={10}
                      lg={10}
                      md={8}
                      xs={9.5}
                      sm={10}
                      style={{ paddingLeft: "16px" }}
                    ></Grid>
                  </Hidden>
                  <Grid
                    xl={0.8}
                    lg={0.8}
                    md={2}
                    xs={3.6}
                    sm={1.8}
                    style={{ paddingLeft: "10px",fontWeight:'600' }}
                  >
                    <p>Yes</p>
                  </Grid>
                  <Grid
                    xl={1}
                    lg={1}
                    md={4}
                    xs={1.5}
                    sm={2}
                    style={{ paddingLeft: "0",marginLeft:'0',fontWeight:'600' }}
                  >
                    <p>No</p>
                  </Grid>
                </Grid>
                {/* </Hidden> */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center" xs={12}>
                    <Grid item xs={12} lg={2} xl={2} style={{ paddingLeft: "14px" }}>
                      <InputLabel
                        htmlFor="bloodPressure"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        Blood pressure
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <TextField
                        placeholder="Type here"
                        fullWidth
                        id="bloodPressure"
                        value={examResult.bloodPressure}
                        onChange={(e) => {
                          setExamResult({
                            ...examResult,
                            bloodPressure: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "44px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: "flex" }}>
                      <Checkbox
                        style={{
                          color: "#800000",
                          transform: "scale(1.8)",
                          marginRight: "14px",
                        }}
                        disableRipple
                        checked={
                          examResult.bloodPressureCheckbox === "Yes"
                            ? true
                            : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // bloodPressureCheckbox: "Yes",
                            bloodPressureCheckbox:
                            examResult.bloodPressureCheckbox === "Yes" ? "" : "Yes",
                          });
                        }}
                      />

                      <Checkbox
                        style={{ color: "#800000", transform: "scale(1.8)" }}
                        disableRipple
                        checked={
                          examResult.bloodPressureCheckbox === "No"
                            ? true
                            : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // bloodPressureCheckbox: "No",
                            bloodPressureCheckbox:
                            examResult.bloodPressureCheckbox === "No" ? "" : "No",
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ///////// */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={2} xl={2} style={{ paddingLeft: "14px" }}>
                      <InputLabel
                        htmlFor="heartRate"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        Heart rate
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <TextField
                        placeholder="Type here"
                        fullWidth
                        id="heartRate"
                        value={examResult.heartRate}
                        onChange={(e) => {
                          setExamResult({
                            ...examResult,
                            heartRate: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "44px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: "flex" }}>
                      <Checkbox
                        style={{
                          color: "#800000",
                          transform: "scale(1.8)",
                          marginRight: "14px",
                        }}
                        disableRipple
                        checked={
                          examResult.heartRateCheckbox === "Yes" ? true : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // heartRateCheckbox: "Yes",
                            heartRateCheckbox:
                            examResult.heartRateCheckbox === "Yes" ? "" : "Yes",
                          });
                        }}
                      />

                      <Checkbox
                        style={{ color: "#800000", transform: "scale(1.8)" }}
                        disableRipple
                        checked={
                          examResult.heartRateCheckbox === "No" ? true : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // heartRateCheckbox: "No",
                            heartRateCheckbox:
        examResult.heartRateCheckbox === "No" ? "" : "No",
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={2} xl={2} style={{ paddingLeft: "14px" }}>
                      <InputLabel
                        htmlFor="weight"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        Weight
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <TextField
                        placeholder="Type here"
                        fullWidth
                        id="weight"
                        value={examResult.weight}
                        onChange={(e) => {
                          setExamResult({
                            ...examResult,
                            weight: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "44px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: "flex" }}>
                      <Checkbox
                        style={{
                          color: "#800000",
                          transform: "scale(1.8)",
                          marginRight: "14px",
                        }}
                        disableRipple
                        checked={
                          examResult.weightCheckbox === "Yes" ? true : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // weightCheckbox: "Yes",
                            weightCheckbox:
                            examResult.weightCheckbox === "Yes" ? "" : "Yes",
                          });
                        }}
                      />

                      <Checkbox
                        style={{ color: "#800000", transform: "scale(1.8)" }}
                        disableRipple
                        checked={
                          examResult.weightCheckbox === "No" ? true : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // weightCheckbox: "No",
                            weightCheckbox:
                            examResult.weightCheckbox === "No" ? "" : "No",
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={2} xl={2} style={{ paddingLeft: "14px" }}>
                      <InputLabel
                        htmlFor="age"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        Age
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <TextField
                        placeholder="Type here"
                        fullWidth
                        id="age"
                        value={examResult.age}
                        onChange={(e) => {
                          setExamResult({
                            ...examResult,
                            age: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "44px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: "flex" }}>
                      <Checkbox
                        style={{
                          color: "#800000",
                          transform: "scale(1.8)",
                          marginRight: "14px",
                        }}
                        disableRipple
                        checked={
                          examResult.ageCheckbox === "Yes" ? true : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // ageCheckbox: "Yes",
                            ageCheckbox: examResult.ageCheckbox === "Yes" ? "" : "Yes",

                          });
                        }}
                      />

                      <Checkbox
                        style={{ color: "#800000", transform: "scale(1.8)" }}
                        disableRipple
                        checked={examResult.ageCheckbox === "No" ? true : false}
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // ageCheckbox: "No",
                            ageCheckbox: examResult.ageCheckbox === "No" ? "" : "No",

                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={2} xl={2} style={{ paddingLeft: "14px" }}>
                      <InputLabel
                        htmlFor="hba1c"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        HbA1c
                        <span style={{ color: "#D13438" }}>*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <TextField
                        placeholder="Type here"
                        fullWidth
                        id="hba1c"
                        value={examResult.hba1c}
                        onChange={(e) => {
                          setExamResult({
                            ...examResult,
                            hba1c: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "44px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: "flex" }}>
                      <Checkbox
                        style={{
                          color: "#800000",
                          transform: "scale(1.8)",
                          marginRight: "14px",
                        }}
                        disableRipple
                        checked={
                          examResult.hba1cCheckbox === "Yes" ? true : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // hba1cCheckbox: "Yes",
                            hba1cCheckbox: examResult.hba1cCheckbox === "Yes" ? "" : "Yes",
                          });
                        }}
                      />

                      <Checkbox
                        style={{ color: "#800000", transform: "scale(1.8)" }}
                        disableRipple
                        checked={
                          examResult.hba1cCheckbox === "No" ? true : false
                        }
                        onChange={() => {
                          setExamResult({
                            ...examResult,
                            // hba1cCheckbox: "No",
                            hba1cCheckbox: examResult.hba1cCheckbox === "No" ? "" : "No",
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 5 }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={2} xl={2} style={{ paddingLeft: "14px" }}>
                      <InputLabel
                        htmlFor="Other"
                        style={{
                          color: "#1A1A1A",
                          marginBottom: "4px",
                          fontSize:'14px',
                          fontWeight:'400 !important'
                        }}
                      >
                        Other
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8} style={{ marginRight: "8px" }}>
                      <TextField
                        placeholder="Type here"
                        fullWidth
                        id="Other"
                        value={examResult.other}
                        onChange={(e) => {
                          setExamResult({
                            ...examResult,
                            other: e.target.value,
                          });
                        }}
                        InputProps={{
                          style: {
                            height: "44px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ display: "flex" }}>
                      <Checkbox
                        style={{
                          color: "#800000",
                          transform: "scale(1.8)",
                          marginRight: "14px",
                        }}
                        disableRipple
                        checked={
                          examResult.otherCheckbox === "Yes" ? true : false
                        }
                        onChange={() => {
                          // setotherYes(true);
                          // setotherNo(false);
                          setExamResult({
                            ...examResult,
                            // otherCheckbox: "Yes",
                            otherCheckbox: examResult.otherCheckbox === "Yes" ? "" : "Yes",
                          });
                        }}
                      />

                      <Checkbox
                        style={{ color: "#800000", transform: "scale(1.8)" }}
                        disableRipple
                        checked={
                          examResult.otherCheckbox === "No" ? true : false
                        }
                        onChange={() => {
                          // setotherYes(false);
                          // setotherNo(true);
                          setExamResult({
                            ...examResult,
                            // otherCheckbox: "No",
                            otherCheckbox: examResult.otherCheckbox === "No" ? "" : "No",
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ///////// */}
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ padding: 15 }}
                >
                  <InputLabel
                    htmlFor="comments"
                    style={{
                      color: "#1A1A1A",
                      marginBottom: "10px",
                      whiteSpace: "normal", // Prevent line breaks
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis",
                    }}
                  >
                    Comments from Examination Results
                  </InputLabel>
                  <TextField
                    placeholder="Type here"
                    fullWidth
                    id="comments"
                    multiline
                    value={examResult.comments}
                    onChange={(e) => {
                      setExamResult({
                        ...examResult,
                        comments: e.target.value,
                      });
                    }}
                    InputProps={{
                      style: {
                        height: "84px",
                        backgroundColor: "#FAFAFA",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: 3,
                justifyContent: "space-between",
                marginBottom: "140px",
              }}
            >
              <Grid style={{ marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "91px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setExamResult(patientExamResult);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "121px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Save as Draft{" "}
                  </Button>
                </Hidden>

                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={(e) => {
                      setExamResult(patientExamResult);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#E8E8E8",
                      borderRadius: "2px",
                      width: "40px",
                      height: "32px",
                      color: "#323130",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                    onClick={props.saveSurveyAsDraft}
                  >
                    Draft{" "}
                  </Button>
                </Hidden>
              </Grid>
              <Grid style={{ marginRight: "40px", marginTop: "10px" }}>
                <Hidden only={["xs"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={validateExamResult}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "96px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "6px 28px 6px 28px",
                      margin: "7px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    Next
                  </Button>
                </Hidden>
                <Hidden only={["lg", "sm", "xl", "md"]}>
                  <Button
                    onClick={props.handlePrev}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "7px",
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    onClick={validateExamResult}
                    // sx={{ mr: 2 }}
                    style={{
                      backgroundColor: "#800000",
                      borderRadius: "2px",
                      width: "32px",
                      height: "32px",
                      color: "#fff",
                      textAlign: "center",
                      textTransform: "capitalize",
                      Padding: "0",
                      margin: "7px",
                      // marginRight: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientExamResult;

import React from "react";
import { withTranslation } from "react-i18next";
import { Tooltip, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import WindowSizeListener from "../../utils/WindowSizeListener";
import ReceiptIcon from "@mui/icons-material/Receipt";

const ButtonList = (props) => {
  return props.buttonList.map((buttonDetails, buttonIndex) => {
    if (!buttonDetails.disable) {
      switch (buttonDetails.button) {
        case "add":
          if (!props.tableAdd) {
            // if tableAdd prop is not passed, skip rendering the add button
            return null;
          }
          return (
            <Tooltip title={props.translator("dataTable.add")}>
              <IconButton aria-label="add" onClick={() => props.tableAdd()}>
                <AddCircleIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );
        // return (
        //   <Tooltip title={props.translator("dataTable.add")}>
        //     <IconButton aria-label="add" onClick={() => props.tableAdd()}>
        //       <AddCircleIcon variant="contained" />
        //     </IconButton>
        //   </Tooltip>
        // );

        case "edit":
          return (
            <Tooltip title={props.translator("dataTable.edit")}>
              <IconButton aria-label="edit" onClick={() => props.tableEdit()}>
                <EditIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );

        case "assign":
          if (!props.tableAssign) {
            // if tableAdd prop is not passed, skip rendering the add button
            return null;
          }
          return (
            <Tooltip title={props.translator("dataTable.assign")}>
              <IconButton
                aria-label="assign"
                onClick={() => props.tableAssign()}
              >
                <GroupAddIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );

        // case "view":
        case "visibility":
          if (!props.tableView) {
            // if tableVIEW prop is not passed, skip rendering the add button
            return null;
          }
          return (
            <Tooltip title={props.translator("dataTable.view")}>
              <IconButton aria-label="view" onClick={() => props.tableView()}>
                <VisibilityIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );

        case "delete":
          return (
            <Tooltip title={props.translator("dataTable.delete")}>
              <IconButton
                aria-label="delete"
                onClick={() => props.tableDelete()}
              >
                <DeleteIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );
        case "view":
          return (
            <Tooltip title={props.translator("dataTable.view")}>
              <IconButton aria-label="delete" onClick={() => props.tableView()}>
                <VisibilityIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );

        // case "upload":
        //   return (
        //     <Tooltip title={props.translator("dataTable.upload")}>
        //       <IconButton
        //         aria-label="upload"
        //         onClick={() => props.tableUpload()}
        //       >
        //         <CloudUploadIcon variant="contained" />
        //       </IconButton>
        //     </Tooltip>
        //   );
        // case "allDownload":
        //   return (
        //     <Tooltip title={props.translator("dataTable.allDownload")}>
        //       <IconButton
        //         aria-label="All Download"
        //         onClick={() => props.tableAllDownload()}
        //       >
        //         <FileDownloadIcon variant="contained" />
        //       </IconButton>
        //     </Tooltip>
        //   );

        // case "approve":
        //   return (
        //     <Tooltip title={props.translator("dataTable.approve")}>
        //       <IconButton
        //         aria-label="approve"
        //         onClick={() => props.tableApprove()}
        //       >
        //         <VerifiedUserIcon variant="contained" />
        //       </IconButton>
        //     </Tooltip>
        //   );

        // case "reject":
        //   return (
        //     <Tooltip title={props.translator("dataTable.reject")}>
        //       <IconButton
        //         aria-label="reject"
        //         onClick={() => props.tableReject()}
        //       >
        //         <DeleteForeverIcon variant="contained" />
        //       </IconButton>
        //     </Tooltip>
        //   );

        // case "changeStatus":
        //   return (
        //     <Tooltip title={props.translator("dataTable.changeStatus")}>
        //       <IconButton
        //         aria-label="changeStatus"
        //         onClick={() => props.tableCheckStatus()}
        //       >
        //         <SwapHorizontalCircleIcon variant="contained" />
        //       </IconButton>
        //     </Tooltip>
        //   );

        // case "notification":
        //   return (
        //     <Tooltip title={props.translator("dataTable.notification")}>
        //       <IconButton
        //         aria-label="changeStatus"
        //         onClick={() => props.tableSendNotification()}
        //       >
        //         <NotificationsIcon variant="contained" />
        //       </IconButton>
        //     </Tooltip>
        //   );

        case "reset":
          return (
            <Tooltip title={props.translator("dataTable.resetPassword")}>
              <IconButton
                aria-label="changeStatus"
                onClick={() => props.tableResetPassword()}
              >
                <SettingsIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );
        case "invoice":
          if (!props.tableDownloadInvoice) {
            // if tableDownload prop is not passed, skip rendering the add button
            return null;
          }
          return (
            <Tooltip title={props.translator("dataTable.downloadInvoice")}>
              <IconButton
                aria-label="changeStatus"
                onClick={() => props.tableDownloadInvoice()}
              >
                <ReceiptIcon variant="contained" />
              </IconButton>
            </Tooltip>
          );

        default:
          return <div></div>;
      }
    }
  });
};

function TableButtons(props) {
  const { width, height } = WindowSizeListener();

  return (
    <ButtonList
      width={width}
      height={height}
      buttonList={props.buttonList}
      translator={props.t}
      tableAdd={props.tableAdd}
      tableEdit={props.tableEdit}
      tableAssign={props.tableAssign}
      tableView={props.tableView}
      tableDelete={props.tableDelete}
      tableUpload={props.tableUpload}
      tableApprove={props.tableApprove}
      tableReject={props.tableReject}
      tableCheckStatus={props.tableCheckStatus}
      tableSendNotification={props.tableSendNotification}
      tableResetPassword={props.tableResetPassword}
      tableAllDownload={props.tableAllDownload}
      tableDownloadInvoice={props.tableDownloadInvoice}
    />
  );
}
export default withTranslation("translations")(TableButtons);

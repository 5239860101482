import React, { useState } from "react";
import { withTranslation } from "react-i18next";
// import {
//   Grid,
//   makeStyles,
//   Card,
//   CardContent,
//   TextField,
//   Typography,
//   Button,
// } from "@material-ui/core";
import {
  Grid,
  //   makeStyles,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import SnackbarUtils from "../../../../utils/SnackbarUtils";
import Toaster from "../../../../utils/Toaster";

import { connect } from "react-redux";
import LocalStorage from "../../../../config/LocalStorage";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formField: {
    "& .MuiInputLabel-shrink": {
      color: "black !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

function Profile(props) {
  const classes = useStyles();

  const [userDetails, setUserDetails] = useState(LocalStorage.userDetails);

  const [enablePasswordFields, setEnablePasswordFields] = useState(false);
  const [enableConfirmPasswordField, setEnableConfirmPassword] =
    useState(false);

  const [password, setPassword] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handlePasswordOptions = () => {
    setEnablePasswordFields(true);
  };

  const cancelPasswordChangeAttempt = () => {
    setEnablePasswordFields(false);
    setPassword({ oldPassword: "", password: "", confirmPassword: "" });
  };

  const handlePasswordConfirmation = () => {
    if (password.oldPassword === "") {
      return Toaster.error(props.t("profile.addOldPassword"), "topRight");
    }
    if (password.password === "") {
      return Toaster.error(props.t("profile.newpas"), "topRight");
    }
    if (password.confirmPassword === "") {
      return Toaster.error(props.t("profile.addConfirmPassword"), "topRight");
    }

    if (password.confirmPassword !== password.password) {
      return Toaster.error(props.t("snackbar.passwordNotMatching"), "topRight");
    }
    if (password?.oldPassword === password?.password) {
      return Toaster.error(props.t("snackbar.notingto"), "topRight");
    }
    APIRequest.request(
      "PUT",
      ConfigAPIURL.resetPassword,
      JSON.stringify(password)
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 105) {
          cancelPasswordChangeAttempt();
          props.logout();
          SnackbarUtils.sucess(
            props.t("snackbar.successPasswordChange"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        } else if (104) {
          SnackbarUtils.error(
            props.t("snackbar.passwordMisMatch"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography gutterBottom variant='p' component='p'>
                    Hello {userDetails.userName},
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "10px" }}
                >
                  <Typography gutterBottom variant="p" component="p">
                    Email {userDetails.userEmail}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    onClick={handlePasswordOptions}
                    variant="primary"
                    style={{
                      color: "#800000",
                      border: "1px solid #800000",
                      borderRadius: "10px",
                      padding: "8px 15px 8px 15px",
                    }}
                  >
                    Update Password ?{" "}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {enablePasswordFields ? (
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography gutterBottom variant="p" component="p">
                      Hello {userDetails.userName},
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      id="oldPassword"
                      type={"password"}
                      label={props.t("profile.oldPassword")}
                      autoComplete="off"
                      className={classes.formField}
                      value={password.oldPassword}
                      fullWidth
                      onChange={(event) =>
                        setPassword({
                          ...password,
                          oldPassword: event.target.value,
                        })
                      }
                      disabled={!enablePasswordFields}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      id="password"
                      type={"password"}
                      label={props.t("profile.newPassword")}
                      autoComplete="off"
                      className={classes.formField}
                      value={password.password}
                      fullWidth
                      onChange={(event) =>
                        setPassword({
                          ...password,
                          password: event.target.value,
                        })
                      }
                      disabled={!enablePasswordFields}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      id="confirmPassword"
                      type={"password"}
                      label={props.t("profile.confirmPassword")}
                      autoComplete="off"
                      className={classes.formField}
                      value={password.confirmPassword}
                      fullWidth
                      onChange={(event) =>
                        setPassword({
                          ...password,
                          confirmPassword: event.target.value,
                        })
                      }
                      disabled={!enablePasswordFields}
                    />
                  </Grid>

                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={() => cancelPasswordChangeAttempt()}
                      style={{
                        color: "#800000",
                        border: "1px solid #800000",
                        borderRadius: "10px",
                        padding: "8px 15px 8px 15px",
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => handlePasswordConfirmation()}
                      style={{
                        color: "#800000",
                        border: "1px solid #800000",
                        borderRadius: "10px",
                        padding: "8px 15px 8px 15px",
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}{" "}
      </Grid>
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
    logout: () => dispatch({ type: "LOGOUT", value: "" }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(Profile)
);

import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Tooltip,
  Link,
  Typography,
  Paper,
  StepButton,
  StepConnector,
} from "@mui/material";
import { makeStyles, withStyles, useTheme } from "@mui/styles";

import { withTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import SideTopBar from "./SideTopBar";
import { AppContext } from "../AppState";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  drawerDrawerClose: {
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,

    overflowY: "scroll",
    overflowX: "hidden",
    border: "1px solid black",

    /* Change scrollbar width and color */
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#888",
    },
  },

  menuLink: {
    color: "rgba(0, 0, 0, 0.87)",
    
  },

  menuStyle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  root: {
    fontFamily: "Segoe UI !important",
  },
}));

const steps = [
  "HCP Info",
  "Patient Info",
  "Authorization Request",
  "Patient diagnosis",
  "Patient’s Lab Results",
  "Patient’s Examination Results",
  "Score for Atrial Fibrillation Stroke Risk",
  "Other Medications for Stroke Risk",
  "Score for Major Bleeding Risk",
  "Other Medications for Bleeding Risk",
  "Patients Lifestyle",
  "Score for Cirrhosis Mortality",
  "Conclusion"
];

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [values, setMenuStatus] = React.useState({});
  const location = useLocation();
  const history = useHistory();

  const [activeStep, setActiveStep, completed] = useContext(AppContext);

  const handleStep = (step) => () => {
    if (!location.pathname.startsWith("/hcp/create")) {
      history.push("/hcp/create");
    }
    console.log("Location: ", location.pathname);
    setActiveStep(step);
  };

  const mappedSideBarMenu = SideTopBar.menuForAdmin;

  useEffect(() => {
    let menuStatus = values;
    mappedSideBarMenu.map((data) => {
      if (data.display !== undefined && data.display !== null) {
        data.subMenu.map((subData) => {
          if (subData.link === location.pathname) {
            props.callback(subData.display);
          }
          return (menuStatus[subData.eventKey] =
            menuStatus[subData.eventKey] !== undefined &&
            menuStatus[subData.eventKey] !== null
              ? menuStatus[subData.eventKey]
              : false);
        });
        if (data.link === location.pathname) {
          props.callback(data.display);
        }
        return (menuStatus[data.eventKey] =
          menuStatus[data.eventKey] !== undefined &&
          menuStatus[data.eventKey] !== null
            ? menuStatus[data.eventKey]
            : false);
      }
      return null;
    });
    setMenuStatus(menuStatus);
  }, [location]);

  const MainMenu = (menu) => (
    <Link href={"#" + menu.link} underline="none" className={classes.menuLink} >
      <ListItem
        button
        key={menu.eventKey}
        selected={location.pathname === menu.link ? true : false}
        style={{ margin: "0", padding: "5px", paddingLeft: "25px",fontWeight:'600' }}
        disableRipple
      >
        <ListItemIcon
          style={{ margin: "0", paddingLeft: "10px",marginRight:'-15px', color: "#323130",fontWeight:'600' }}
        >
          {menu.icon}
        </ListItemIcon>
        <Tooltip title={props.t(menu.display)}>
          <ListItemText
            className={classes.menuStyle}
            primary={props.t(menu.display)}
            style={{ color: "#323130",fontWeight: '600!important' }}
          />
        </Tooltip>
      </ListItem>
    </Link>
  );

  const DrawerMenu = () => {
    return (
      <div>
        {/* <Divider /> */}
        <List style={{ marginTop: "60px" }}>
          {mappedSideBarMenu.map((menu, menuIndex) =>
            menu.display !== undefined && menu.display !== null ? (
              <MainMenu key={menuIndex} {...menu} />
            ) : (
              <Divider key={menuIndex} />
            )
          )}
        </List>
      </div>
    );
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div
      className={props.mobileOpen ? classes.drawerDrawerClose : classes.drawer}
      aria-label="mailbox folders"
    >
      <Hidden only={["xl", "lg", "md"]}>
        <Drawer
          container={container}
          variant="temporary"
          //   anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
            
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            
          }}
          // style={{backgroundColor:'#800000'}}
        >
          <DrawerMenu />
        </Drawer>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{
            // width: "100vw",
            position: "relative",
            
          }}
          variant={props.mobileOpen ? "temporary" : "permanent"}
          open={!props.mobileOpen}
        >
          <DrawerMenu />
          <div>
            <Divider />
            <Box
              style={{
                marginLeft: 35,
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <Stepper orientation="vertical" nonLinear activeStep={activeStep} >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton
                      color="inherit"
                      onClick={handleStep(index)}
                      disableTouchRipple
                    >
                     
                      <StepLabel
                        StepIconProps={{
                          style: {
                            color:
                              index === activeStep || completed[index]
                                ? "#800000"
                                : "#fff",

                                border:
                                index === activeStep || completed[index]
                                  ? "1px solid #800000"
                                  : "1px solid grey",  
                                  borderRadius:'50%'  ,
                                margin: "0",
                                boxShadow: "inset 0 0 0 1px #800000",
                                marginTop:'-6px',

                          },
                          
                          
                        }}
                        // style={{
                        //   color: completed[index] ? "#800000" : "grey",
                        // }}
                      >
                        <Box
                          // href={"#" + "/hcp/create"}
                          style={{
                            color: index === activeStep ? "#800000" : "black",
                            fontWeight: index === activeStep ? "bold" : "",
                            marginTop:'-6px'
                          }}
                        >
                          {label}
                        </Box>
                      </StepLabel>
                    </StepButton>
                    {index !== steps.length - 1 && (
      <StepConnector style={{ marginTop: -12, marginBottom: -26 }} />
    )}
                    {/* <StepConnector
                      style={{ marginTop: -12, marginBottom: -26 }}
                    /> */}
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
}

export default withTranslation("translations")(ResponsiveDrawer);

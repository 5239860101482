import React, { useEffect, useState } from 'react';
import APIRequest from '../../utils/APIRequest';
import Toaster from '../../utils/Toaster';
import ConfigAPIURL from '../../config/ConfigAPIURL';
export default function PasswordGenaration(props) {
  useEffect(() => {
    urlFetch();
  }, []);

  const urlFetch = () => {
    var query = window.location.href;
    var vars = query.split('?');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      // console.log('pair', pair);

      if (pair[1]) {
        _generatePasswordAttemptAdmin(pair[1]);
      }
    }
  };
  const _generatePasswordAttemptAdmin = (url) => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.adminGeneratePassword + '?token=' + url,
      ''
    ).then((response) => {
      if (response.code === 100 && response.data.responseCode === 119) {
        Toaster.info('Redirecting to login page...', 'topRight');

        props.history.push('/login');

        Toaster.info(
          'Your new password is generated successfully, Please check your mail.',
          'topRight'
        );
      } else {
        Toaster.info('Redirecting to login page...', 'topRight');

        props.history.push('/login');

        Toaster.error(
          'Invalide Token, Session timeout. Please request one more time.',
          'topRight'
        );
      }
    });
  };

  const { classes, fieldProps } = props;

  return (
    <div
      className='login'
      style={{
        margin: '20px 20%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <h3></h3>
    </div>
  );
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Hidden,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";
import TranslateIcon from "@mui/icons-material/Translate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageConfig from "../../../../config/LanguageConfig";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { useState } from "react";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundImage: theme.custom.topHeader.toolbarColor,
  },
  appBarDrawerClose: {
    backgroundImage: theme.custom.topHeader.toolbarColor,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  floatRight: {
    textAlign: "right",
  },
  languageMenuColor: {
    color: theme.custom.topHeader.toolBarLanguage,
    background: "transparent",
  },
  logoutIcon: {
    color: theme.custom.topHeader.toolBarLogout,
  },
}));

function TopBar(props) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const classes = useStyles();
  const handleNotLogout = () => {
    setShowDeleteDialog(false);
  };
  const handleDialog = () => {
    setShowDeleteDialog((prevState) => !prevState);
  };
  const [languageList, setLanguageList] = React.useState([]);
  useEffect(() => {
    LanguageConfig.languageList().then((lngList) => {
      setLanguageList(lngList);
    });
    props.i18n.changeLanguage(props.languageData.code);
  }, []);
  const notificationTest = () => {
    SnackbarUtils.sucess("HI", "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  const languageChange = (data) => {
    props.i18n.changeLanguage(data.code);
    props.languageChange(data);
    localStorage.setItem("lng", JSON.stringify(data));
    handleLanguageMenuClose();
  };
  const [languageDropdown, setLanguageDropdown] = React.useState(null);
  const handleLanguageMenuClick = (event) => {
    setLanguageDropdown(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setLanguageDropdown(null);
  };
  return (
    <div>
      <AppBar
        position="fixed"
        className={
          props.mobileOpen ? classes.appBarDrawerClose : classes.appBar
        }
      >
        <Toolbar>
          <Tooltip ml={2} title={props.t("topNavBar.menu")}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          <Typography variant="h6" className={classes.title} color="#000">
            {props.t(props.pageName)}
          </Typography>
          {/* <Hidden only={["sm", "xs"]}> */}
          {/* <Tooltip ml={2} title={props.t("topNavBar.language")}>
              <Button
                className={classes.languageMenuColor}
                aria-controls="simple-menu"
                aria-haspopup="true"
                startIcon={<TranslateIcon />}
                endIcon={<ExpandMoreIcon />}
                onClick={handleLanguageMenuClick}
              >
                {props.languageData !== undefined && props.languageData !== null
                  ? props.languageData.displayName
                  : "English"}
              </Button>
            </Tooltip> */}
          {/* <Menu
              id="simple-menu"
              anchorEl={languageDropdown}
              keepMounted
              open={Boolean(languageDropdown)}
              onClose={handleLanguageMenuClose}
            >
              {languageList.map((lng, lngIndex) => (
                <MenuItem
                  key={lngIndex}
                  selected={
                    lng.displayName === props.languageData.displayName
                      ? true
                      : false
                  }
                  onClick={() => languageChange(lng)}
                >
                  {lng.displayName}
                </MenuItem>
              ))}
            </Menu> */}
          <Tooltip ml={2} title={props.t("topNavBar.logout")}>
            <IconButton
              // onClick={() => props.logout()}
              onClick={handleDialog}
            >
              <ExitToAppIcon className={classes.logoutIcon} />
            </IconButton>
          </Tooltip>
          {/* </Hidden> */}
          {/* <Hidden only={["xl", "lg", "md"]}>
            <Tooltip ml={2} title={props.t("topNavBar.moreMenu")}>
              <IconButton
                onClick={() => {
                  props.handleBottomDrawerOpen();
                }}
              >
                <MoreVertIcon
                  onClick={() => {
                    props.handleBottomDrawerOpen();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Hidden> */}
        </Toolbar>
      </AppBar>
      {/* ---- */}
      <div>
        <Dialog
          hidden={!showDeleteDialog}
          onDismiss={handleNotLogout}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Logout",
            subText: "Do you want to Logout?",
          }}
          modalProps={{
            isBlocking: true,
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => props.logout()}
              style={{ backgroundColor: "#800000", border: "none" }}
              text="Yes"
            />
            <DefaultButton onClick={handleNotLogout} text="No" />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    languageData: state.languageData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    languageChange: (languageData) =>
      dispatch({ type: "LANGUAGE", value: languageData }),
    logout: () => dispatch({ type: "LOGOUT", value: "" }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(TopBar)
);
